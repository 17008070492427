export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium жана тарифтер"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опциялар"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ийгилик окуялары"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биз жөнүндө"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карьера"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жардам"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жашыруундуулук профилин өзгөртүңүз"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ката жөнүндө билдир"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биз менен байланышыңыз"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укуктук шарттар"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảo mật"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биздин Тейлөө шарттары"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биздин сатып алуу саясаты"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google менен кирүү"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook менен кирүү"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple менен кирүү"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер катасы. Биз менен байланышыңыз."])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Афганистан"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Албания"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алжир"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Америкалык Самоа"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Андорра"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ангола"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ангилья"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антарктида"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антигуа жана Барбуда"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аргентина"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Армения"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аруба"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австралия"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австрия"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азербайжан"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Багама аралдары"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бахрейн"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бангладеш"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барбадос"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беларусь"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бельгия"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белиз"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бенин"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бермуд аралдары"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутан"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боливия"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Босния жана Герцеговина"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботсвана"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бразилия"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инди океанындагы Британ территориясы"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виргин аралдары (Британия)"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бруней"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болгария"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Буркина-Фасо"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бурунди"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камбоджа"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камерун"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канада"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабо-Верде"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кайман аралдары"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Борбордук Африка Республикасы"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чад"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чили"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кытай"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рождество аралы"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кокос (Килинг) аралдары"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колумбия"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коморос"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кук аралдары"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коста-Рика"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хорватия"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куба"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кюрасао"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кипр"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чехия"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конго-Киншаса"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дания"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джибути"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доминика"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доминика Республикасы"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тимор-Лесте"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эквадор"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Египет"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эль-Сальвадор"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экватордук Гвинея"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эритрея"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эстония"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эфиопия"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фолкленд аралдары"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фарер аралдары"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиджи"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финляндия"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Франция"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Француздук Гвиана"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полинезия (франциялык)"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Габон"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гамбия"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грузия"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Германия"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гана"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибралтар"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Греция"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гренландия"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гренада"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гваделупа"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гуам"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гватемала"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гернси"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвинея"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвинея-Бисау"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гайана"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гаити"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гондурас"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гонконг Кытай ААА"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Венгрия"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исландия"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индия"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индонезия"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иран"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ирак"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ирландия"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мэн аралы"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Израиль"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Италия"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кот-д’Ивуар"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ямайка"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Япония"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жерси"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иордания"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Казакстан"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кения"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кирибати"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Косово"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Палау"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кыргызстан"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаос"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латвия"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ливан"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лесото"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Либерия"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ливия"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лихтенштейн"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Литва"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люксембург"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макау Кытай ААА"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Македония"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мадагаскар"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малави"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малайзия"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мальдив"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мали"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мальта"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маршалл аралдары"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мартиника"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мавритания"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маврикий"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майотта"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мексика"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Микронезия"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молдова"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монако"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монголия"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черногория"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монтсеррат"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Марокко"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мозамбик"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мьянма (Бирма)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намибия"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Науру"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непал"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нидерланд"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңы Каледония"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңы Зеландия"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никарагуа"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нигер"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нигерия"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниуэ"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Норфолк аралы"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түндүк Корея"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түндүк Мариана аралдары"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Норвегия"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оман"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакистан"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Палестина аймактары"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панама"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папуа-Жаңы Гвинея"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парагвай"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перу"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филиппин"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Питкэрн аралдары"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Польша"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Португалия"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пуэрто-Рико"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Катар"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конго-Браззавил"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реюньон"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Румыния"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Россия"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Руанда"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент Бартелеми"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ыйык Елена"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Китс жана Невис"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Люсия"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Мартин"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сен-Пьер жана Микелон"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Винсент жана Гренадиндер"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самоа"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан Марино"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан-Томе жана Принсипи"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сауд Арабиясы"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сенегал"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сербия"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейшел аралдары"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьерра-Леоне"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сингапур"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синт-Мартен"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словакия"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словения"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соломон аралдары"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сомали"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түштүк-Африка Республикасы"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түштүк Корея"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түштүк Судан"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Испания"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шри-Ланка"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судан"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суринам"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шпицберген жана Ян-Майен"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свазиленд"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швеция"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швейцария"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сирия"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тайвань"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тажикстан"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Танзания"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тайланд"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Того"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токелау"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тонга"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тринидад жана Тобаго"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тунис"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түркия"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түркмөнстан"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түркс жана Кайкос аралдары"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тувалу"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виргин аралдары (АКШ)"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уганда"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Украина"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бириккен Араб Эмираттары"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улуу Британия"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кошмо Штаттар"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уругвай"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзбекстан"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вануату"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ватикан"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Венесуэла"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вьетнам"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уоллис жана Футуна"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Батыш Сахара"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йемен"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замбия"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зимбабве"])}
  }
}