export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium và Thuế quan"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính năng"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu Chuyện Thành Công"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giới thiệu"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nghề nghiệp"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trợ giúp"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu hỏi Thường gặp"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản Lý Hồ Sơ Bảo Mật Của Bạn"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Báo cáo sự cố"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên hệ chúng tôi"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảo vệ Dữ liệu"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კონფიდენციალურობა"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều khoản Dịch vụ của chúng tôi"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chính sách Mua hàng của chúng tôi"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập bằng Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập bằng Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập bằng Apple"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lỗi số điện thoại. Vui lòng liên hệ với chúng tôi"])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algeria"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa thuộc Mỹ"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam Cực"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua và Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áo"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bỉ"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia và Herzegovina"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazil"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lãnh thổ Ấn Độ Dương thuộc Anh"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Virgin thuộc Anh"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campuchia"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroon"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cape Verde"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Cayman"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cộng hòa Trung Phi"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung Quốc"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đảo Giáng Sinh"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Cocos (Keeling)"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoros"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Cook"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaçao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síp"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séc"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo - Kinshasa"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đan Mạch"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cộng hòa Dominica"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor-Leste"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ai Cập"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea Xích Đạo"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopia"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Falkland"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Faroe"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần Lan"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pháp"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiana thuộc Pháp"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polynesia thuộc Pháp"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruzia"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đức"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hy Lạp"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenland"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hồng Kông- Trung Quốc"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iceland"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ấn Độ"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đảo Man"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côte d’Ivoire"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhật Bản"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyzstan"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lào"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Li-băng"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libya"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litva"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macao- Trung Quốc"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bắc Macedonia"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Marshall"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinique"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mông Cổ"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma-rốc"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Miến Điện)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hà Lan"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Caledonia"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đảo Norfolk"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triều Tiên"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Bắc Mariana"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na Uy"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lãnh thổ Palestine"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua New Guinea"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Pitcairn"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ba Lan"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bồ Đào Nha"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Rico"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo - Brazzaville"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nga"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Barthélemy"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Helena"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Kitts và Nevis"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Lucia"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Martin"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Pierre và Miquelon"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Vincent và Grenadines"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Tomé và Príncipe"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ả Rập Xê-út"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint Maarten"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Solomon"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam Phi"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàn Quốc"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam Sudan"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tây Ban Nha"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard và Jan Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thụy Điển"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thụy Sĩ"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syria"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đài Loan"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajikistan"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái Lan"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad và Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thổ Nhĩ Kỳ"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Turks và Caicos"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quần đảo Virgin thuộc Mỹ"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraina"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các Tiểu Vương quốc Ả Rập Thống nhất"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vương quốc Anh"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoa Kỳ"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thành Vatican"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Việt Nam"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis và Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tây Sahara"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
  }
}