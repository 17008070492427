export default {
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизоваться"])},
  "Data Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифные Планы"])},
  "Data without border at yourfingertips {quantity} Travel destinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самый легкий способ оставаться подключенными к Интернету во время Ваших путешествий"])},
  "Search your destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск Тарифных Пакетов"])},
  "Countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страны"])},
  "Download Getcontact to manage and top up your eSIM anytime, anywhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачайте Getcontact для управления и пополнения Вашей eSIM в любое время, в любом месте"])},
  "Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact"])},
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium и Тарифы"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СВОЙСТВА"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Истории успеха"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карьера"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощь"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЧаВо"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление конфиденциальностью профиля"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить о проблеме"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Защита данных"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy / GDPR"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши условия обслуживания"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша политика покупок"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
  "footer.copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторские права © 2016-2023 Getverify LDA. Все права защищены. Getcontact™ является зарегистрированной маркой."])},
  "Secure Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасная Оплата"])},
  "Go to eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к eSIM"])},
  "Select plan and continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите план и продолжите"])},
  "Login or Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти или Регистрация"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти через Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти через Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти через Apple"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или"])},
  "Enter Your Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите Вашу Электронную Почту"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
  "Card Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер Карты"])},
  "Name On The Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя на Карте"])},
  "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ММ"])},
  "YY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ГГ"])},
  "CVV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
  "Complete Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить"])},
  "{amount} GB": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " ГБ"])},
  "{day} Day Valid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно ", _interpolate(_named("day")), " Дней"])},
  "{day} Days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " Дней"])},
  "{day} Day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " ДЕНЬ"])},
  "Bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бандл"])},
  "{month} month Getcontact Premium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("month")), " месяцев Getcontact Premium"])},
  "Back to Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возвратиться к Планам"])},
  "eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM"])},
  "When you clicked Payment Complete Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда Вы нажимаете на Кнопку Оплата Совершена"])},
  "When you clicked Update Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда Вы нажимаете на кнопку Обновить"])},
  "Update card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить Карту"])},
  "Term of Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия Использования"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика о Конфиденциальности"])},
  "you are deemed to have accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["считается, что Вы приняли"])},
  "When you clicked Add Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда Вы нажимаете на кнопку Добавить"])},
  "3rd Party Integration Service Providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сторонние поставщики услуг интеграции"])},
  "Account Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация об Аккаунте"])},
  "Saved Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраненная Карта"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
  "Follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следовать"])},
  "My eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои eSIM"])},
  "Install an eSIM on your Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить eSIM на Ваше Устройство"])},
  "This plan can only be purchased through the <b>eSIM io</b> mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This plan can only be purchased through the <b>eSIM io</b> mobile application."])},
  "<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application."])},
  "Scan the code to download the eSIM.io application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the code to download the eSIM.io application."])},
  "Go to the application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the application"])},
  "QR Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Код"])},
  "Manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мануально"])},
  "Manual Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мануальная Установка"])},
  "Remaining Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставшийся Интернет"])},
  "My Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои Пакеты"])},
  "Available Top-up Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступные Пакеты Пополнения"])},
  "Installation Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаги Установки"])},
  "<b>Important: eSIM</b> can only be installed once.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Важно: eSIM</b> можно установить только один раз."])},
  "<b>Do NOT DELETE/REMOVE</b> your eSIM from your device after successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b> НЕ УДАЛЯЙТЕ/СТИРАЙТЕ</b> Вашу eSIM с Вашего устройства после успешной установки."])},
  "QR Code Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установка с QR Кодом"])},
  "Install your eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установите Вашу eSIM"])},
  "Installation Instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкции по Установке"])},
  "To manually activate the eSIM on your eSIM capable device you need to print or display this QR code on your device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсканируйте QR код, чтобы активировать Вашу eSIM."])},
  "Do not remove your eSIM from your device After successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удаляйте Вашу eSIM с Вашего устройства После успешной установки"])},
  "To manually activate the eSIM on your eSIM capable device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для мануальной активации eSIM на Вашем, подходящим для eSIM устройстве"])},
  "Please copy this information for manually installing the eSIM your device. é Make sure your device has a stable internet connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопируйте эти данные для мануальной установки eSIM на Вашем устройстве. Убедитесь, что Ваше устройство имеет стабильное соединение с интернетом."])},
  "SM-DP-ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM-DP-АДРЕС"])},
  "ACTIVATION CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КОД АКТИВАЦИИ"])},
  "Thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо"])},
  "Your plan is ready to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша eSIM готова к использованию."])},
  "Details have been sent to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробная информация была отправлена на Вашу электронную почту:"])},
  "If you wish, you can manage your eSIM installation from the Getcontact Mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершите установку, чтобы начать пользоваться Вашей eSIM."])},
  "Download Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать Getcontact"])},
  "First Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
  "Last Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
  "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
  "You don’t have any credit card saved yet.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У Вас нет сохраненной кредитной карты."])},
  "Add Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить Карту"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "Expiry Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок Действия:"])},
  "Purchase History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История Покупок"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активный"])},
  "Expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Истек"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидается"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
  "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тариф"])},
  "Validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период действия"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали"])},
  "Show All Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать Все Пакеты"])},
  "Would you like to Purchase a new eSIM ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотите ли Вы приобрести новую eSIM?"])},
  "Purchase an eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить сейчас"])},
  "Log in / Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти / Регистрация"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
  "Back to Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возвратиться к Пакетам"])},
  "Are you sure you want to delete your card?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить Вашу карту?"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
  "An error occurred. Please try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "Expires on {date}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Закончится ", _interpolate(_named("date"))])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
  "Copied!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировано!"])},
  "eSIM Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить eSIM"])},
  "my-esim-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У Вас нет eSIM плана"])},
  "installation-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете управлять приобретенными планами здесь."])},
  "add-saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда Вы добавляете новую карту, Вы увидите подробности о сохраненной карте здесь."])},
  "saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша сохраненная карта"])},
  "You have successfully logged in. Redirecting...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы успешно вошли. Перенаправление..."])},
  "Please fill in your name and surname to continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите Ваши имя и фамилию, чтобы продолжить"])},
  "Local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Местный"])},
  "Regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Региональный"])},
  "Global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальний"])},
  "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад к Главной Странице"])},
  "404.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница, которую Вы ищете, не найдена."])},
  "404.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Страница не Найдена"])},
  "login.name-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши Имя и Фамилия"])},
  "packages.regional-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Региональные eSIM"])},
  "packages.global-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальные eSIM"])},
  "packages.local-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Местные eSIM"])},
  "packages.regional-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Региональные eSIM"])},
  "packages.global-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальные eSIM"])},
  "packages.regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Региональный"])},
  "packages.global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальный"])},
  "packages.local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Местный"])},
  "packages.supported-countries-modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддерживаемые Страны"])},
  "packages.supported-countries-modal.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В каких странах я смогу использовать мой Глобальный план?"])},
  "packages.supported-countries-modal.search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск Стран"])},
  "installation.local-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Местные Тарифные Планы"])},
  "installation.regional-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Региональные Тарифные Планы"])},
  "installation.global-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальные Тарифные Планы"])},
  "installation.qr-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не сможете поменять устройство после успешной установки. Поэтому, не удаляйте активную eSIM с Вашего устройства."])},
  "installation.manual-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не сможете поменять устройство после успешной установки. Поэтому, не удаляйте активную eSIM с Вашего устройства."])},
  "installation.detail-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не сможете поменять устройство после успешной установки. Поэтому, не удаляйте активную eSIM с Вашего устройства."])},
  "installation.qr-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Настройки > Сотовая / Мобильная сеть > Добавить Сотовый/Мобильный План."])},
  "installation.qr-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Сканируйте QR код."])},
  "installation.qr-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.Подтвердите детали плана eSIM."])},
  "installation.qr-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Пометьте eSIM."])},
  "installation.manual-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Перейдите в Настройки > Сотовая/мобильная связь > Добавить тарифные планы сотовой/мобильной связи"])},
  "installation.manual-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Нажмите на Ввести Вручную"])},
  "installation.manual-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Введите Ваш SM-DP-Адрес и Код Активирования"])},
  "installation.manual-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Нажмите на Добавить Сотовый План"])},
  "installation.manual-installation-step-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Отметьте eSIM"])},
  "packages.supported-countries-modal.global-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В каких странах я смогу использовать мой Глобальный план?"])},
  "packages.supported-countries-modal.regional-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В каких странах я смогу использовать мой Региональный план?"])},
  "packages.supported-countries-modal.local-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Где я смогу использовать мой Местный план?"])},
  "This data plan can be used in {count} destinations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Этот тарифный план можно использовать в ", _interpolate(_named("count")), " местах"])},
  "cookiepolicy.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы используем законные файлы куки (cookie), чтобы улучшить пользование."])},
  "cookiepolicy.description2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Куки и ", _interpolate(_named("policy")), ", которые мы используем."])},
  "cookiepolicy.cookiepolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика куки"])},
  "cookiepolicy.policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша политика"])},
  "homepage.banner.image.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купите план eSIM для поездки в Катар на Чемпионат мира по футболу FIFA!"])},
  "homepage.banner.button.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купите Сейчас"])},
  "400014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверная электронная почта"])},
  "400601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверное Имя"])},
  "400602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверная Фамилия"])},
  "500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая ошибка"])},
  "400010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте данные, которые Вы ввели, и попробуйте еще раз."])},
  "400008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "400009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "400011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "400220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "400221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "400222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "500221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка во время заказа. Пожалуйста, попробуйте снова."])},
  "400005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не выполнена."])},
  "404001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не выполнена. Пожалуйста, попробуйте позже."])},
  "401002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не выполнена. Пожалуйста, попробуйте позже."])},
  "400002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недействительный пакет. Пожалуйста, попробуйте другой пакет."])},
  "400003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в платежной системе. Пожалуйста, попробуйте снова."])},
  "400004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в платежной системе. Пожалуйста, попробуйте снова."])},
  "400006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недействительная кредитная карта."])},
  "400007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно начать новую подписку, так как уже имеется активная подписка."])},
  "400012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в платежной системе. Пожалуйста, попробуйте снова."])},
  "400013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в платежной системе. Пожалуйста, попробуйте снова."])},
  "400015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в процессе возврата средств."])},
  "500001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в платежной системе. Пожалуйста, попробуйте снова."])},
  "500002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при создании подписки. Пожалуйста, попробуйте снова."])},
  "500003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка во время сохранения карты. Пожалуйста, попробуйте позже."])},
  "C0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка, связанная с оплатой. Пожалуйста, попробуйте позже."])},
  "400223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не найден подходящий пакет. Пожалуйста, попробуйте снова."])},
  "500220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка во время платежа. Пожалуйста, попробуйте снова."])},
  "Z_404001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не выполнена. Пожалуйста, попробуйте позже."])},
  "Z_401002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не выполнена. Пожалуйста, попробуйте позже."])},
  "Z_400002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недействительный пакет. Пожалуйста, попробуйте другой пакет."])},
  "Z_400003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в платежной системе. Пожалуйста, попробуйте снова."])},
  "Z_400004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в платежной системе. Пожалуйста, попробуйте снова."])},
  "Z_400005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не выполнена."])},
  "Z_400006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недействительная кредитная карта."])},
  "Z_400007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно начать новую подписку, так как уже имеется активная подписка."])},
  "Z_400008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "Z_400009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "Z_400010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "Z_400011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "Z_400012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в платежной системе. Пожалуйста, попробуйте снова."])},
  "Z_400013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в платежной системе. Пожалуйста, попробуйте снова."])},
  "Z_400014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в платежной системе. Пожалуйста, попробуйте снова."])},
  "Z_400015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в процессе возврата средств."])},
  "Z_500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "Z_500001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в платежной системе. Пожалуйста, попробуйте снова."])},
  "Z_500002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при создании подписки. Пожалуйста, попробуйте снова."])},
  "Z_500003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка во время сохранения карты. Пожалуйста, попробуйте позже."])},
  "Z_C0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка, связанная с оплатой. Пожалуйста, попробуйте позже."])},
  "Z_400220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "Z_400221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "Z_400222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка. Пожалуйста, попробуйте снова."])},
  "Z_400223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не найден подходящий пакет. Пожалуйста, попробуйте снова."])},
  "Z_500220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка во время платежа. Пожалуйста, попробуйте снова."])},
  "Z_500221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка во время заказа. Пожалуйста, попробуйте снова."])},
  "Z_E0000027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы столкнулись с некоторыми непредвиденными изменениями в наших операциях в вашем регионе. Однако в настоящее время мы работаем над тем, чтобы решить ваш запрос как можно скорее."])},
  "Z_E0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка во время платежа. Пожалуйста, попробуйте снова."])},
  "E0000027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы столкнулись с некоторыми непредвиденными изменениями в наших операциях в вашем регионе. Однако в настоящее время мы работаем над тем, чтобы решить ваш запрос как можно скорее."])},
  "E0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка во время платежа. Пожалуйста, попробуйте снова."])},
  "400224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Несовместимость пользователя и SIM ID"])},
  "400340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недействительный номер кредитной карты"])},
  "400341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недействительное имя на кредитной карте"])},
  "400342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный месяц действия кредитной карты"])},
  "400343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный год действия кредитной карты"])},
  "400344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный код безопасности кредитной карты"])},
  "403033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверная информация об аккаунте"])},
  "500015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при подтверждении"])},
  "403002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время сеанса истекло. Пожалуйста, попробуйте войти снова."])},
  "400000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш запрос не был выполнен из-за недостающей информации. Пожалуйста, попробуйте снова."])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер ошибочный. Пожалуйста, свяжитесь с нами"])},
  "Z_E0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциязавершилась без нажатия на кнопку Оплата."])},
  "Z_E0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Из-за недостаточного лимита на карте транзакция не была завершена успешно."])},
  "Z_E0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не совершилась, так как верификация по безопасности была неуспешна."])},
  "Z_E0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция была неуспешна. Пожалуйста, свяжитесь с Вашим банком."])},
  "Z_E0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция была неуспешна из-за технической ошибки. Пожалуйста, попробуйте снова."])},
  "Z_E0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция была отменена пользователем."])},
  "Z_E0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша карта закрыта для покупок в сети. Пожалуйста, свяжитесь с Вашим банком."])},
  "Z_E0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция была неуспешна из-за Политики по Безопасности."])},
  "Z_E0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция была неуспешна из-за введенного неверного номера карты."])},
  "Z_E0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция была неуспешна из-за неверной даты истечения срока карты."])},
  "Z_E0000011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция была прервана, потому что пользователь не подтвердил транзакцию."])},
  "Z_E0000012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция была прервана из-за превышения лимита транзакций."])},
  "Z_E0000013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не завершилась из-за неверной общей суммы."])},
  "Z_E0000014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша подписка не была активирована, так как верификация безопасности 3DS не была завершена."])},
  "Z_E0000015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция была отклонена Вашим банком."])},
  "Z_E0000016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция была отклонена Вашим банком. Пожалуйста, свяжитесь с Вашим банком."])},
  "Z_E0000017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы ввели неправильный код CVV. Пожалуйста, проверьте код и попробуйте снова."])},
  "Z_E0000018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитная карта не была подтверждена. Пожалуйста, проверьте и попробуйте снова."])},
  "Z_E0000019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок использования Вашей кредитной карты истек. Пожалуйста, попробуйте другую карту."])},
  "Z_E0000020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не завершилась, так как Вы ввели неверный код подтверждения несколько раз."])},
  "Z_E0000021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция была неуспешна из-за технической ошибки. Пожалуйста, попробуйте снова."])},
  "Z_E0000022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша кредитная карта не принимается действующим провайдером платежных услуг. Пожалуйста, попробуйте другую карту."])},
  "Z_E0000023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитная карта, которую Вы используете, не была подтверждена. Пожалуйста, попробуйте другую кредитную карту."])},
  "Z_E0000024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы ввели неправильный год истечения срока кредитной карты."])},
  "Z_E0000025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы ввели неправильный месяц истечения срока кредитной карты."])},
  "Z_E0000026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы ввели неправильную дату истечения срока кредитной карты."])},
  "Z_F0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не прошла. Пожалуйста, свяжитесь со Службой Поддержки."])},
  "Z_F0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не прошла. Пожалуйста, свяжитесь со Службой Поддержки."])},
  "Z_F0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не прошла. Пожалуйста, свяжитесь со Службой Поддержки."])},
  "Z_F0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не прошла. Пожалуйста, свяжитесь со Службой Поддержки."])},
  "Z_F0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не прошла. Пожалуйста, свяжитесь со Службой Поддержки."])},
  "Z_F0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не прошла. Пожалуйста, свяжитесь со Службой Поддержки."])},
  "Z_F0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не прошла. Пожалуйста, свяжитесь со Службой Поддержки."])},
  "Z_F0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не прошла. Пожалуйста, свяжитесь со Службой Поддержки."])},
  "Z_F0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не прошла. Пожалуйста, свяжитесь со Службой Поддержки."])},
  "Z_F0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не прошла. Пожалуйста, свяжитесь со Службой Поддержки."])},
  "Z_F0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не прошла. Пожалуйста, свяжитесь со Службой Поддержки."])},
  "Z_S0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция прошла успешно."])},
  "Z_R0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам следует перейти по ссылке redirectUrl для транзакции."])},
  "order-status": {
    "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платеж был возвращен."])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Истек."])},
    "ORDER_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при определении."])},
    "PAYMENT_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при оплате."])},
    "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активный."])},
    "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удален."])},
    "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидается."])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакет создается."])},
    "PENDING_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидается завершение."])},
    "DEPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит Закончился"])},
    "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Истек"])}
  },
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Афганистан"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Албания"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алжир"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Американское Самоа"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Андорра"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ангола"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ангилья"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антарктида"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антигуа и Барбуда"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аргентина"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Армения"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аруба"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австралия"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австрия"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азербайджан"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Багамы"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бахрейн"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бангладеш"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барбадос"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беларусь"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бельгия"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белиз"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бенин"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бермудские о-ва"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутан"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боливия"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Босния и Герцеговина"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботсвана"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бразилия"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Британская территория в Индийском океане"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виргинские о-ва (Великобритания)"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бруней-Даруссалам"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болгария"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Буркина-Фасо"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бурунди"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камбоджа"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камерун"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канада"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабо-Верде"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острова Кайман"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центрально-Африканская Республика"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чад"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чили"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Китай"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["о-в Рождества"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кокосовые о-ва"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колумбия"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коморы"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острова Кука"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коста-Рика"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хорватия"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куба"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кюрасао"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кипр"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чехия"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демократическая Республика Конго"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дания"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джибути"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доминика"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доминиканская Республика"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восточный Тимор"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эквадор"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Египет"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сальвадор"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экваториальная Гвинея"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эритрея"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эстония"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эфиопия"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фолклендские о-ва"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фарерские о-ва"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиджи"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финляндия"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Франция"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Французская Гвиана"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Французская Полинезия"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Габон"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гамбия"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грузия"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Германия"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гана"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибралтар"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Греция"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гренландия"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гренада"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гваделупа"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гуам"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гватемала"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гернси"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвинея"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвинея-Бисау"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гайана"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гаити"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гондурас"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гонконг (САР)"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Венгрия"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исландия"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индия"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индонезия"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иран"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ирак"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ирландия"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["о-в Мэн"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Израиль"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Италия"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кот-д’Ивуар"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ямайка"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Япония"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джерси"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иордания"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Казахстан"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кения"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кирибати"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Косово"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Палау"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Киргизия"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаос"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латвия"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ливан"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лесото"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Либерия"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ливия"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лихтенштейн"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Литва"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люксембург"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макао (САР)"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Северная Македония"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мадагаскар"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малави"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малайзия"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мальдивы"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мали"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мальта"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маршалловы Острова"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мартиника"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мавритания"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маврикий"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майотта"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мексика"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Федеративные Штаты Микронезии"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молдова"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монако"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монголия"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черногория"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монтсеррат"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Марокко"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мозамбик"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мьянма (Бирма)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намибия"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Науру"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непал"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нидерланды"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая Каледония"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая Зеландия"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никарагуа"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нигер"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нигерия"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниуэ"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["о-в Норфолк"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КНДР"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Северные Марианские о-ва"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Норвегия"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оман"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакистан"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Палестинские территории"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панама"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папуа — Новая Гвинея"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парагвай"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перу"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филиппины"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["о-ва Питкэрн"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Польша"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Португалия"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пуэрто-Рико"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Катар"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Республика Конго"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реюньон"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Румыния"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Россия"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Руанда"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сен-Бартелеми"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["о-в Св. Елены"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Китс и Невис"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Люсия"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сен-Мартен"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сен-Пьер и Микелон"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Винсент и Гренадины"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самоа"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан-Марино"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан-Томе и Принсипи"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саудовская Аравия"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сенегал"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сербия"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейшельские Острова"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьерра-Леоне"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сингапур"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синт-Мартен"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словакия"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словения"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соломоновы Острова"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сомали"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Южно-Африканская Республика"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Республика Корея"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Южный Судан"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Испания"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шри-Ланка"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судан"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суринам"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шпицберген и Ян-Майен"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эсватини"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швеция"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швейцария"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сирия"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тайвань"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таджикистан"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Танзания"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таиланд"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Того"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токелау"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тонга"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тринидад и Тобаго"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тунис"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Турция"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туркменистан"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["о-ва Тёркс и Кайкос"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тувалу"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виргинские о-ва (США)"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уганда"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Украина"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОАЭ"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Великобритания"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соединенные Штаты Америки"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уругвай"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узбекистан"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вануату"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ватикан"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Венесуэла"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вьетнам"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уоллис и Футуна"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Западная Сахара"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йемен"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замбия"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зимбабве"])}
  },
  "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПРИНЯТО"])},
  "FULFILLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВЫПОЛНЯЕТСЯ"])},
  "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CЗАВЕРШЕНО"])},
  "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НЕУСПЕШНО"])},
  "page-titles": {
    "app-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact eSIM"])},
    "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная Страница"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
    "login-by-verifykit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход с VerifyKit"])},
    "country-packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакеты для Стран"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата Пакетов"])},
    "payment-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешный Платеж"])},
    "installation-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали по Установке"])},
    "installation-qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR для Установки"])},
    "installation-manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мануальная Установка"])},
    "account-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация об Аккаунте"])},
    "saved-cards-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраненные Карты"])},
    "saved-cards-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в Сохраненные Карты"])},
    "saved-cards-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать Сохраненные Карты"])},
    "my-esim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моя eSIM"])},
    "404-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница не Найдена"])}
  },
  "credit-card.card-number-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный номер карты"])},
  "credit-card.card-owner-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверное имя на карте"])},
  "credit-card.card-expiration-month-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный месяц действия карты"])},
  "credit-card.card-expiration-year-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный год действия карты"])},
  "credit-card.card-cvv-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный код безопасности карты"])},
  "saved-cards.add-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить Карту"])},
  "saved-cards.update-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить Карту"])},
  "payment-success.order-type-topup-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите для подробной информации о Вашей eSIM"])},
  "saved-cards.add-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта была успешно добавлена."])},
  "saved-cards.update-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта была успешно изменена."])},
  "saved-cards.delete-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта была успешно удалена."])},
  "general-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так, попробуйте снова."])},
  "search-country.empty-state-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна не найдена"])},
  "account-information.update-profile-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация Вашего профиля была успешно обновлена."])},
  "installation.order-does-not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ, который Вы ищете, не существует."])}
}