export default {
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Girişi"])},
  "Data Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Planları"])},
  "Data without border at yourfingertips {quantity} Travel destinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seyahatleriniz sırasında internete bağlı kalmanın en kolay yolu"])},
  "Search your destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Paketi Arayın"])},
  "Countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülkeler"])},
  "Download Getcontact to manage and top up your eSIM anytime, anywhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact'ı indirin ve eSIM'inizi her zaman her yerde yönetip bakiye yükleyin"])},
  "Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact"])},
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium ve Tarifeler"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özellikler"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarı Hikayeleri "])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkımızda"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kariyer"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSS"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil Gizliliğini Yönet"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorun bildir"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bize Ulaşın"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik Politikası / GDPR"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmet Koşullarımız"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satın Alma Politikamız"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
  "Go to eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM'e git"])},
  "footer.copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telif Hakkı © 2016-2023 Getverify LDA. Tüm hakları saklıdır. Getcontact™ tescilli bir markadır."])},
  "Secure Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güvenli Ödeme"])},
  "Select plan and continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir plan seçip devam edin"])},
  "Login or Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş yapın ya da kaydolun"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google ile giriş yap"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook ile giriş yap"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple ile giriş yap"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veya"])},
  "Enter Your Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresinizi girin"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam et"])},
  "login.when-clicked-continue-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"Devam et\" butonuna basarak ", _interpolate(_named("termOfService")), " ve ", _interpolate(_named("privacyPolicy")), " onay verdiğinizi kabul edersiniz."])},
  "Card Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart numarası"])},
  "Name On The Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartın üzerinde yazan isim"])},
  "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AA"])},
  "YY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YY"])},
  "CVV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
  "Complete Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi tamamla"])},
  "{amount} GB": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " GB"])},
  "{day} Day Valid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " Gün Geçerli"])},
  "{day} Days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " Gün"])},
  "{day} Day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " Gün"])},
  "Bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket"])},
  "{month} month Getcontact Premium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("month")), " ay Getcontact Premium"])},
  "Back to Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planlara Dön"])},
  "eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM"])},
  "When you clicked Payment Complete Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi tamamla butonuna tıkladığınızda"])},
  "When you clicked Update Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle butonuna tıkladığınızda"])},
  "Term of Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Koşulları"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik Sözleşmesi"])},
  "you are deemed to have accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kabul etmiş sayılırsınız"])},
  "3rd Party Integration Service Providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Parti Entegrasyon Hizmet Sağlayıcıları"])},
  "Account Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Bilgileri"])},
  "Saved Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı Kart"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış yap"])},
  "Follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takip Et"])},
  "My eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM'lerim"])},
  "Install an eSIM on your Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cihazınıza bir eSIM yükleyin"])},
  "This plan can only be purchased through the <b>eSIM io</b> mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu plan sadece eSIM io mobil uygulamasından satın alınabilir."])},
  "<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>esim.getcontact.com</b> servisi <b>eSIM.io</b> uygulamasında."])},
  "Scan the code to download the eSIM.io application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM io uygulamasanı indirmek için kodu tarayın."])},
  "Go to the application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamaya git"])},
  "QR Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR kodu"])},
  "Manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elle Kurulum"])},
  "Manual Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elle Kurulum"])},
  "Remaining Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalan Veri"])},
  "My Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketlerim"])},
  "Package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM"])},
  "Available Top-up Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanabileceğiniz bakiye yükleme paketleri"])},
  "Installation Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurulum Adımları"])},
  "<b>Important: eSIM</b> can only be installed once.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Önemli: eSIM</b> yalnızca bir tane eklenebilir."])},
  "<b>Do NOT DELETE/REMOVE</b> your eSIM from your device after successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı bir eSIM yüklemesi sonrası cihaz değişikliği yapamazsınız. Bu nedenle aktif eSIM paketinizi cihazınızdan kaldırmayın. "])},
  "QR Code Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Kod ile Kurulum"])},
  "Install your eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM'inizi yükleyin"])},
  "Installation Instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurulum Talimatları"])},
  "To manually activate the eSIM on your eSIM capable device you need to print or display this QR code on your device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM’inizi aktive etmek için QR kodu okutun"])},
  "Do not remove your eSIM from your device After successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı bir eSIM yüklemesi sonrası cihaz değişikliği yapamazsınız. Bu nedenle aktif eSIM paketinizi cihazınızdan kaldırmayın. "])},
  "To manually activate the eSIM on your eSIM capable device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM’ inizi manuel olarak aktive etmek için aşağıdaki adımları izleyin."])},
  "Please copy this information for manually installing the eSIM your device. é Make sure your device has a stable internet connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cihazınızda elle eSIM kurulumu yapmak için lütfen bu bilgiyi kopyalayın. Cihazınızın stabil bir internet bağlantısı olduğundan emin olun."])},
  "SM-DP-ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM-DP+ ADRESİ"])},
  "ACTIVATION CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AKTİVASYON KODU"])},
  "Thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teşekkürler"])},
  "Your plan is ready to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM’iniz kullanıma hazır."])},
  "Details have been sent to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayrıntılar e-mail adresinize gönderildi: "])},
  "If you wish, you can manage your eSIM installation from the Getcontact Mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM’inizi kullanmaya başlamak için kurulumunuzu tamamlayınız."])},
  "Download Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact'ı indirin "])},
  "First Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
  "Last Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyisim"])},
  "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresi"])},
  "You don’t have any credit card saved yet.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz kaydedilmiş bir kredi kartınız yok"])},
  "Add Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart Ekle"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
  "Expiry Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son kullanma tarihi:"])},
  "Purchase History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satın alma geçmişi"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketler"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
  "Expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süresi doldu"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekliyor"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke"])},
  "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri "])},
  "Validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerlilik"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ücret"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayrıntılar"])},
  "Show All Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün Paketleri Görüntüle"])},
  "Would you like to Purchase a new eSIM ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir eSIM satın almak ister misiniz?"])},
  "Purchase an eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemen satın al"])},
  "Log in / Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş yap / Kayıt ol"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
  "Back to Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketlere Dön"])},
  "Are you sure you want to delete your card?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartınızı silmek istediğinize emin misiniz?"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
  "An error occurred. Please try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "Expires on {date}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " tarihinde kullanım süresi dolacak"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
  "Copied!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyalandı!"])},
  "eSIM Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM Yükle"])},
  "installation-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satın aldığınız planları buradan yönetebilirsiniz."])},
  "add-saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir kart eklediğinizde, kartın ayrıntılarını burada görebilirsiniz."])},
  "saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı kartınız"])},
  "When you clicked Add Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle Butonuna bastığınızda"])},
  "You have successfully logged in. Redirecting...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş yapıldı. Yönlendiriliyor..."])},
  "Please fill in your name and surname to continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam etmek için lütfen adınızı ve soyadınızı girin"])},
  "Local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yerel eSIM'ler"])},
  "Regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölgesel eSIM'ler"])},
  "Global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küresel eSIM'ler"])},
  "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfaya Dön"])},
  "404.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradığınız sayfa bulunamadı"])},
  "404.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Sayfa Bulunamadı"])},
  "login.name-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim ve Soyisim "])},
  "packages.regional-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölgesel eSIM'ler"])},
  "packages.global-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küresel eSIM'ler"])},
  "packages.local-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yerel eSIM'ler"])},
  "packages.regional-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölgesel eSIM'ler"])},
  "packages.global-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küresel eSIM'ler"])},
  "packages.regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölgesel"])},
  "packages.global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küresel"])},
  "packages.local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yerel"])},
  "packages.supported-countries-modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desteklenen Ülkeler"])},
  "packages.supported-countries-modal.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küresel planımı hangi ülkelerde kullanabilirim?"])},
  "packages.supported-countries-modal.search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke Ara"])},
  "installation.local-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yerel Veri Planı"])},
  "installation.regional-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölgesel Veri Planı"])},
  "installation.global-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küresel Veri Planı"])},
  "installation.info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı bir eSIM yüklemesi sonrası cihaz değişikliği yapamazsınız. Bu nedenle aktif eSIM paketinizi cihazınızdan kaldırmayın."])},
  "installation.qr-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı bir eSIM yüklemesi sonrası cihaz değişikliği yapamazsınız. Bu nedenle aktif eSIM paketinizi cihazınızdan kaldırmayın."])},
  "installation.manual-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı bir eSIM yüklemesi sonrası cihaz değişikliği yapamazsınız. Bu nedenle aktif eSIM paketinizi cihazınızdan kaldırmayın."])},
  "installation.detail-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı bir eSIM yüklemesi sonrası cihaz değişikliği yapamazsınız. Bu nedenle aktif eSIM paketinizi cihazınızdan kaldırmayın."])},
  "installation.qr-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.Ayarlar > Hücresel/Mobil > Hücresel/Mobil Plan Ekle."])},
  "installation.qr-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.QR kodunu tarayın."])},
  "installation.qr-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.eSIM planı ayrıntılarını onaylayın."])},
  "installation.qr-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.eSIM’i etiketleyin."])},
  "installation.manual-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.Ayarlar > Hücresel/Mobil > Hücresel/Mobil Plan Ekle adımlarını izleyin"])},
  "installation.manual-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.\"Ayrıntıları Elle Girin\" kısmına tıklayın"])},
  "installation.manual-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.SM-DP+ Adresini ve Etkinleştirme Kodunu girin"])},
  "installation.manual-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.Hücresel plan ekle seçeneğine tıklayın"])},
  "installation.manual-installation-step-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5.eSIM'e isim verin"])},
  "packages.supported-countries-modal.global-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küresel planımı hangi ülkelerde kullanabilirim?"])},
  "packages.supported-countries-modal.regional-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölgesel planımı hangi ülkelerde kullanabilirim?"])},
  "packages.supported-countries-modal.local-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yerel planımı nerede kullanabilirim?"])},
  "This data plan can be used in {count} destinations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu veri planı ", _interpolate(_named("count")), " ülkede kullanılabilir"])},
  "cookiepolicy.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deneyiminizi iyileştirmek için yasalara uygun çerezler (cookies) kullanıyoruz."])},
  "cookiepolicy.description2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kullandığımız çerezler ve ", _interpolate(_named("policy")), "."])},
  "cookiepolicy.cookiepolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çerez Politikası"])},
  "cookiepolicy.policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politikamız"])},
  "homepage.banner.image.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIFA Katar Dünya Kupası seyahatiniz için eSIM veri planınızı satın alın!"])},
  "homepage.banner.button.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemen Al"])},
  "400601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz İsim"])},
  "400602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz Soy isim"])},
  "400008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "400009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "400011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "400220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "400221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "400222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "E0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme esnasında bir hata oluştu, Lütfen tekrar deneyin."])},
  "500221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş oluşturulurken bir hata oluştu, Lütfen tekrar deneyin."])},
  "400005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme işlemi başarısız."])},
  "404001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzgünüz, işleminiz başarısız oldu. Lütfen daha sonra tekrar deneyin."])},
  "401002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzgünüz, işleminiz başarısız oldu. Lütfen daha sonra tekrar deneyin."])},
  "400002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satın alınacak paket geçersiz. Lütfen başka bir paket deneyin."])},
  "400003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sisteminde hata oluştu, Lütfen tekrar deneyin."])},
  "400004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sisteminde hata oluştu, Lütfen tekrar deneyin."])},
  "400006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz kredi kartı."])},
  "400007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif bir abonelik olduğu için yeni abonelik başlatılamıyor. (Bu hata abonelik paketleri satınalma isteklerinde döner)"])},
  "400012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sisteminde hata oluştu, Lütfen tekrar deneyin."])},
  "400013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sisteminde hata oluştu, Lütfen tekrar deneyin."])},
  "400015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İade işleminde hata oluştu."])},
  "500001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sisteminde hata oluştu, Lütfen tekrar deneyin."])},
  "500002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abone oluştururken problem oluştu, Lütfen tekrar deneyin."])},
  "500003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abone kart kaydetme işleminde hata oluştu, Lütfen tekrar deneyin."])},
  "C0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ile ilgili bir problem oluştu. Lütfen sonra tekrar deneyin."])},
  "400223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygun Paket Bulunamadı, Lütfen tekrar deneyin."])},
  "500220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme esnasında bir hata oluştu, Lütfen tekrar deneyin."])},
  "Z_E0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme esnasında bir hata oluştu, Lütfen tekrar deneyin."])},
  "Z_404001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzgünüz, işleminiz başarısız oldu. Lütfen daha sonra tekrar deneyin."])},
  "Z_401002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzgünüz, işleminiz başarısız oldu. Lütfen daha sonra tekrar deneyin."])},
  "Z_400002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satın alınacak paket geçersiz. Lütfen başka bir paket deneyin."])},
  "Z_400003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sisteminde hata oluştu, Lütfen tekrar deneyin."])},
  "Z_400004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sisteminde hata oluştu, Lütfen tekrar deneyin."])},
  "Z_400005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme işlemi başarısız."])},
  "Z_400006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz kredi kartı."])},
  "Z_400007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif bir abonelik olduğu için yeni abonelik başlatılamıyor. (Bu hata abonelik paketleri satınalma isteklerinde döner)"])},
  "Z_400008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "Z_400009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "Z_400010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "Z_400011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "Z_400012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sisteminde hata oluştu, Lütfen tekrar deneyin."])},
  "Z_400013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sisteminde hata oluştu, Lütfen tekrar deneyin."])},
  "Z_400014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sisteminde hata oluştu, Lütfen tekrar deneyin."])},
  "Z_400015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İade işleminde hata oluştu."])},
  "Z_500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata oluştu. Lütfen tekrar deneyin."])},
  "Z_500001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sisteminde hata oluştu, Lütfen tekrar deneyin."])},
  "Z_500002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abone oluştururken problem oluştu, Lütfen tekrar deneyin."])},
  "Z_500003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abone kart kaydetme işleminde hata oluştu, Lütfen tekrar deneyin."])},
  "Z_C0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ile ilgili bir problem oluştu. Lütfen sonra tekrar deneyin."])},
  "Z_400220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "Z_400221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "Z_400222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "Z_400223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygun Paket Bulunamadı, Lütfen tekrar deneyin."])},
  "Z_500220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme esnasında bir hata oluştu, Lütfen tekrar deneyin."])},
  "Z_500221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş oluşturulurken bir hata oluştu, Lütfen tekrar deneyin."])},
  "400224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı ile sim id uyuşmadı."])},
  "400340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartı numarası hatalı."])},
  "400341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartı sahibi hatalı."])},
  "400342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartı son kullanma ayı hatalı."])},
  "400343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartı son kullanma yılı hatalı."])},
  "400344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartı cvv kodu hatalı."])},
  "403033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz hesap bilgisi"])},
  "500015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama hatası"])},
  "403002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum süre aşımına uğradı, Lütfen tekrar girmeyi deneyin."])},
  "400000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksik girdiğiniz bilgilerden dolayı isteğiniz yapılmadı, Lütfen tekrar deneyin"])},
  "Z_E0000027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölgenizdeki işlemlerimizde bazı öngörülmemiş değişikliklerle karşı karşıyayız. Talebinizi mümkün olan en kısa sürede karşılamak için çalışıyoruz."])},
  "E0000027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölgenizdeki işlemlerimizde bazı öngörülmemiş değişikliklerle karşı karşıyayız. Talebinizi mümkün olan en kısa sürede karşılamak için çalışıyoruz."])},
  "500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel hata"])},
  "400010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen girdiğiniz bilgileri kontrol edip, tekrar deneyin."])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numara hatalı. Lütfen bizimle iletişime geçin."])},
  "Z_E0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme Butonuna tıklanmadan işlem sonlandırılmıştır."])},
  "Z_E0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart limiti yetersiz olduğu için işlem başarısız sonuçlanmıştır."])},
  "Z_E0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güvenlik doğrulaması başarılı bir şekilde tamamlanamadığı için işlem başarısız olarak sonuçlandırılmıştır."])},
  "Z_E0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem başarısız olarak sonuçlanmıştır. Lütfen kartın bankası ile iletişime geçiniz"])},
  "Z_E0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknik bir hatadan dolayı işleminiz gerçekleştirilemedi Lütfen tekrar deneyiniz. Anlayışınız için teşekkürler."])},
  "Z_E0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme işlemi kullanıcı tarafından sonlandırıldı."])},
  "Z_E0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartınız online alışverişe kapalıdır. Lütfen bankanızla iletişime geçiniz."])},
  "Z_E0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güvenlik politikaları gereği işlem başarısız olarak sonlandırılmıştır."])},
  "Z_E0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz bir kart numarası girildiği için işlem başarısız sonuçlanmıştır."])},
  "Z_E0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartın son kullanma tarihi hatalı olduğu için işlem başarısız olarak sonuçlandırılmıştır."])},
  "Z_E0000011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı tarafından işleme onay verilmediği için işlem sonlandırılmıştır."])},
  "Z_E0000012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem limiti aşıldığı için işlem gerçekleştirilememiştir."])},
  "Z_E0000013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar bilgisi hatalı olduğundan ödeme gerçekleştirilemedi."])},
  "Z_E0000014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3DS güvenlik doğrulaması yapılamadığınızdan aboneliğiniz başlatılamadı."])},
  "Z_E0000015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme işlemine bankanız tarafından onay verilmemiştir."])},
  "Z_E0000016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme işlemine bankanız tarafından onay verilmedi. Lütfen bankanızla iletişime geçiniz."])},
  "Z_E0000017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV bilgisini hatalı girdiniz. Lütfen kontrol ederek işlemi tekrarlayınız."])},
  "Z_E0000018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartı bilgileri onaylanmadı. Lütfen kontrol ederek işlemi tekrarlayınız."])},
  "Z_E0000019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartınızın son kullanım tarihi sona ermiştir. Lütfen başka bir kart ile deneyiniz."])},
  "Z_E0000020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama kodunu birden fazla hatalı girdiğinizden işlem onaylanmadı."])},
  "Z_E0000021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknik bir hatadan dolayı işleminiz gerçekleştirilemedi Lütfen tekrar deneyiniz. Anlayışınız için teşekkürler."])},
  "Z_E0000022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartınız mevcut ödeme sağlayıcıda geçerli değildir. Lütfen başka bir kredi kartı ile deneyiniz."])},
  "Z_E0000023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme yapmak istediğiniz kredi kartına onay verilmedi. Lütfen farklı bir kredi kartı ile deneyiniz."])},
  "Z_E0000024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartına ait son kullanıma yılını hatalı girdiniz"])},
  "Z_E0000025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartına ait son kullanıma ayını hatalı girdiniz."])},
  "Z_E0000026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartına ait son kullanıma tarihini hatalı girdiniz."])},
  "Z_F0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılamadı. Lütfen müşteri hizmetleri ile iletişime geçiniz."])},
  "Z_F0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılamadı. Lütfen müşteri hizmetleri ile iletişime geçiniz."])},
  "Z_F0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılamadı. Lütfen müşteri hizmetleri ile iletişime geçiniz."])},
  "Z_F0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılamadı. Lütfen müşteri hizmetleri ile iletişime geçiniz."])},
  "Z_F0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılamadı. Lütfen müşteri hizmetleri ile iletişime geçiniz."])},
  "Z_F0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılamadı. Lütfen müşteri hizmetleri ile iletişime geçiniz."])},
  "Z_F0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılamadı. Lütfen müşteri hizmetleri ile iletişime geçiniz."])},
  "Z_F0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılamadı. Lütfen müşteri hizmetleri ile iletişime geçiniz."])},
  "Z_F0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılamadı. Lütfen müşteri hizmetleri ile iletişime geçiniz."])},
  "Z_F0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılamadı. Lütfen müşteri hizmetleri ile iletişime geçiniz."])},
  "Z_F0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılamadı. Lütfen müşteri hizmetleri ile iletişime geçiniz."])},
  "Z_S0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme işlemi başarıyla tamamlandı"])},
  "Z_R0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme işlemi için redirectUrl linkine yönlendirme yapmanız gereklidir."])},
  "order-status": {
    "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İade yapıldı"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süresi doldu"])},
    "ORDER_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanımlama hatası"])},
    "PAYMENT_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme başarısız oldu"])},
    "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silindi"])},
    "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekleniyor"])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri hazırlanıyor"])},
    "PENDING_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanması bekleniyor"])},
    "DEPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Doldu"])},
    "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süresi Doldu"])}
  },
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afganistan"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arnavutluk"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cezayir"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerikan Samoası"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antarktika"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua ve Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arjantin"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ermenistan"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avustralya"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avusturya"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaycan"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamalar"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahreyn"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladeş"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belçika"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butan"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivya"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosna-Hersek"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botsvana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brezilya"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britanya Hint Okyanusu Toprakları"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britanya Virjin Adaları"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaristan"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamboçya"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamerun"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cape Verde"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cayman Adaları"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orta Afrika Cumhuriyeti"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şili"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çin"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Christmas Adası"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocos (Keeling) Adaları"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolombiya"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komorlar"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cook Adaları"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosta Rika"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hırvatistan"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaçao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kıbrıs"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekya"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kongo - Kinşasa"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danimarka"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cibuti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominika"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominik Cumhuriyeti"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor-Leste"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekvador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mısır"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekvator Ginesi"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritre"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonya"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiyopya"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falkland Adaları"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faroe Adaları"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlandiya"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransa"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransız Guyanası"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransız Polinezyası"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambiya"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gürcistan"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almanya"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cebelitarık"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yunanistan"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grönland"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gine"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gine-Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çin Hong Kong ÖİB"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macaristan"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzlanda"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindistan"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endonezya"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İran"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irak"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İrlanda"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man Adası"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsrail"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İtalya"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fildişi Sahili"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaika"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonya"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürdün"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakistan"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosova"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kırgızistan"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letonya"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lübnan"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberya"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libya"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litvanya"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lüksemburg"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çin Makao ÖİB"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuzey Makedonya"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagaskar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malavi"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malezya"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldivler"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshall Adaları"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinik"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moritanya"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meksika"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikronezya"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monako"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moğolistan"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karadağ"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fas"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambik"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Burma)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibya"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hollanda"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kaledonya"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Zelanda"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikaragua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nijer"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nijerya"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norfolk Adası"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuzey Kore"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuzey Mariana Adaları"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norveç"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umman"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filistin Bölgeleri"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua Yeni Gine"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipinler"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairn Adaları"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polonya"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portekiz"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porto Riko"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katar"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kongo Cumhuriyeti"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanya"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusya"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Barthelemy"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Helena"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts ve Nevis"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Martin"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Pierre ve Miquelon"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent ve Grenadinler"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Tomé ve Príncipe"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suudi Arabistan"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sırbistan"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seyşeller"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapur"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint Maarten"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakya"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenya"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solomon Adaları"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somali"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güney Afrika"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güney Kore"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güney Sudan"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İspanya"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard ve Jan Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esvatini"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsveç"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsviçre"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriye"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tayvan"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tacikistan"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzanya"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tayland"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad ve Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunus"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkiye"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkmenistan"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks ve Caicos Adaları"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABD Virjin Adaları"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrayna"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birleşik Arap Emirlikleri"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birleşik Krallık"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerika Birleşik Devletleri"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özbekistan"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatikan"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis ve Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batı Sahra"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambiya"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabve"])}
  },
  "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALINDI"])},
  "FULFILLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAMAMLANIYOR"])},
  "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAMAMLANDI"])},
  "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAMAMLANAMADI"])},
  "page-titles": {
    "app-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact eSIM"])},
    "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Ekran"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş Yap"])},
    "login-by-verifykit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifykit ile Giriş Yap"])},
    "country-packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke Paketleri"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Ödemesi"])},
    "payment-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme Başarılı"])},
    "installation-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurulum Detayları"])},
    "installation-qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Kodu İle Kurulum"])},
    "installation-manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elle Kurulum"])},
    "account-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Bilgileri"])},
    "saved-cards-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı Kartlar"])},
    "saved-cards-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı Kartlara Ekle"])},
    "saved-cards-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı Kartları Düzenle"])},
    "my-esim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM'im"])},
    "404-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Bulunamadı"])}
  },
  "saved-cards.update-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart Güncelle"])},
  "credit-card.card-number-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz kart numarası"])},
  "credit-card.card-owner-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz kart sahibi"])},
  "credit-card.card-expiration-month-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz son kullanma ayı"])},
  "credit-card.card-expiration-year-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz son kullanma yılı"])},
  "credit-card.card-cvv-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz güvenlik kodu"])},
  "saved-cards.add-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart Ekle"])},
  "payment-success.order-type-topup-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM ayrıntılarınız için tıklayın"])},
  "saved-cards.add-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart ekleme işlemi başarılı."])},
  "saved-cards.update-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart güncelleme işlemi başarılı."])},
  "saved-cards.delete-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart silme işlemi başarılı."])},
  "general-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu. Lütfen tekrar deneyin."])},
  "search-country.empty-state-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke bulunamadı"])},
  "account-information.update-profile-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil bilgileriniz başarıyla güncellendi."])},
  "installation.order-does-not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradığınız sipariş bulunmamaktadır."])},
  "Update card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartı Güncelle"])}
}