export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium e tarifas"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórias de êxito"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre Nós"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carreira"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuda"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perguntas Frequentes"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça a Gestão da Privacidade do Seu Perfil"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reportar um problema"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fale Conosco"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գաղտնիության քաղաքականություն"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os Nossos Termos de Serviço"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Nossa Política de Compra"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar a Sessão com o Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar a Sessão com o Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar a Sessão com a Apple"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro de número. Favor entrar em contato conosco."])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afeganistão"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albânia"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argélia"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa Americana"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguila"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antártida"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antígua e Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armênia"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austrália"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áustria"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijão"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrein"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bielorrússia"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bélgica"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermudas"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butão"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolívia"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bósnia e Herzegovina"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botsuana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brasil"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Território Britânico do Oceano Índico"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Virgens Britânicas"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgária"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burquina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camboja"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camarões"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canadá"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabo Verde"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Cayman"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Centro-Africana"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chade"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Christmas"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Cocos (Keeling)"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colômbia"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comores"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Cook"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croácia"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaçao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chipre"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchéquia"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo - Kinshasa"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinamarca"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibuti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Dominicana"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor-Leste"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egito"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiné Equatorial"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritreia"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estônia"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiópia"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Malvinas"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Faroe"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlândia"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["França"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiana Francesa"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polinésia Francesa"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabão"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gâmbia"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geórgia"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alemanha"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grécia"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groenlândia"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granada"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadalupe"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiné"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiné-Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong- RAE da China"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungria"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islândia"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índia"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonésia"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irã"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraque"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlanda"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha de Man"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itália"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa do Marfim"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japão"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordânia"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cazaquistão"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quênia"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quirguistão"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letônia"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líbano"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesoto"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libéria"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líbia"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituânia"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxemburgo"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau- RAE da China"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedônia do Norte"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaui"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malásia"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldivas"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Marshall"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinica"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritânia"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maurício"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["México"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronésia"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mônaco"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongólia"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marrocos"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moçambique"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mianmar (Birmânia)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namíbia"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países Baixos"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Caledônia"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Zelândia"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicarágua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigéria"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Norfolk"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coreia do Norte"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Marianas do Norte"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noruega"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omã"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquistão"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territórios palestinos"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panamá"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua-Nova Guiné"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguai"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipinas"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Pitcairn"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polônia"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porto Rico"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catar"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República do Congo"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reunião"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romênia"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rússia"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Bartolomeu"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santa Helena"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Cristóvão e Névis"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santa Lúcia"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Martinho"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Pedro e Miquelão"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Vicente e Granadinas"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Tomé e Príncipe"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arábia Saudita"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sérvia"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seicheles"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serra Leoa"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapura"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint Maarten"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eslováquia"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eslovênia"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Salomão"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somália"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["África do Sul"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coreia do Sul"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudão do Sul"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espanha"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudão"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard e Jan Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suazilândia"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suécia"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suíça"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síria"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tadjiquistão"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzânia"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailândia"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad e Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunísia"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turquia"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turcomenistão"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Turcas e Caicos"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Virgens Americanas"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ucrânia"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emirados Árabes Unidos"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reino Unido"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estados Unidos"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguai"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbequistão"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade do Vaticano"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnã"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis e Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saara Ocidental"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iêmen"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zâmbia"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbábue"])}
  }
}