export default {
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти"])},
  "Data Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифні плани"])},
  "Data without border at yourfingertips {quantity} Travel destinations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Інтернет без меж на кінчиках пальців ", _interpolate(_named("quantity")), " Напрями подорожей"])},
  "Search your destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук Тарифних Пакетів"])},
  "Countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країни"])},
  "Download Getcontact to manage and top up your eSIM anytime, anywhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажте Getcontact для управління та поповнення Вашої eSIM у будь-який час, у будь-якому місці"])},
  "Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact"])},
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium та Тарифи "])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функції"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історії успіху"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про нас"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кар'єра"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допомога"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЧаПи"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управління конфіденційністю профілю"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомити про проблему"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зв'яжіться з нами"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Захист даних"])},
  "Go to eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти до eSim"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy / GDPR"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наші умови обслуговування"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша політика покупок"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])},
  "footer.copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторські права © 2016-2023 Getverify LDA. Всі права захищені. Getcontact™ є зареєстрованою маркою."])},
  "Secure Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безпечна Оплата"])},
  "Select plan and continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть план та продовжіть"])},
  "Login or Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти або Зареєструватися"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти через Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти через Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти через Apple"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["або"])},
  "Enter Your Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть Вашу електронну пошту"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
  "Card Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер картки"])},
  "Name On The Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я на карті"])},
  "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
  "YY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РР"])},
  "CVV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
  "Complete Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сплатити"])},
  "{amount} GB": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " ГБ"])},
  "{day} Day Valid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дійсно ", _interpolate(_named("day")), " днів"])},
  "{day} Days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " днів"])},
  "{day} Day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " ДЕНЬ"])},
  "Bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бандл"])},
  "{month} month Getcontact Premium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("month")), " місяців Getcontact Premium"])},
  "Back to Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися до Планів"])},
  "eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM"])},
  "When you clicked Payment Complete Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коли Ви натиснете на кнопку здійснити оплату"])},
  "When you clicked Update Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коли Ви натискаєте кнопку Оновити"])},
  "Update card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити Карту"])},
  "Term of Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умови використання"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Політика конфіденційності"])},
  "you are deemed to have accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["будуть вважатися прийнятими"])},
  "3rd Party Integration Service Providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сторонні постачальники послуг інтеграції"])},
  "Account Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про Обліковий запис"])},
  "Saved Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Збережена картка"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти"])},
  "Follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слідкувати"])},
  "My eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моя eSIM"])},
  "Install an eSIM on your Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встановити eSIM на Ваш пристрій"])},
  "This plan can only be purchased through the <b>eSIM io</b> mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This plan can only be purchased through the <b>eSIM io</b> mobile application."])},
  "<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application."])},
  "Scan the code to download the eSIM.io application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the code to download the eSIM.io application."])},
  "Go to the application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the application"])},
  "QR Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Код"])},
  "Manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мануально"])},
  "Manual Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мануальна установка"])},
  "Remaining Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтернет, що залишився"])},
  "My Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої пакети"])},
  "Available Top-up Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакети, доступні для поповнення"])},
  "Installation Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кроки Установки"])},
  "<b>Important: eSIM</b> can only be installed once.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Важливо: eSIM</b> можна встановити лише один раз."])},
  "<b>Do NOT DELETE/REMOVE</b> your eSIM from your device after successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b> НЕ ВИДАЛЯЙТЕ</b> Вашу eSIM з Вашого пристрою після успішної установки."])},
  "QR Code Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установка з QR-кодом"])},
  "Install your eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встановіть Вашу eSIM"])},
  "Installation Instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інструкції з встановлення"])},
  "To manually activate the eSIM on your eSIM capable device you need to print or display this QR code on your device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відскануйте QR код, щоб активувати Вашу eSIM."])},
  "Do not remove your eSIM from your device After successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не видаляйте eSIM з Вашого пристрою після успішного встановлення"])},
  "To manually activate the eSIM on your eSIM capable device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для мануальної активації eSIM на Вашому придатному для eSIM пристрої"])},
  "Please copy this information for manually installing the eSIM your device. é Make sure your device has a stable internet connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопіюйте ці дані для мануальної установки eSIM на Вашому пристрої. Переконайтеся, що Ваш пристрій має стабільне підключення до Інтернету."])},
  "SM-DP-ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM-DP-АДРЕСА"])},
  "ACTIVATION CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КОД АКТИВАЦІЇ"])},
  "Thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дякуємо"])},
  "Your plan is ready to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш план готовий до використання."])},
  "Details have been sent to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детальна інформація була надіслана на:"])},
  "If you wish, you can manage your eSIM installation from the Getcontact Mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо Ви бажаєте, Ви можете керувати установкою Вашої eSIM через мобільний додаток Getcontact."])},
  "Download Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити Getcontact"])},
  "First Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
  "Last Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище"])},
  "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])},
  "You don’t have any credit card saved yet.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У Вас немає збереженої кредитної картки."])},
  "Add Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати картку"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "Expiry Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін дії:"])},
  "Purchase History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія Покупок"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активний"])},
  "Expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закінчився"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очікується"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країна"])},
  "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тариф"])},
  "Validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період дії"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ціна"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деталі"])},
  "Show All Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати усі пакети"])},
  "Would you like to Purchase a new eSIM ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи бажаєте Ви придбати нову eSIM?"])},
  "Purchase an eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купити eSIM"])},
  "Log in / Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти / Зареєструватися"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова"])},
  "Back to Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися до пакетів"])},
  "Are you sure you want to delete your card?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви впевнені, що хочете видалити Вашу картку?"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ні"])},
  "An error occurred. Please try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка. Будь ласка, спробуйте ще раз."])},
  "Expires on {date}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Термін дії закінчиться ", _interpolate(_named("date"))])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
  "Copied!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопійовано!"])},
  "eSIM Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деталі eSIM"])},
  "my-esim-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У Вас немає eSIM плану"])},
  "When you clicked Add Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коли Ви натискаєте на кнопку Додати"])},
  "installation-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете керувати придбаними планами тут."])},
  "add-saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коли Ви додаєте нову картку, Ви побачите подробиці про збережену картку тут."])},
  "saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша збережена картка"])},
  "You have successfully logged in. Redirecting...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви успішно увійшли. Перенаправлення..."])},
  "Please fill in your name and surname to continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, введіть Ваше ім'я та прізвище, щоб продовжити"])},
  "Local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місцевий"])},
  "Regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регіональний"])},
  "Global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальний"])},
  "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад до Головної Сторінки"])},
  "404.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сторінку, яку Ви шукаєте, не знайдено."])},
  "404.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Сторінка не Знайдена"])},
  "login.name-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я та прізвище"])},
  "packages.regional-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регіональні eSIM"])},
  "packages.global-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальні eSIM"])},
  "packages.local-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місцеві eSIM"])},
  "packages.regional-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регіональні eSIM"])},
  "packages.global-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальні eSIM"])},
  "packages.regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регіональний"])},
  "packages.global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальний"])},
  "packages.local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місцевий"])},
  "packages.supported-countries-modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країни, що підтримуються"])},
  "packages.supported-countries-modal.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В яких країнах я зможу використовувати свій Глобальний план?"])},
  "packages.supported-countries-modal.search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук країни"])},
  "installation.local-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місцеві Тарифні Плани"])},
  "installation.regional-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регіональні Тарифні Плани"])},
  "installation.global-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальні Тарифні Плани"])},
  "installation.qr-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не зможете змінити пристрій після успішного встановлення. Тому не видаляйте активну eSIM із Вашого пристрою."])},
  "installation.manual-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не зможете змінити пристрій після успішного встановлення. Тому не видаляйте активну eSIM із Вашого пристрою."])},
  "installation.detail-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не зможете змінити пристрій після успішного встановлення. Тому не видаляйте активну eSIM із Вашого пристрою."])},
  "installation.qr-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Налаштування > Стільникова/Мобільна мережа > Додати Стільниковий/Мобільний План."])},
  "installation.qr-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Відскануйте QR-код."])},
  "installation.qr-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.Підтвердьте деталі плану eSIM."])},
  "installation.qr-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Позначте eSIM."])},
  "installation.manual-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Перейдіть до Установки > Стільниковий/мобільний зв'язок > Додати тарифні плани стільникового/мобільного зв'язку"])},
  "installation.manual-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Натисніть ввести вручну"])},
  "installation.manual-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Введіть Вашу SM-DP-Адресу та Код активування"])},
  "installation.manual-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Натисніть додати стільниковий план"])},
  "installation.manual-installation-step-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Позначте eSIM"])},
  "packages.supported-countries-modal.global-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В яких країнах я зможу використовувати свій Глобальний план?"])},
  "packages.supported-countries-modal.regional-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В яких країнах я зможу використовувати свій Регіональний план?"])},
  "packages.supported-countries-modal.local-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Де я зможу використати мій Місцевий план?"])},
  "This data plan can be used in {count} destinations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цей тарифний план можна використовувати у ", _interpolate(_named("count")), " місцях"])},
  "cookiepolicy.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми використовуємо дозволені файли кукі (cookie), щоб покращити користування."])},
  "cookiepolicy.description2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Файли cookie, які ми використовуємо, і ", _interpolate(_named("policy")), "."])},
  "cookiepolicy.cookiepolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Політика використання файлів cookie"])},
  "cookiepolicy.policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша політика"])},
  "homepage.banner.image.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купуйте план eSIM для поїздки до Катару для Чемпіонату світу з футболу FIFA!"])},
  "homepage.banner.button.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купуйте Зараз"])},
  "400601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильне Ім'я"])},
  "400602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильне Прізвище"])},
  "400008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка. Будь ласка, спробуйте ще раз."])},
  "400009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка. Будь ласка, спробуйте ще раз."])},
  "400011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка. Будь ласка, спробуйте ще раз."])},
  "400220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка. Будь ласка, спробуйте ще раз."])},
  "400221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка. Будь ласка, спробуйте ще раз."])},
  "400222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка. Будь ласка, спробуйте ще раз."])},
  "500221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка під час замовлення. Будь ласка, спробуйте ще раз."])},
  "400005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція не виконана."])},
  "404001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція не виконана. Будь ласка, спробуйте пізніше."])},
  "401002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція не виконана. Будь ласка, спробуйте пізніше."])},
  "400002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недійсний пакет. Будь ласка, спробуйте інший пакет."])},
  "400003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у платіжній системі. Будь ласка, спробуйте ще раз."])},
  "400004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у платіжній системі. Будь ласка, спробуйте ще раз."])},
  "400006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недійсна кредитна картка."])},
  "400007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо розпочати нову підписку, оскільки у Вас вже є активна підписка."])},
  "400012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у платіжній системі. Будь ласка, спробуйте ще раз."])},
  "400013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у платіжній системі. Будь ласка, спробуйте ще раз."])},
  "400015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у процесі повернення коштів."])},
  "500001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у платіжній системі. Будь ласка, спробуйте ще раз."])},
  "500002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка під час створення підписки. Будь ласка, спробуйте ще раз."])},
  "500003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час збереження картки сталася помилка. Будь ласка, спробуйте пізніше."])},
  "C0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка, пов'язана із оплатою. Будь ласка, спробуйте пізніше."])},
  "400223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не знайдено відповідний пакет. Будь ласка, спробуйте ще раз."])},
  "500220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка під час сплати. Будь ласка, спробуйте ще раз."])},
  "400014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильна електронна адреса"])},
  "Z_E0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцію завершено без натискання на кнопку Оплата "])},
  "Z_E0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Через недостатній ліміт на карті транзакція не вдалася"])},
  "Z_E0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакцію не вдалося здійснити, оскільки перевірка безпеки завершилась невдало "])},
  "Z_E0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція не вдалася. Будь ласка, зв'яжіться з Вашим банком"])},
  "Z_E0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція не вдалася через технічну помилку. Будь ласка, спробуйте ще раз"])},
  "Z_E0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція була скасована користувачем"])},
  "Z_E0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша карта закрита для купівель онлайн. Будь ласка, зв'яжіться з Вашим банком"])},
  "Z_E0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакцію було невдалою через політику безпеки."])},
  "Z_E0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакцію не вдалося здійснити, оскільки введено недійсний номер картки."])},
  "Z_E0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакцію було здійснено невдало через неправильну дату закінчення терміну дії картки."])},
  "Z_E0000011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція була скасована, оскільки користувач не схвалив транзакцію."])},
  "Z_E0000012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція була скасована через перевищення ліміту транзакцій."])},
  "Z_E0000013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося здійснити оплату через неправильну загальну суму."])},
  "Z_E0000014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу підписку не вдалося ініціалізувати, оскільки перевірка безпеки 3DS не була завершена."])},
  "Z_E0000015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція була відхилена Вашим банком."])},
  "Z_E0000016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція була відхилена Вашим банком. Будь ласка, зв'яжіться з Вашим банком."])},
  "Z_E0000017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви ввели неправильні дані CVV. Будь ласка, перевірте дані та спробуйте ще раз."])},
  "Z_E0000018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про кредитну картку не підтверджена. Будь ласка, перевірте та спробуйте ще раз."])},
  "Z_E0000019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін дії Вашої кредитної картки закінчився. Будь ласка, спробуйте з іншою карткою."])},
  "Z_E0000020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція не була підтверджена, оскільки Ви кілька разів неправильно ввели код підтвердження."])},
  "Z_E0000021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити через технічну помилку. Спробуйте ще раз. Дякуємо за розуміння."])},
  "Z_E0000022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша кредитна картка не приймається діючим постачальником платежів. Будь ласка, спробуйте з іншою кредитною карткою."])},
  "Z_E0000023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитну картку, якою Ви намагаєтеся скористатися, не схвалено. Будь ласка, спробуйте з іншою кредитною карткою."])},
  "Z_E0000024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви неправильно ввели рік закінчення терміну дії кредитної картки."])},
  "Z_E0000025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви неправильно ввели місяць закінчення терміну дії кредитної картки."])},
  "Z_E0000026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви неправильно ввели термін дії кредитної картки."])},
  "Z_F0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити. Зверніться, будь ласка, до служби підтримки клієнтів."])},
  "Z_F0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити. Зверніться, будь ласка, до служби підтримки клієнтів."])},
  "Z_F0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити. Зверніться, будь ласка, до служби підтримки клієнтів."])},
  "Z_F0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити. Зверніться, будь ласка, до служби підтримки клієнтів."])},
  "Z_F0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити. Зверніться, будь ласка, до служби підтримки."])},
  "Z_F0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити. Зверніться, будь ласка, до служби підтримки."])},
  "Z_F0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити. Зверніться, будь ласка, до служби підтримки."])},
  "Z_F0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити. Зверніться, будь ласка, до служби підтримки."])},
  "Z_F0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити. Зверніться, будь ласка, до служби підтримки."])},
  "Z_F0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити. Зверніться, будь ласка, до служби підтримки."])},
  "Z_F0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу транзакцію не вдалося завершити. Зверніться, будь ласка, до служби підтримки."])},
  "Z_S0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процес оплати успішно завершено"])},
  "Z_R0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для здійснення процесу оплати Вам потрібно перейти за посиланням redirectUrl."])},
  "Z_404001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція не виконана. Будь ласка, спробуйте пізніше."])},
  "Z_401002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція не виконана. Будь ласка, спробуйте пізніше."])},
  "Z_400002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недійсний пакет. Будь ласка, спробуйте інший пакет."])},
  "Z_400003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у платіжній системі. Будь ласка, спробуйте ще раз."])},
  "Z_400004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у платіжній системі. Будь ласка, спробуйте ще раз."])},
  "Z_400005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція не виконана."])},
  "Z_400006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недійсна кредитна картка."])},
  "Z_400007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо розпочати нову підписку, оскільки у Вас вже є активна підписка."])},
  "Z_400008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка. Будь ласка, спробуйте ще раз."])},
  "Z_400009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка. Будь ласка, спробуйте ще раз."])},
  "Z_400010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка. Будь ласка, спробуйте ще раз."])},
  "Z_400011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка. Будь ласка, спробуйте ще раз."])},
  "Z_400012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у платіжній системі. Будь ласка, спробуйте ще раз."])},
  "Z_400013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у платіжній системі. Будь ласка, спробуйте ще раз."])},
  "Z_400014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у платіжній системі. Будь ласка, спробуйте ще раз."])},
  "Z_400015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у процесі повернення коштів."])},
  "Z_500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка. Будь ласка, спробуйте ще раз."])},
  "Z_500001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка у платіжній системі. Будь ласка, спробуйте ще раз."])},
  "Z_500002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка під час створення підписки. Будь ласка, спробуйте ще раз."])},
  "Z_500003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час збереження картки сталася помилка. Будь ласка, спробуйте пізніше."])},
  "Z_C0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка, пов'язана із оплатою. Будь ласка, спробуйте пізніше."])},
  "Z_400220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка. Будь ласка, спробуйте ще раз."])},
  "Z_400221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка. Будь ласка, спробуйте ще раз."])},
  "Z_400222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка. Будь ласка, спробуйте ще раз."])},
  "Z_400223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не знайдено відповідний пакет. Будь ласка, спробуйте ще раз."])},
  "Z_500220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка під час сплати. Будь ласка, спробуйте ще раз."])},
  "Z_500221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка під час замовлення. Будь ласка, спробуйте ще раз."])},
  "Z_E0000027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми зіткнулися з деякими непередбаченими змінами у наших операціях у вашому регіоні. Проте, в даний час ми працюємо над тим, щоб вирішити Ваш запит якнайшвидше."])},
  "E0000027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми зіткнулися з деякими непередбаченими змінами у наших операціях у вашому регіоні. Проте, в даний час ми працюємо над тим, щоб вирішити Ваш запит якнайшвидше."])},
  "Z_E0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка під час сплати. Будь ласка, спробуйте ще раз."])},
  "E0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка під час сплати. Будь ласка, спробуйте ще раз."])},
  "500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальна помилка"])},
  "400010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірте дані, які Ви ввели, та спробуйте ще раз."])},
  "400224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID користувача та SIM не збігаються."])},
  "400340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний номер кредитної картки."])},
  "400341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильне ім'я власника кредитної картки."])},
  "400342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний місяць закінчення терміну дії кредитної картки."])},
  "400343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний рік закінчення терміну дії кредитної картки."])},
  "400344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний код підтвердження кредитної картки."])},
  "403033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильна інформація облікового запису."])},
  "500015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка під час підтвердження."])},
  "403002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час сеансу вичерпано. Будь ласка, спробуйте увійти знову."])},
  "400000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш запит не був виконаний через брак інформації. Будь ласка, спробуйте ще раз."])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер містить помилку. Будь ласка, зв'яжіться з нами"])},
  "order-status": {
    "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата повернена."])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вичерпано."])},
    "ORDER_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка під час визначення."])},
    "PAYMENT_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка під час оплати."])},
    "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активний."])},
    "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалено."])},
    "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очікується."])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакет створюється."])},
    "PENDING_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очікується завершення."])},
    "DEPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміт Закінчився"])},
    "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вичерпано"])}
  },
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Афганістан"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Албанія"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алжир"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Американське Самоа"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Андорра"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ангола"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анґілья"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антарктика"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антиґуа і Барбуда"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аргентина"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вірменія"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аруба"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австралія"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австрія"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азербайджан"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Багамські Острови"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бахрейн"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бангладеш"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барбадос"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Білорусь"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бельґія"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беліз"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бенін"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бермудські Острови"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутан"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болівія"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боснія і Герцеґовина"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботсвана"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бразілія"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Британська територія в Індійському Океані"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Британські Віргінські острови"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бруней"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болгарія"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Буркіна-Фасо"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бурунді"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камбоджа"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камерун"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канада"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабо-Верде"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кайманові Острови"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центральноафриканська Республіка"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чад"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чілі"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Китай"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острів Різдва"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кокосові (Кілінґ) Острови"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колумбія"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комори"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острови Кука"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коста-Ріка"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хорватія"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куба"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кюрасао"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіпр"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чехія"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конго – Кіншаса"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данія"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джибуті"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домініка"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домініканська Республіка"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тімор-Лешті"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еквадор"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Єгипет"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сальвадор"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екваторіальна Гвінея"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еритрея"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Естонія"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ефіопія"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фолклендські Острови"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фарерські Острови"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фіджі"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фінляндія"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Франція"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Французька Ґвіана"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Французька Полінезія"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Габон"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гамбія"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грузія"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Німеччина"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гана"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ґібралтар"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Греція"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ґренландія"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ґренада"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ґваделупа"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ґуам"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ґватемала"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ґернсі"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвінея"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвінея-Бісау"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ґайана"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гаїті"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гондурас"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гонконг- О.А.Р. Китаю"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угорщина"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ісландія"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Індія"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Індонезія"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іран"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ірак"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ірландія"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острів Мен"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ізраїль"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Італія"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кот-дʼІвуар"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ямайка"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Японія"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джерсі"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йорданія"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Казахстан"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кенія"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кірібаті"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Косово"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Палау"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Киргизстан"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаос"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латвія"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліван"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лесото"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліберія"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лівія"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліхтенштейн"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Литва"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люксембурґ"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макао- О.А.Р Китаю"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Північна Македонія"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мадагаскар"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малаві"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малайзія"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мальдіви"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малі"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мальта"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маршаллові Острови"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мартініка"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мавританія"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маврікій"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майотта"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мексика"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мікронезія"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молдова"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монако"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монголія"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чорногорія"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монтсеррат"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Марокко"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мозамбік"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мʼянма (Бірма)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намібія"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Науру"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непал"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нідерланди"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова Каледонія"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова Зеландія"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нікараґуа"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нігер"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нігерія"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ніуе"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острів Норфолк"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Північна Корея"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Північні Маріанські Острови"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Норвеґія"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оман"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакистан"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Палестинські території"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панама"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папуа-Нова Ґвінея"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параґвай"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перу"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Філіппіни"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острови Піткерн"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Польща"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портуґалія"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пуерто-Ріко"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Катар"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Республіка Конго"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реюньйон"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Румунія"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Росія"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Руанда"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сен-Бартельмі"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острів Святої Єлени"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Кітс і Невіс"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Люсія"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сен-Мартен"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сен-Пʼєр і Мікелон"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Вінсент і Ґренадіни"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самоа"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан-Маріно"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан-Томе і Прінсіпі"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саудівська Аравія"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сенегал"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сербія"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейшельські Острови"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьєрра-Леоне"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сінгапур"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сінт-Мартен"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словаччина"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словенія"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соломонові Острови"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сомалі"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Південно-Африканська Республіка"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Південна Корея"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Південний Судан"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іспанія"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шрі-Ланка"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судан"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сурінам"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шпіцберген та Ян-Маєн"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есватіні"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швеція"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швейцарія"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сирія"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тайвань"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таджикистан"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Танзанія"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таїланд"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Того"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токелау"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тонґа"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трінідад і Тобаґо"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туніс"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туреччина"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туркменістан"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Острови Теркс і Кайкос"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тувалу"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віргінські острови- США"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уганда"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Україна"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обʼєднані Арабські Емірати"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Велика Британія"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сполучені Штати"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уруґвай"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узбекистан"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вануату"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ватикан"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Венесуела"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вʼєтнам"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уолліс і Футуна"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Західна Сахара"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ємен"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замбія"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зімбабве"])}
  },
  "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПРИЙНЯТО"])},
  "FULFILLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВИКОНУЄТЬСЯ"])},
  "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЗАВЕРШЕНО"])},
  "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НЕУСПІШНО"])},
  "page-titles": {
    "app-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact eSIM"])},
    "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головна сторінка"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LВхід"])},
    "login-by-verifykit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вхід з VerifyKit"])},
    "country-packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакети для країн"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата пакетів"])},
    "payment-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішний платіж"])},
    "installation-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деталі по налаштуванням"])},
    "installation-qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR для установки"])},
    "installation-manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мануальна установка"])},
    "account-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про Обліковий запис"])},
    "saved-cards-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Збережені карти"])},
    "saved-cards-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати до Збережених"])},
    "saved-cards-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати Збережені Картки"])},
    "my-esim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моя eSIM"])},
    "404-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сторінка не знайдена"])}
  },
  "credit-card.card-number-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірний номер картки"])},
  "credit-card.card-owner-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильне ім'я на картці"])},
  "credit-card.card-expiration-month-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірний місяць дії картки"])},
  "credit-card.card-expiration-year-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірний рік дії картки"])},
  "credit-card.card-cvv-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний код безпеки картки"])},
  "saved-cards.add-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати Картку"])},
  "saved-cards.update-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити Карту"])},
  "payment-success.order-type-topup-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натисніть для детальної інформації про Вашу eSIM"])},
  "saved-cards.add-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта була успішно додана."])},
  "saved-cards.update-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта була успішно змінена."])},
  "saved-cards.delete-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта була успішно видалена."])},
  "general-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щось пішло не так, спробуйте ще раз."])},
  "search-country.empty-state-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країну не знайдено."])},
  "account-information.update-profile-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація Вашого профілю була успішно оновлена."])},
  "installation.order-does-not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замовлення, яке Ви шукаєте, не існує."])}
}