export default {
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium y tarifas"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historias de éxito"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrera"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas más frecuentes"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administre su perfil de privacidad"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informar de un problema"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta con nosotros"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protección de datos"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagkumpidensiyalidad"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestras condiciones de servicio"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra política de compras"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "Copyright © 2016-2023 Getverify LDA. All rights reserved. Getcontact™ is a registered trademark.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © 2016-2023 Getverify LDA. Todos los derechos reservados. Getcontact™ es una marca registrada."])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con Apple"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
  "Enter Your Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce tu dirección de correo electrónico."])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "Data Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planes de datos"])},
  "Data without border at yourfingertips {quantity} Travel destinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La forma más fácil de estar conectado a Internet durante sus viajes"])},
  "Search your destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar paquetes de datos"])},
  "Countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
  "Download Getcontact to manage and top up your eSIM anytime, anywhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargue Getcontact para gestionar y recargar su eSIM en cualquier momento y lugar"])},
  "Secure Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago seguro"])},
  "Select plan and continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el plan y continúe"])},
  "Login or Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicie sesión o regístrese."])},
  "Card Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tarjeta"])},
  "Name On The Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre que aparece en la tarjeta"])},
  "Complete Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar pago"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "Thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias"])},
  "First Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "Last Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
  "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
  "Expiry Date:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de caducidad"])},
  "By clicking 'Continue', you agree to our Terms of Service and Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al hacer clic en \"Continuar\", aceptas nuestros Términos de Servicio y Política de Privacidad"])},
  "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
  "YY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AA"])},
  "{amount} GB": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " GB"])},
  "{day} Day Valid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " Día válido"])},
  "{day} Days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " Días"])},
  "Bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquete"])},
  "{month} month Getcontact Premium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("month")), " mes Getcontact Premium"])},
  "Back to Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a los planes"])},
  "When you clicked Payment Complete Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez que haya pulsado el botón de pago completado"])},
  "Term of Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones de uso"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
  "you are deemed to have accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se considera que ha aceptado"])},
  "3rd Party Integration Service Providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedores de servicios de integración de terceros"])},
  "Account Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de la cuenta"])},
  "Saved Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta guardada"])},
  "Follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir"])},
  "My eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis eSIMs"])},
  "Install an eSIM on your Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instale una eSIM en su dispositivo"])},
  "This plan can only be purchased through the <b>eSIM io</b> mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This plan can only be purchased through the <b>eSIM io</b> mobile application."])},
  "<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application."])},
  "Scan the code to download the eSIM.io application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the code to download the eSIM.io application."])},
  "Go to the application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the application"])},
  "QR Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código QR"])},
  "Manual Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalación manual"])},
  "Remaining Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos restantes"])},
  "My Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis paquetes"])},
  "Available Top-up Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquetes de recarga disponibles"])},
  "Installation Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasos de la instalación"])},
  "installation.manual-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Vaya a Ajustes > Celular/Móvil > Añadir Planes de Celular/Móvil"])},
  "installation.manual-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Pulse en Introducir manualmente"])},
  "installation.manual-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Introduzca su dirección SM-DP y el código de activación"])},
  "installation.manual-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Pulse en Añadir plan de telefonía móvil"])},
  "installation.manual-installation-step-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Etiquetar la eSIM"])},
  "<b>Important: eSIM</b> can only be installed once.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Importante: eSIM</b> sólo se puede instalar una vez."])},
  "<b>Do NOT DELETE/REMOVE</b> your eSIM from your device after successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si elimina la eSIM después de instalarla, no podrá volver a instalarla."])},
  "QR Code Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalación del código QR"])},
  "Install your eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instale su eSIM"])},
  "Installation Instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucciones de instalación"])},
  "To manually activate the eSIM on your eSIM capable device you need to print or display this QR code on your device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanee el código QR para activar su eSIM"])},
  "Do not remove your eSIM from your device After successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No retire su eSIM de su dispositivo después de la instalación exitosa"])},
  "To manually activate the eSIM on your eSIM capable device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para activar manualmente la eSIM en su dispositivo apto para eSIM"])},
  "Please copy this information for manually installing the eSIM your device. é Make sure your device has a stable internet connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, copie esta información para instalar manualmente la eSIM en su dispositivo. é Asegúrese de que su dispositivo tiene una conexión a Internet estable"])},
  "SM-DP-ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM-DP-ADDRESS"])},
  "ACTIVATION CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CÓDIGO DE ACTIVACIÓN"])},
  "Your plan is ready to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su eSIM está lista para ser utilizada."])},
  "Details have been sent to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los detalles han sido enviados a su dirección de correo electrónico:"])},
  "If you wish, you can manage your eSIM installation from the Getcontact Mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, complete la instalación para empezar a utilizar su eSIM."])},
  "Download Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar Getcontact"])},
  "You don’t have any credit card saved yet.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía no tienes ninguna tarjeta de crédito guardada."])},
  "Add Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir tarjeta"])},
  "Purchase History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de compras"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "Expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caducado"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos"])},
  "Validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validez"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
  "Log in / Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicie sesión / Regístrese"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "An error occurred. Please try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error. Por favor, inténtelo más tarde."])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
  "Update card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar tarjeta"])},
  "Show All Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar todos los paquetes"])},
  "Would you like to Purchase a new eSIM ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quiere comprar una nueva eSIM?"])},
  "Purchase an eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar ahora"])},
  "Back to Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a Paquetes"])},
  "Are you sure you want to delete your card?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar tu tarjeta?"])},
  "Expires on {date}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Caduca el ", _interpolate(_named("date"))])},
  "Copied!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Copiado!"])},
  "eSIM Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalar eSIM"])},
  "my-esim-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay plan de eSIM todavía"])},
  "installation-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes gestionar tus planes comprados aquí."])},
  "add-saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando añada una nueva tarjeta, verá los detalles de su tarjeta guardada aquí."])},
  "saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su tarjeta guardada"])},
  "{day} Day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " DÍA"])},
  "{day} Days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " DÍAS"])},
  "When you clicked Update Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando haga clic en el botón de actualización"])},
  "installation.qr-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes intercambiar tu dispositivo después de la instalación exitosa. Así que no retire la eSIM activa de su dispositivo."])},
  "installation.manual-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes intercambiar tu dispositivo después de la instalación exitosa. Así que no retire la eSIM activa de su dispositivo."])},
  "installation.detail-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes intercambiar tu dispositivo después de la instalación exitosa. Así que no retire la eSIM activa de su dispositivo."])},
  "installation.qr-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes > Celular/Móvil > Añadir Plan de Celular/Móvil."])},
  "installation.qr-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanee el código QR."])},
  "installation.qr-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme los detalles del plan eSIM."])},
  "installation.qr-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetar la eSIM."])},
  "400010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros no válidos"])},
  "403033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de cuenta inválida"])},
  "500015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error de verificación"])},
  "403002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token no encontrado"])},
  "400000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error de parámetros generales"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número contiene un error. Por favor, ponte en contacto con nosotros"])},
  "You have successfully logged in. Redirecting...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha iniciado la sesión con éxito. Redirigiendo..."])},
  "Please fill in your name and surname to continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su nombre y apellidos para continuar"])},
  "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACEPTADO"])},
  "FULFILLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETANDO"])},
  "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETADO"])},
  "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FALLADO"])},
  "400601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre inválido"])},
  "400602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido inválido"])},
  "This data plan can be used in {count} destinations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Este plan de datos se puede utilizar en ", _interpolate(_named("count")), " destinos"])},
  "packages.global-title ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIMs globales"])},
  "packages.local-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIMs locales"])},
  "packages.regional-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIMs regionales"])},
  "packages.global-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIMs globales"])},
  "packages.supported-countries-modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países admitidos"])},
  "packages.supported-countries-modal.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿En qué países puedo utilizar mi plan Global?"])},
  "packages.supported-countries-modal.search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar países"])},
  "installation.local-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de datos local"])},
  "installation.regional-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de datos regional"])},
  "installation.global-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de datos global"])},
  "404.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Página no encontrada"])},
  "404.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido encontrar la página que buscaba."])},
  "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la página de inicio"])},
  "Package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquete"])},
  "packages.supported-countries-modal.local-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Dónde puedo utilizar mi plan local?"])},
  "packages.supported-countries-modal.regional-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿En qué países puedo utilizar mi plan regional?"])},
  "packages.supported-countries-modal.global-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿En qué países puedo utilizar mi plan Global?"])},
  "page-titles": {
    "login-by-verifykit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar por Verifykit"])},
    "country-packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquetes de países"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago del paquete"])},
    "payment-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago exitoso"])},
    "installation-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle de la instalación"])},
    "installation-qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalación QR"])},
    "installation-manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalación manual"])},
    "account-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de la cuenta"])},
    "saved-cards-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas guardadas"])},
    "saved-cards-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir a las tarjetas guardadas"])},
    "saved-cards-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar tarjetas guardadas"])},
    "saved-cards.add-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir tarjeta"])},
    "my-esim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi eSIM"])},
    "404-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada"])}
  },
  "500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error general"])},
  "credit-card.card-number-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de tarjeta no es válido"])},
  "credit-card.card-owner-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El propietario de la tarjeta no es válido"])},
  "credit-card.card-expiration-month-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mes de caducidad de la tarjeta no es válido"])},
  "credit-card.card-expiration-year-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El año de caducidad de la tarjeta no es válido"])},
  "credit-card.card-cvv-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de seguridad de la tarjeta no es válido"])},
  "When you clicked Add Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al hacer clic en el botón Añadir"])},
  "payment-success.order-type-topup-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic en los detalles de su eSIM"])},
  "Go to eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a eSIM"])},
  "saved-cards.add-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tarjeta ha sido añadida con éxito."])},
  "saved-cards.update-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tarjeta ha sido editada con éxito."])},
  "saved-cards.delete-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tarjeta se ha eliminado correctamente."])},
  "general-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo ha ido mal, inténtelo de nuevo."])},
  "search-country.empty-state-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País no encontrado"])},
  "account-information.update-profile-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La información de su perfil se ha actualizado correctamente"])},
  "installation.order-does-not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pedido que busca no existe."])},
  "Local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
  "Regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional"])},
  "Global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
  "login.name-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre y apellido"])},
  "packages.regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional"])},
  "packages.global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
  "packages.local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
  "400014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico no válido"])},
  "Z_400008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error. Por favor, inténtelo más tarde."])},
  "Z_400009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error. Por favor, inténtelo más tarde."])},
  "Z_400010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error. Por favor, inténtelo más tarde."])},
  "Z_400011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error. Por favor, inténtelo más tarde."])},
  "Z_500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error. Por favor, inténtelo más tarde."])},
  "400220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error. Por favor, inténtelo más tarde."])},
  "400221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error. Por favor, inténtelo más tarde."])},
  "400222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error. Por favor, inténtelo más tarde."])},
  "Z_404001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, la transacción ha fallado. Por favor, inténtelo de nuevo más tarde."])},
  "Z_401002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, la transacción ha fallado. Por favor, inténtelo de nuevo más tarde."])},
  "Z_400002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquete inválido. Por favor, intente comprar otro paquete."])},
  "Z_400003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error en el sistema de pago. Por favor, inténtelo de nuevo."])},
  "Z_400004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error en el sistema de pago. Por favor, inténtelo de nuevo."])},
  "Z_400005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción fallida."])},
  "Z_400006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de crédito no válida"])},
  "Z_400007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede iniciar una nueva suscripción porque ya hay una suscripción activa."])},
  "Z_400012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error en el sistema de pago. Por favor, inténtelo de nuevo."])},
  "Z_400013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error en el sistema de pago. Por favor, inténtelo de nuevo."])},
  "Z_400014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error en el sistema de pago. Por favor, inténtelo de nuevo."])},
  "Z_400015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error durante el proceso de reembolso."])},
  "Z_500001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error en el sistema de pago. Por favor, inténtelo de nuevo."])},
  "Z_500002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al crear una suscripción. Inténtelo de nuevo."])},
  "Z_500003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al guardar la tarjeta de suscripción. Por favor, inténtelo de nuevo."])},
  "Z_C0000000 ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error en el pago. Vuelva a intentarlo más tarde."])},
  "Z_400223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido encontrar un paquete adecuado. Por favor, inténtelo de nuevo."])},
  "Z_500220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error durante el pago. Por favor, inténtelo de nuevo."])},
  "Z_500221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al crear el pedido. Por favor, inténtelo de nuevo."])},
  "400224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario y el ID de la sim no coinciden"])},
  "400340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tarjeta de crédito incorrecto"])},
  "400341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titular de la tarjeta de crédito incorrecto"])},
  "400342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes de caducidad de la tarjeta de crédito incorrecto"])},
  "400343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año de caducidad de la tarjeta de crédito incorrecto"])},
  "400344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código CVV de la tarjeta de crédito incorrecto"])},
  "Z_E0000027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos afrontando algunos cambios imprevistos en nuestras operaciones en su región. Sin embargo, estamos trabajando para resolver su solicitud lo antes posible."])},
  "Z_E0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error durante el pago. Por favor, inténtelo de nuevo."])},
  "Z_E0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción se canceló antes de que se pulsara el botón de compra."])},
  "Z_E0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción falló debido a que el límite de la tarjeta es insuficiente."])},
  "Z_E0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La acción falló porque la verificación no se llevó a cabo correctamente."])},
  "Z_E0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción fallida. Póngase en contacto con su banco."])},
  "Z_E0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción ha fallado debido a un error técnico. Vuelva a intentarlo más tarde."])},
  "Z_E0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La acción de pago fue terminada por el usuario."])},
  "Z_E0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su tarjeta está desactivada para compras en línea. Póngase en contacto con su banco."])},
  "Z_E0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción falló debido a las políticas de seguridad."])},
  "Z_E0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción ha fallado porque el número de la tarjeta no es válido."])},
  "Z_E0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción ha fallado porque la fecha de caducidad no es válida."])},
  "Z_E0000011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción falló porque el usuario no dio la confirmación."])},
  "Z_E0000012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción ha fallado porque se ha superado el límite de la transacción."])},
  "Z_E0000013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción ha fallado porque se ha introducido un importe incorrecto."])},
  "Z_E0000014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su suscripción no pudo iniciarse porque la verificación de seguridad 3DS no tuvo éxito."])},
  "Z_E0000015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su banco no permitió la transacción."])},
  "Z_E0000016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su banco no permitió la transacción. Póngase en contacto con su banco."])},
  "Z_E0000017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha introducido un CVV incorrecto. Por favor, compruebe y vuelva a intentar la transacción."])},
  "Z_E0000018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La información de su tarjeta de crédito ha sido rechazada. Compruebe y vuelva a intentar la transacción."])},
  "Z_E0000019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su tarjeta de crédito ha caducado. Pruebe con otra tarjeta."])},
  "Z_E0000020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción ha sido rechazada porque has introducido un código de verificación incorrecto varias veces."])},
  "Z_E0000021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción ha fallado debido a un error técnico. Inténtelo de nuevo."])},
  "Z_E0000022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su tarjeta de crédito no es válida en el proveedor de servicios de pago actual. Por favor, intente con otra tarjeta de crédito."])},
  "Z_E0000023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tarjeta de crédito que desea utilizar para la transacción ha sido rechazada. Por favor, intente con otra tarjeta de crédito."])},
  "Z_E0000024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha introducido un año de caducidad incorrecto para la tarjeta de crédito."])},
  "Z_E0000025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha introducido un mes de caducidad incorrecto para la tarjeta de crédito."])},
  "Z_E0000026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha introducido una fecha de caducidad incorrecta para la tarjeta de crédito."])},
  "Z_F0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido procesar la transacción. Póngase en contacto con el servicio de atención al cliente."])},
  "Z_F0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido procesar la transacción. Póngase en contacto con el servicio de atención al cliente."])},
  "Z_F0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido procesar la transacción. Póngase en contacto con el servicio de atención al cliente."])},
  "Z_F0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido procesar la transacción. Póngase en contacto con el servicio de atención al cliente."])},
  "Z_F0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido procesar la transacción. Póngase en contacto con el servicio de atención al cliente."])},
  "Z_F0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido procesar la transacción. Póngase en contacto con el servicio de atención al cliente."])},
  "Z_F0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido procesar la transacción. Póngase en contacto con el servicio de atención al cliente."])},
  "Z_F0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido procesar la transacción. Póngase en contacto con el servicio de atención al cliente."])},
  "Z_F0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido procesar la transacción. Póngase en contacto con el servicio de atención al cliente."])},
  "Z_F0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido procesar la transacción. Póngase en contacto con el servicio de atención al cliente."])},
  "Z_F0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido procesar la transacción. Póngase en contacto con el servicio de atención al cliente."])},
  "Z_S0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción realizada con éxito."])},
  "Z_R0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para la transacción, necesita redirigirse a redirectUrl."])},
  "order-status": {
    "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolsado"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caducado"])},
    "ORDER_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error de identificación"])},
    "PAYMENT_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago fallido"])},
    "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
    "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminado"])},
    "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparando los datos"])},
    "PENDING_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente de completar"])},
    "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caducado"])}
  },
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afganistán"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argelia"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa Americana"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguila"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antártida"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua y Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaiyán"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baréin"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladés"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bielorrusia"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bélgica"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belice"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benín"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermudas"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bután"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia y Herzegovina"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botsuana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brasil"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territorio Británico del Océano Índico"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Vírgenes Británicas"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunéi"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camboya"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camerún"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canadá"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabo Verde"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Caimán"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Centroafricana"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isla de Navidad"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Cocos"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoras"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Cook"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croacia"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curazao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chipre"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chequia"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Democrática del Congo"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinamarca"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yibuti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Dominicana"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor-Leste"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egipto"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea Ecuatorial"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiopía"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Malvinas"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Feroe"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiyi"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlandia"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francia"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guayana Francesa"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polinesia Francesa"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabón"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alemania"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grecia"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groenlandia"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granada"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadalupe"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bisáu"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haití"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAE de Hong Kong (China)"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungría"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islandia"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irán"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irak"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlanda"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isla de Man"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italia"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côte d’Ivoire"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japón"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordania"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazajistán"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenia"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaos"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirguistán"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letonia"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líbano"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesoto"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libia"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituania"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxemburgo"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAE de Macao (China)"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedonia"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaui"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malasia"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldivas"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Marshall"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinica"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauricio"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["México"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldavia"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mónaco"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marruecos"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Birmania)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países Bajos"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Caledonia"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Zelanda"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isla Norfolk"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corea del Norte"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Marianas del Norte"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noruega"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omán"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistán"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territorios Palestinos"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panamá"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papúa Nueva Guinea"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perú"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipinas"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Pitcairn"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polonia"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Rico"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catar"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reunión"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumanía"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusia"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Bartolomé"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santa Elena"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Cristóbal y Nieves"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santa Lucía"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Martín"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Pedro y Miquelón"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Vicente y las Granadinas"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santo Tomé y Príncipe"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabia Saudí"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leona"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapur"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint Maarten"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eslovaquia"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eslovenia"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Salomón"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudáfrica"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corea del Sur"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudán del Sur"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["España"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudán"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard y Jan Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esuatini"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suecia"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suiza"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siria"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwán"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tayikistán"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailandia"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad y Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Túnez"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turquía"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistán"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Turcas y Caicos"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Vírgenes de EE. UU."])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ucrania"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emiratos Árabes Unidos"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reino Unido"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estados Unidos"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistán"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad del Vaticano"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis y Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sáhara Occidental"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabue"])}
  }
}