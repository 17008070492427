export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium และภาษี"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีเจอร์"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สตอรี่ความสำเร็จ"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกี่ยวกับ"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาชีพ"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความช่วยเหลือ"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำถามที่พบบ่อย"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการโปรไฟล์ส่วนตัวของคุณ"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงานปัญหา"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดต่อเรา"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปกป้องข้อมูล"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidad"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อตกลงการให้บริการของเรา"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นโยบายการซื้อของเรา"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าระบบกับ Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าระบบกับ Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าระบบกับ Apple"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขมีข้อผิดพลาด โปรดติดต่อเรา"])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัฟกานิสถาน"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แอลเบเนีย"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แอลจีเรีย"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อเมริกันซามัว"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อันดอร์รา"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แองโกลา"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แองกวิลลา"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แอนตาร์กติกา"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แอนติกาและบาร์บูดา"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาร์เจนตินา"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาร์เมเนีย"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อารูบา"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออสเตรเลีย"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออสเตรีย"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาเซอร์ไบจาน"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บาฮามาส"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บาห์เรน"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บังกลาเทศ"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บาร์เบโดส"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบลารุส"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบลเยียม"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบลีซ"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบนิน"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์มิวดา"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภูฏาน"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โบลิเวีย"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอสเนียและเฮอร์เซโกวีนา"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอตสวานา"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บราซิล"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริติชอินเดียนโอเชียนเทร์ริทอรี"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะบริติชเวอร์จิน"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บรูไน"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัลแกเรีย"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บูร์กินาฟาโซ"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บุรุนดี"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กัมพูชา"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แคเมอรูน"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แคนาดา"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เคปเวิร์ด"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะเคย์แมน"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาธารณรัฐแอฟริกากลาง"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชาด"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชิลี"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จีน"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกาะคริสต์มาส"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะโคโคส (คีลิง)"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โคลอมเบีย"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คอโมโรส"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะคุก"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คอสตาริกา"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โครเอเชีย"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คิวบา"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คูราเซา"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไซปรัส"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เช็ก"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คองโก - กินชาซา"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดนมาร์ก"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จิบูตี"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดมินิกา"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาธารณรัฐโดมินิกัน"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติมอร์-เลสเต"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอกวาดอร์"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อียิปต์"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอลซัลวาดอร์"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อิเควทอเรียลกินี"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอริเทรีย"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอสโตเนีย"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอธิโอเปีย"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะฟอล์กแลนด์"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะแฟโร"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟิจิ"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟินแลนด์"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝรั่งเศส"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฟรนช์เกียนา"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฟรนช์โปลินีเซีย"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กาบอง"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แกมเบีย"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จอร์เจีย"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เยอรมนี"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กานา"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยิบรอลตาร์"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรีซ"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรีนแลนด์"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกรเนดา"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กวาเดอลูป"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กวม"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กัวเตมาลา"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกิร์นซีย์"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กินี"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กินี-บิสเซา"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กายอานา"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฮติ"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฮอนดูรัส"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขตปกครองพิเศษฮ่องกงแห่งสาธารณรัฐประชาชนจีน"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฮังการี"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไอซ์แลนด์"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อินเดีย"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อินโดนีเซีย"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อิหร่าน"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อิรัก"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไอร์แลนด์"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกาะแมน"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อิสราเอล"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อิตาลี"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โกตดิวัวร์"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จาเมกา"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ญี่ปุ่น"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจอร์ซีย์"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จอร์แดน"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คาซัคสถาน"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เคนยา"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คิริบาส"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โคโซโว"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปาเลา"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คีร์กีซสถาน"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลาว"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลัตเวีย"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลบานอน"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลโซโท"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไลบีเรีย"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิเบีย"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิกเตนสไตน์"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิทัวเนีย"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลักเซมเบิร์ก"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขตปกครองพิเศษมาเก๊าแห่งสาธารณรัฐประชาชนจีน"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มาซิโดเนียเหนือ"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มาดากัสการ์"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มาลาวี"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มาเลเซีย"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มัลดีฟส์"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มาลี"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอลตา"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะมาร์แชลล์"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มาร์ตินีก"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอริเตเนีย"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอริเชียส"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มายอต"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เม็กซิโก"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไมโครนีเซีย"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอลโดวา"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โมนาโก"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มองโกเลีย"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอนเตเนโกร"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอนต์เซอร์รัต"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โมร็อกโก"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โมซัมบิก"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมียนมาร์ (พม่า)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นามิเบีย"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นาอูรู"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนปาล"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนเธอร์แลนด์"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นิวแคลิโดเนีย"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นิวซีแลนด์"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นิการากัว"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไนเจอร์"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไนจีเรีย"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นีอูเอ"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกาะนอร์ฟอล์ก"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกาหลีเหนือ"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะนอร์เทิร์นมาเรียนา"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นอร์เวย์"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โอมาน"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปากีสถาน"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดินแดนปาเลสไตน์"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปานามา"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปาปัวนิวกินี"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปารากวัย"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปรู"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟิลิปปินส์"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะพิตแคร์น"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปแลนด์"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรตุเกส"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปอร์โตริโก"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กาตาร์"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คองโก - บราซซาวิล"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรอูนียง"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โรมาเนีย"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รัสเซีย"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวันดา"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซนต์บาร์เธเลมี"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซนต์เฮเลนา"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซนต์คิตส์และเนวิส"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซนต์ลูเซีย"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซนต์มาร์ติน"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แซงปีแยร์และมีเกอลง"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซนต์วินเซนต์และเกรนาดีนส์"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซามัว"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซานมาริโน"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซาตูเมและปรินซิปี"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซาอุดีอาระเบีย"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซเนกัล"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซอร์เบีย"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซเชลส์"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซียร์ราลีโอน"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิงคโปร์"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซินต์มาร์เทน"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สโลวะเกีย"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สโลวีเนีย"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะโซโลมอน"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โซมาเลีย"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แอฟริกาใต้"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกาหลีใต้"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซูดานใต้"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สเปน"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ศรีลังกา"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซูดาน"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซูรินาเม"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สฟาลบาร์และยานไมเอน"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอสวาตีนี"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สวีเดน"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สวิตเซอร์แลนด์"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซีเรีย"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไต้หวัน"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทาจิกิสถาน"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แทนซาเนีย"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไทย"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โตโก"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โตเกเลา"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตองกา"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรินิแดดและโตเบโก"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตูนิเซีย"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตุรกี"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เติร์กเมนิสถาน"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะเติกส์และหมู่เกาะเคคอส"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตูวาลู"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมู่เกาะเวอร์จินของสหรัฐอเมริกา"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยูกันดา"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยูเครน"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สหรัฐอาหรับเอมิเรตส์"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สหราชอาณาจักร"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สหรัฐอเมริกา"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อุรุกวัย"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อุซเบกิสถาน"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วานูอาตู"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นครวาติกัน"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวเนซุเอลา"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวียดนาม"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วาลลิสและฟุตูนา"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ม.ค."])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เยเมน"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แซมเบีย"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซิมบับเว"])}
  }
}