<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      :class="fillColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0zM6.053 1.15c-.443.8-.968 2.046-1.312 3.806H1.648a8.431 8.431 0 0 1 4.405-3.805zM4.637 5.579h-3.3a8.337 8.337 0 0 0-.72 3.111H4.38c.015-1.17.112-2.2.258-3.11zM.613 9.311h3.77c.013 1.175.116 2.198.261 3.11H1.361a8.307 8.307 0 0 1-.748-3.11zm4.133 3.733H1.657a8.413 8.413 0 0 0 4.414 3.812c-.446-.791-.978-2.035-1.325-3.812zm3.982 4.341a8.325 8.325 0 0 1-1.89-.286c-.469-.767-1.077-2.087-1.462-4.055h3.352v4.341zm-3.462-4.963h3.462V9.31H5.005c.015 1.175.116 2.199.261 3.11zm3.462-3.733H5.001a21.5 21.5 0 0 1 .26-3.11h3.467v3.11zM5.363 4.956h3.365V.615a8.39 8.39 0 0 0-1.91.291c-.468.782-1.073 2.096-1.455 4.05zM17.38 8.69H13.7c-.016-1.17-.113-2.2-.259-3.11h3.21c.43.956.688 2.004.731 3.11zm-4.046-3.733h3.003a8.424 8.424 0 0 0-4.3-3.78c.44.8.957 2.038 1.297 3.78zM9.35.614c.662.029 1.304.134 1.919.306.466.784 1.067 2.093 1.447 4.036H9.35V.614zm3.467 4.964H9.35V8.69h3.726a21.49 21.49 0 0 0-.259-3.11zM9.35 9.311h3.723a21.258 21.258 0 0 1-.262 3.11H9.35v-3.11zm0 3.732v4.33a8.494 8.494 0 0 0 1.898-.288c.467-.77 1.072-2.086 1.455-4.042H9.35zm2.671 3.786c.443-.792.968-2.027 1.31-3.786h3.002a8.379 8.379 0 0 1-4.312 3.786zm1.413-4.407h3.197a8.37 8.37 0 0 0 .744-3.111h-3.679a21.428 21.428 0 0 1-.262 3.11z" />
  </svg>
</template>

<script>
export default {
  name: "LanguageIcon",
  props: {
    fillColor: {
      type: String,
      default: "fill-raisin-black",
    },
  },
}
</script>

<style scoped></style>
