export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium și Tarife"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funcții"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povestiri de Succes"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despre Noi"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carieră"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajutor"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionează-ți Profilul de Confidențialitate"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportează o Problemă"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactează-ne"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacybeleid/GDPR"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termenii și condițiile noastre"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica noastră de Cumpărare"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectare cu Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectare cu Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectare cu Apple"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare la număr. Te rugăm să ne contactezi"])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albanië"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algerije"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerikaans-Samoa"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antarctica"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua en Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentinië"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenië"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australië"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oostenrijk"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbeidzjan"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahama’s"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrein"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["België"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnië en Herzegovina"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazilië"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brits Indische Oceaanterritorium"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britse Maagdeneilanden"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarije"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodja"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kameroen"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaapverdië"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaaimaneilanden"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centraal-Afrikaanse Republiek"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsjaad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chili"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Christmaseiland"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocoseilanden"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoren"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookeilanden"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroatië"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaçao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsjechië"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo-Kinshasa"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denemarken"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominicaanse Republiek"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oost-Timor"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypte"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equatoriaal-Guinea"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estland"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopië"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falklandeilanden"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faeröer"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frankrijk"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans-Guyana"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans-Polynesië"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgië"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duitsland"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Griekenland"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groenland"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinee"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinee-Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haïti"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongkong SAR van China"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongarije"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IJsland"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesië"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irak"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ierland"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isle of Man"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israël"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italië"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ivoorkust"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordanië"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazachstan"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenia"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirgizië"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letland"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libanon"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libië"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litouwen"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxemburg"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau SAR van China"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noord-Macedonië"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagaskar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maleisië"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldiven"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshalleilanden"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinique"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritanië"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldavië"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolië"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marokko"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Birma)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibië"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederland"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw-Caledonië"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw-Zeeland"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norfolk"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noord-Korea"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noordelijke Marianen"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noorwegen"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palestijnse gebieden"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papoea-Nieuw-Guinea"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipijnen"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairneilanden"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polen"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Rico"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo-Brazzaville"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roemenië"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusland"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Barthélemy"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint-Helena"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts en Nevis"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Martin"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Pierre en Miquelon"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent en de Grenadines"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tomé en Principe"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saoedi-Arabië"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servië"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychellen"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint-Maarten"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slowakije"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenië"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salomonseilanden"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalië"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuid-Afrika"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuid-Korea"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuid-Soedan"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanje"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soedan"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spitsbergen en Jan Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSwatini"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweden"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwitserland"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syrië"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tadzjikistan"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad en Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunesië"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkije"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks- en Caicoseilanden"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerikaanse Maagdeneilanden"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oekraïne"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verenigde Arabische Emiraten"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verenigd Koninkrijk"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verenigde Staten"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oezbekistan"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaticaanstad"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis en Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Westelijke Sahara"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jemen"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
  }
}