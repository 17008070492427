export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium və Tariflər"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xüsusiyyətlər"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uğur hekayələri"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haqqında"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karyera"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kömək"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məxfilik profilinizi idarə edin"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemi bildirmək"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizimlə Əlaqə saxla"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məlumatların Qorunması"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиялық саясаты"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xidmət şərtlərimiz"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satınalma siyasətimiz"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google ilə daxil olun"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook ilə daxil olun"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple ilə daxil olun"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nömrədə səhvlik var. Zəhmət olmasa bizimlə əlaqə saxlayın."])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əfqanıstan"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albaniya"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əlcəzair"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerika Samoası"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anqola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angilya"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antarktika"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antiqua və Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ermənistan"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avstraliya"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avstriya"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azərbaycan"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baham adaları"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bəhreyn"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banqladeş"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belçika"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beliz"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermud adaları"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butan"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boliviya"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosniya və Herseqovina"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botsvana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Braziliya"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britaniyanın Hind Okeanı Ərazisi"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britaniyanın Virgin adaları"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruney"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolqarıstan"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamboca"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamerun"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabo-Verde"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayman adaları"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mərkəzi Afrika Respublikası"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çili"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çin"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milad adası"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokos (Kilinq) adaları"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolumbiya"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komor adaları"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuk adaları"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosta Rika"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xorvatiya"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurasao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kipr"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çexiya"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konqo - Kinşasa"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danimarka"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cibuti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominika"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominikan Respublikası"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şərqi Timor"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekvador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misir"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekvatorial Qvineya"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritreya"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoniya"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efiopiya"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folklend adaları"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farer adaları"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fici"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlandiya"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransa"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransa Qvianası"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransa Polineziyası"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qabon"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qambiya"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gürcüstan"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almaniya"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cəbəllütariq"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yunanıstan"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qrenlandiya"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qrenada"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qvadelupa"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qvatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gernsi"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qvineya"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qvineya-Bisau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qayana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honq Konq Xüsusi İnzibati Ərazi Çin"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macarıstan"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İslandiya"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindistan"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndoneziya"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İran"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İraq"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İrlandiya"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men adası"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsrail"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İtaliya"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotd’ivuar"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamayka"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaponiya"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cersi"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İordaniya"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qazaxıstan"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keniya"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qırğızıstan"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latviya"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livan"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesoto"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberiya"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liviya"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lixtenşteyn"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litva"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lüksemburq"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makao Xüsusi İnzibati Ərazi Çin"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makedoniya"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madaqaskar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malavi"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malayziya"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldiv adaları"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marşal adaları"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinik"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mavritaniya"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mavriki"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayot"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meksika"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikroneziya"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monako"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monqolustan"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monteneqro"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monserat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mərakeş"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambik"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanma"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibiya"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niderland"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kaledoniya"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Zelandiya"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikaraqua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeriya"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norfolk adası"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimali Koreya"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimali Marian adaları"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norveç"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fələstin Əraziləri"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua-Yeni Qvineya"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraqvay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filippin"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitkern adaları"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polşa"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuqaliya"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Riko"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qətər"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konqo - Brazzavil"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reyunyon"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumıniya"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusiya"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent-Bartelemi"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müqəddəs Yelena"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent-Kits və Nevis"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent-Lusiya"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent Martin"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müqəddəs Pyer və Mikelon"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent-Vinsent və Qrenadinlər"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San-Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San-Tome və Prinsipi"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Səudiyyə Ərəbistanı"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneqal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbiya"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seyşel adaları"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syerra-Leone"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinqapur"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint-Marten"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakiya"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sloveniya"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solomon adaları"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somali"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cənub Afrika"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cənubi Koreya"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cənubi Sudan"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İspaniya"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şri-Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard və Yan-Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esvatini"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsveç"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsveçrə"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriya"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tayvan"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tacikistan"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzaniya"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailand"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toqo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonqa"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad və Tobaqo"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunis"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkiyə"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkmənistan"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törks və Kaykos adaları"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABŞ Virgin adaları"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uqanda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrayna"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birləşmiş Ərəb Əmirlikləri"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birləşmiş Krallıq"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerika Birləşmiş Ştatları"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruqvay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özbəkistan"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatikan"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venesuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyetnam"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uollis və Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qərbi Saxara"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yəmən"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambiya"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabve"])}
  }
}