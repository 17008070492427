export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium en tarieven"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functies"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succesverhalen"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over ons"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VEELGESTELDE VRAGEN"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer Uw Privacyprofiel"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meld Een Probleem"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacteer Ons"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridisch"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung / GDPR"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze servicevoorwaarden"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons aankoopbeleid"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met Apple"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummerfout. Neem contact met ons op."])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algeria"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa americane"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antartide"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua e Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaigian"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrein"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bielorussia"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgio"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia ed Erzegovina"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brasile"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territorio britannico dell’Oceano Indiano"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Vergini Britanniche"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambogia"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camerun"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capo Verde"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Cayman"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repubblica Centrafricana"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cile"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cina"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isola Christmas"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Cocos (Keeling)"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comore"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Cook"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croazia"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaçao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cipro"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cechia"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo - Kinshasa"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danimarca"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibuti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repubblica Dominicana"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor Est"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egitto"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea Equatoriale"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiopia"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Falkland"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Fær Øer"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figi"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlandia"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francia"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana francese"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polinesia francese"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germania"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibilterra"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grecia"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groenlandia"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadalupa"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAS di Hong Kong"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungheria"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islanda"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlanda"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isola di Man"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israele"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italia"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa d’Avorio"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giamaica"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giappone"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giordania"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakistan"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirghizistan"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettonia"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libano"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libia"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituania"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lussemburgo"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAS di Macao"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedonia del Nord"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldive"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Marshall"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinica"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messico"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldavia"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marocco"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambico"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Birmania)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paesi Bassi"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Caledonia"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Zelanda"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isola Norfolk"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corea del Nord"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Marianne settentrionali"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norvegia"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territori palestinesi"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panamá"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua Nuova Guinea"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perù"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filippine"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Pitcairn"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polonia"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portogallo"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portorico"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo-Brazzaville"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riunione"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russia"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Barthélemy"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sant’Elena"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts e Nevis"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Martin"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Pierre e Miquelon"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent e Grenadine"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Tomé e Príncipe"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabia Saudita"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint Maarten"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovacchia"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Salomone"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudafrica"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corea del Sud"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sud Sudan"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spagna"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard e Jan Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svezia"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svizzera"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siria"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagikistan"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailandia"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad e Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turchia"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Turks e Caicos"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isole Vergini Americane"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ucraina"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emirati Arabi Uniti"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regno Unito"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stati Uniti"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città del Vaticano"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis e Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahara occidentale"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
  }
}