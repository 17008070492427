export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium र ट्यारिफ"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विशेषताहरू"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफलताका कथाहरू"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बारेमा"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्यारियर"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मद्दत गर्नुहोस्"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बारम्बार सोधिने प्रश्नहरू"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आफ्नो गोपनियता प्रोफाइल व्यवस्थापन गर्नुहोस्"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समस्याको बारेमा रिपोर्ट गर्नुहोस्"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हामीलाई सम्पर्क गर्नुहोस्"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेटा सुरक्षा"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นโยบายความเป็นส่วนตัว"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाम्रो सेवाका सर्तहरू"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाम्रो खरिद नीति"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google मार्फत लग इन गर्नुहोस्"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook मार्फत लग इन गर्नुहोस्"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple मार्फत लग इन गर्नुहोस्"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नम्बरमा त्रुटि छ। कृपया हामीलाई सम्पर्क गर्नुहोस्"])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अफगानिस्तान"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अल्बेनिया"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अल्जेरिया"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अमेरिकी समोआ"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्डोर्रा"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अङ्गोला"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आङ्गुइला"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्टारटिका"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एन्टिगुआ र बारबुडा"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अर्जेन्टिना"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आर्मेनिया"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अरुबा"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अष्ट्रेलिया"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अष्ट्रिया"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अजरबैजान"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बहामास"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बहराइन"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बङ्गलादेश"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बार्बाडोस"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बेलारूस"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बेल्जियम"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बेलिज"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बेनिन"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बर्मुडा"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुटान"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बोलिभिया"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बोस्निया एण्ड हर्जगोभिनिया"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बोट्स्वाना"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्राजिल"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बेलायती हिन्द महासागर क्षेत्र"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बेलायती भर्जिन टापुहरू"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्रुनाइ"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बुल्गेरिया"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बुर्किना फासो"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बुरूण्डी"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम्बोडिया"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्यामरून"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्यानाडा"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केप भर्डे"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केयमान टापु"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्य अफ्रिकी गणतन्त्र"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चाड"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चिली"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चीन"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिष्टमस टापु"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोकोस (किलिंग) टापुहरु"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोलोम्बिया"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोमोरोस"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुक टापुहरु"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोष्टारिका"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रोएशिया"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्युबा"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुराकाओ"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइप्रस"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चेकिया"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कङ्गो - किन्शासा"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेनमार्क"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिजिबुटी"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डोमिनिका"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डोमिनिकन गणतन्त्र"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिमोर-लेस्टे"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इक्वेडोर"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इजिप्ट"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एल् साल्भाडोर"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भू-मध्यीय गिनी"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एरित्रिया"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस्टोनिया"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इथियोपिया"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फकल्याण्ड टापुहरु"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फारो टापुहरू"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिजी"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिनल्याण्ड"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ्रान्स"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ्रान्सेली गायना"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ्रान्सेली पोलिनेसिया"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गावोन"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गाम्विया"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जर्जिया"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जर्मनी"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["घाना"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जिब्राल्टार"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्रीस"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्रिनल्याण्ड"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्रेनाडा"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्वाडेलुप"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुवाम"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्वाटेमाला"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुएर्नसे"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गिनी"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गिनी-बिसाउ"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुयाना"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैटी"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हन्डुरास"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हङकङ चिनियाँ विशेष प्रशासनिक क्षेत्र"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हङ्गेरी"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आइस्ल्याण्ड"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भारत"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इन्डोनेशिया"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इरान"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इराक"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आयरल्याण्ड"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आइल अफ म्यान"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इजरायल"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इटाली"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोट दिभोर"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमैका"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जापान"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जर्सी"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोर्डन"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["काजाकस्तान"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केन्या"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किरिबाटी"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोसोभो"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पलाउ"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किर्गिस्तान"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाओस"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाट्भिया"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेबनन"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेसोथो"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाइबेरिया"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिबिया"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिकटेन्सटाइन"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिथुएनिया"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्जेमबर्ग"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(मकाउ चिनियाँ विशेष प्रशासनिक क्षेत्र"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["म्यासेडोनिया"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माडागास्कर"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मालावी"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मलेसिया"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माल्दिभ्स"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माली"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माल्टा"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मार्शल टापुहरु"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मार्टिनिक"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माउरिटानिया"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मौरिसियस"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मायोट्ट"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेक्सिको"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माइक्रोनेसिया"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माल्डोभा"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मोनाको"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मङ्गोलिया"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मोन्टेनिग्रो"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मोन्टसेर्राट"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मोरोक्को"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मोजाम्बिक"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["म्यान्मार (बर्मा)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नामिबिया"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाउरू"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नेपाल"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नेदरल्याण्ड"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यु क्यालेडोनिया"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्युजिल्याण्ड"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकारागुवा"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाइजर"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाइजेरिया"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नियुइ"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नोरफोल्क टापु"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्तर कोरिया"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्तरी मारिआना टापु"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नर्वे"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ओमन"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पाकिस्तान"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्यालेस्टनी भू-भागहरु"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्यानामा"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पपुआ न्यू गाइनिया"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्याराग्वे"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पेरू"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिलिपिन्स"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिटकाइर्न टापुहरु"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पोल्याण्ड"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पोर्चुगल"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुएर्टो रिको"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कतार"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कङ्गो ब्राजाभिल"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रियुनियन"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रोमेनिया"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रूस"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रवाण्डा"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेन्ट बार्थेलेमी"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेन्ट हेलेना"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेन्ट किट्स र नेभिस"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेन्ट लुसिया"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेन्ट मार्टिन"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेन्ट पिर्रे र मिक्केलोन"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेन्ट भिन्सेन्ट र ग्रेनाडिन्स"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामोआ"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सान् मारिनो"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साओ टोमे र प्रिन्सिप"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साउदी अरब"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेनेगल"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्बिया"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेचेलेस"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सिएर्रा लिओन"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सिङ्गापुर"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सिन्ट मार्टेन"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्लोभाकिया"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्लोभेनिया"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सोलोमोन टापुहरु"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सोमालिया"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दक्षिण अफ्रिका"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दक्षिण कोरिया"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दक्षिण सुडान"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पेन"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रीलङ्का"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुडान"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुरिनेम"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभाल्बार्ड र जान मायेन"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वाजिल्याण्ड"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्विडेन"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्विजरल्याण्ड"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सिरिया"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ताइवान"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ताजिकिस्तान"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तान्जानिया"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["थाइल्याण्ड"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोगो"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तोकेलाउ"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोंगा"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रिनिडाड एण्ड टोबागो"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्युनिसिया"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टर्की"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तुर्कमेनिस्तान"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तुर्क र काइकोस टापु"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तुभालु"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संयुक्त राज्य भर्जिन टापुहरु"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["युगाण्डा"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["युक्रेन"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संयुक्त अरब इमिराट्स"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संयुक्त अधिराज्य"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संयुक्त राज्य"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उरूग्वे"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उज्बेकिस्तान"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भानुआतु"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेटिकन सिटी"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेनेजुएला"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भिएतनाम"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वालिस र फुटुना"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पश्चिमी साहारा"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["येमेन"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जाम्बिया"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जिम्बाबवे"])}
  }
}