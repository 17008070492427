export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium болон тариф"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онцлог"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Амжилтын Түүх"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тухай"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ажил мэргэжлийн"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тусламж"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Асуулт"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өөрийн Хаягийн Нууцлалыг Зохицуулах"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Асуудал мэдээлэх"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бидэнтэй холбогдох"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өгөгдлийн хамгаалалт"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidade"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Манай Үйлчилгээний Нөхцөл"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Манай Худалдааны Бодлого"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google-ээр Нэвтрэх"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook-ээр Нэвтрэх"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple-ээр Нэвтрэх"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дугаар алдаатай байна. Бидэнтэй холбогдоно уу."])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Афганистан"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Албани"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алжир"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Америкийн Самоа"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Андорра"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ангол"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ангилья"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антарктид"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антигуа ба Барбуда"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аргентин"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Армени"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аруба"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австрали"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австри"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азербайжан"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Багамын арлууд"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бахрейн"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бангладеш"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барбадос"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беларусь"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бельги"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белизе"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бенин"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бермуда"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутан"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боливи"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Босни-Герцеговин"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботсван"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бразил"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Британийн харьяа Энэтхэгийн далай дахь нутаг дэвсгэр"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Британийн Виржиний арлууд"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бруней"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болгар"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Буркина Фасо"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бурунди"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камбож"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камерун"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канад"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабо-Верде"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кайманы арлууд"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төв Африкийн Бүгд Найрамдах Улс"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чад"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чили"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хятад"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зул сарын арал"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кокос (Кийлинг) арлууд"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колумби"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коморын арлууд"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күүкийн арлууд"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коста-Рика"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хорват"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куба"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кюрасао"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кипр"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чех"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конго-Киншаса"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дани"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джибути"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доминика"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бүгд Найрамдах Доминикан Улс"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тимор-Лесте"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эквадор"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Египет"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эль Сальвадор"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экваторын Гвиней"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эритрей"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эстони"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этиоп"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фолклендийн арлууд"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фарерын арлууд"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фижи"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финлянд"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Франц"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Францын Гвиана"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Францын Полинез"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Габон"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гамби"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гүрж"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Герман"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гана"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибралтар"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грек"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гренланд"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гренада"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гваделуп"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гуам"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гватемал"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гернси"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвиней"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвиней-Бисау"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гайана"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гаити"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гондурас"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БНХАУ-ын Тусгай захиргааны бүс Хонг Конг"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Унгар"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исланд"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Энэтхэг"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индонез"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иран"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ирак"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ирланд"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мэн Арал"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Израиль"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итали"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кот-д’Ивуар"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ямайка"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Япон"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жерси"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йордан"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Казахстан"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кени"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кирибати"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Косово"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Палау"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кыргызстан"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаос"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латви"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ливан"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лесото"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Либери"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ливи"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лихтенштейн"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Литва"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люксембург"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БНХАУ-ын Тусгай захиргааны бүс Макао"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Македон"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мадагаскар"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малави"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малайз"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мальдив"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мали"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мальта"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маршаллын арлууд"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мартиник"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мавритани"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маврикий"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майотта"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мексик"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Микронези"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молдав"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монако"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монгол"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монтенегро"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монтсеррат"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Морокко"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мозамбик"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мьянмар"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намиби"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Науру"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Балба"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нидерланд"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шинэ Каледони"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шинэ Зеланд"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никарагуа"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нигер"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нигери"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниуэ"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Норфолк арал"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хойд Солонгос"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хойд Марианы арлууд"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Норвеги"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оман"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакистан"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Палестины нутаг дэвсгэр"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панам"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папуа Шинэ Гвиней"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парагвай"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перу"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филиппин"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Питкэрн арлууд"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Польш"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Португал"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пуэрто-Рико"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Катар"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конго-Браззавиль"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реюнион"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Румын"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орос"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Руанда"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Бартельми"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент Хелена"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Киттс ба Невис"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент Люсиа"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Мартин"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Пьер ба Микело"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Винсент ба Гренадин"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самоа"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан-Марино"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан-Томе ба Принсипи"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саудын Араб"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сенегал"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серби"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейшелийн арлууд"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьерра-Леоне"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сингапур"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синт Мартен"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словак"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словени"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соломоны арлууд"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сомали"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өмнөд Африк"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өмнөд Солонгос"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өмнөд Судан"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Испани"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шри-Ланка"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судан"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суринам"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свалбард ба Ян Майен"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свазиланд"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швед"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швейцарь"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сири"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тайвань"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тажикистан"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Танзани"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тайланд"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Того"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токелау"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тонга"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тринидад ба Тобаго"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тунис"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Турк"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туркменистан"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Турк ба Кайкосын Арлууд"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тувалу"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АНУ-ын Виржиний арлууд"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уганда"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Украин"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арабын Нэгдсэн Эмирт Улс"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Их Британи"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Америкийн Нэгдсэн Улс"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уругвай"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узбекистан"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вануату"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ватикан хот улс"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Венесуэл"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вьетнам"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уоллис ба Футуна"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баруун Сахар"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йемен"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замби"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зимбабве"])}
  }
}