export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium et tarifs"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histoires de réussite"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer votre profil de confidentialité"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridique"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos conditions d'utilisation"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre politique d'achat"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec Apple"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de numéro. Veuillez nous contacter"])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albanie"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algérie"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa américaines"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorre"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antarctique"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua-et-Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentine"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arménie"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australie"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autriche"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaïdjan"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahreïn"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbade"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biélorussie"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgique"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénin"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermudes"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhoutan"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivie"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnie-Herzégovine"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brésil"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territoire britannique de l’océan Indien"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Vierges britanniques"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunéi Darussalam"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarie"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodge"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroun"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cap-Vert"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Caïmans"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République centrafricaine"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chili"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chine"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Île Christmas"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Cocos"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombie"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comores"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Cook"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatie"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaçao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chypre"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchéquie"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo-Kinshasa"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danemark"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominique"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République dominicaine"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor oriental"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équateur"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Égypte"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée équatoriale"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érythrée"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonie"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éthiopie"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Malouines"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Féroé"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fidji"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlande"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyane française"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polynésie française"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambie"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géorgie"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemagne"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grèce"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groenland"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenade"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernesey"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée-Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haïti"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R.A.S. chinoise de Hong Kong"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongrie"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islande"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inde"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonésie"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irak"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlande"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Île de Man"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israël"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italie"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côte d’Ivoire"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaïque"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japon"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordanie"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaos"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirghizistan"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettonie"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liban"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libéria"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libye"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituanie"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R.A.S. chinoise de Macao"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macédoine"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaisie"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malte"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Marshall"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinique"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritanie"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maurice"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexique"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["États fédérés de Micronésie"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldavie"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolie"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monténégro"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maroc"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Birmanie)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibie"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Népal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays-Bas"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle-Calédonie"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle-Zélande"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigéria"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Île Norfolk"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corée du Nord"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Mariannes du Nord"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norvège"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territoires palestiniens"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papouasie-Nouvelle-Guinée"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pérou"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Pitcairn"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pologne"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porto Rico"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo-Brazzaville"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Réunion"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roumanie"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russie"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Barthélemy"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sainte-Hélène"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Christophe-et-Niévès"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sainte-Lucie"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Martin"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Pierre-et-Miquelon"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Vincent-et-les-Grenadines"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Marin"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tomé-et-Principe"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabie saoudite"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sénégal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbie"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapour"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Martin (partie néerlandaise)"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovaquie"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovénie"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Salomon"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalie"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrique du Sud"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corée du Sud"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soudan du Sud"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagne"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soudan"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard et Jan Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eswatini"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suède"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suisse"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syrie"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taïwan"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tadjikistan"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzanie"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thaïlande"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinité-et-Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisie"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turquie"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkménistan"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Turques-et-Caïques"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Vierges des États-Unis"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émirats arabes unis"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Royaume-Uni"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["États-Unis"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouzbékistan"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État de la Cité du Vatican"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis-et-Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahara occidental"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yémen"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambie"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
  }
}