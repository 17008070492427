export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium და ტარიფები"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ფუნქციები"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["წარმატების ისტორიები"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შესახებ"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კარიერა"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დახმარება"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ხშირად დასმული შეკითხვები"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მართეთ თქვენი ანგარიშის კონფიდენციალურობა"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შეგვატყობინეთ პრობლემის შესახებ"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დაგვიკავშირდით"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მონაცემთა დაცვა"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गोपनीयता"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩვენი მომსახურების პირობები"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩვენი შესყიდვების პოლიტიკა"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შედით ანგარიშზე Google-ით"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შედით ანგარიშზე Facebook-ით"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შედით ანგარიშზე Apple-ით"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ნომერთან დაკავშირებით შეცდომაა. გთხოვთ, დაგვიკავშირდეთ"])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ავღანეთი"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ალბანეთი"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ალჟირი"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ამერიკის სამოა"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ანდორა"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ანგოლა"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ანგილია"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ანტარქტიკა"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ანტიგუა და ბარბუდა"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["არგენტინა"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სომხეთი"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["არუბა"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ავსტრალია"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ავსტრია"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["აზერბაიჯანი"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბაჰამის კუნძულები"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბაჰრეინი"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბანგლადეში"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბარბადოსი"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბელარუსი"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბელგია"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბელიზი"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბენინი"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბერმუდა"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბუტანი"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბოლივია"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბოსნია და ჰერცეგოვინა"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბოტსვანა"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბრაზილია"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბრიტანეთის ტერიტორია ინდოეთის ოკეანეში"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბრიტანეთის ვირჯინის კუნძულები"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბრუნეი"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბულგარეთი"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბურკინა-ფასო"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბურუნდი"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კამბოჯა"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კამერუნი"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კანადა"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კაბო-ვერდე"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კაიმანის კუნძულები"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ცენტრალური აფრიკის რესპუბლიკა"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩადი"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩილე"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩინეთი"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შობის კუნძული"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ქოქოსის (კილინგის) კუნძულები"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კოლუმბია"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კომორის კუნძულები"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კუკის კუნძულები"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კოსტა-რიკა"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ხორვატია"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კუბა"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კიურასაო"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კვიპროსი"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩეხეთი"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კონგო - კინშასა"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დანია"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჯიბუტი"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დომინიკა"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დომინიკელთა რესპუბლიკა"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ტიმორ-ლესტე"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ეკვადორი"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ეგვიპტე"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სალვადორი"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ეკვატორული გვინეა"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ერიტრეა"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ესტონეთი"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ეთიოპია"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ფოლკლენდის კუნძულები"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ფარერის კუნძულები"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ფიჯი"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ფინეთი"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["საფრანგეთი"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["საფრანგეთის გვიანა"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["საფრანგეთის პოლინეზია"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გაბონი"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გამბია"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["საქართველო"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გერმანია"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["განა"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გიბრალტარი"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["საბერძნეთი"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გრენლანდია"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გრენადა"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გვადელუპა"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გუამი"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გვატემალა"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გერნსი"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გვინეა"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გვინეა-ბისაუ"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გაიანა"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჰაიტი"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჰონდურასი"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჰონკონგის სპეციალური ადმინისტრაციული რეგიონი- ჩინეთი"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["უნგრეთი"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ისლანდია"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ინდოეთი"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ინდონეზია"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ირანი"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ერაყი"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ირლანდია"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მენის კუნძული"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ისრაელი"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["იტალია"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კოტ-დივუარი"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["იამაიკა"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["იაპონია"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჯერსი"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["იორდანია"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ყაზახეთი"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კენია"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კირიბატი"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კოსოვო"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პალაუ"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ყირგიზეთი"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ლაოსი"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ლატვია"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ლიბანი"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ლესოთო"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ლიბერია"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ლიბია"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ლიხტენშტაინი"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ლიეტუვა"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ლუქსემბურგი"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მაკაოს სპეციალური ადმინისტრაციული რეგიონი- ჩინეთი"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მაკედონია"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მადაგასკარი"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მალავი"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მალაიზია"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მალდივები"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მალი"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მალტა"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მარშალის კუნძულები"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მარტინიკა"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მავრიტანია"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მავრიკი"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მაიოტა"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მექსიკა"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მიკრონეზია"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მოლდოვა"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მონაკო"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მონღოლეთი"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მონტენეგრო"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მონსერატი"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მაროკო"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მოზამბიკი"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მიანმარი (ბირმა)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ნამიბია"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ნაურუ"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ნეპალი"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ნიდერლანდები"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ახალი კალედონია"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ახალი ზელანდია"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ნიკარაგუა"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ნიგერი"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ნიგერია"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ნიუე"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ნორფოლკის კუნძული"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩრდილოეთ კორეა"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩრდილოეთ მარიანას კუნძულები"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ნორვეგია"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ომანი"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პაკისტანი"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პალესტინის ტერიტორიები"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პანამა"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პაპუა-ახალი გვინეა"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პარაგვაი"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პერუ"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ფილიპინები"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პიტკერნის კუნძულები"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პოლონეთი"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პორტუგალია"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პუერტო-რიკო"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კატარი"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კონგო - ბრაზავილი"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["რეუნიონი"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["რუმინეთი"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["რუსეთი"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["რუანდა"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სენ-ბართელმი"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["წმინდა ელენეს კუნძული"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სენტ-კიტსი და ნევისი"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სენტ-ლუსია"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სენ-მარტენი"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სენ-პიერი და მიკელონი"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სენტ-ვინსენტი და გრენადინები"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სამოა"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სან-მარინო"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სან-ტომე და პრინსიპი"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["საუდის არაბეთი"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სენეგალი"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სერბეთი"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სეიშელის კუნძულები"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სიერა-ლეონე"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სინგაპური"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სინტ-მარტენი"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სლოვაკეთი"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სლოვენია"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სოლომონის კუნძულები"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სომალი"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სამხრეთ აფრიკის რესპუბლიკა"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სამხრეთ კორეა"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სამხრეთ სუდანი"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ესპანეთი"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შრი-ლანკა"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სუდანი"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სურინამი"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შპიცბერგენი და იან-მაიენი"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სვაზილენდი"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შვედეთი"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შვეიცარია"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სირია"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ტაივანი"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ტაჯიკეთი"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ტანზანია"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ტაილანდი"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ტოგო"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ტოკელაუ"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ტონგა"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ტრინიდადი და ტობაგო"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ტუნისი"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["თურქეთი"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["თურქმენეთი"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["თერქს-ქაიქოსის კუნძულები"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ტუვალუ"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["აშშ-ის ვირჯინის კუნძულები"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["უგანდა"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["უკრაინა"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["არაბთა გაერთიანებული საამიროები"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გაერთიანებული სამეფო"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ამერიკის შეერთებული შტატები"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ურუგვაი"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["უზბეკეთი"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ვანუატუ"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ქალაქი ვატიკანი"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ვენესუელა"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ვიეტნამი"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["უოლისი და ფუტუნა"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დასავლეთ საჰარა"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["იემენი"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ზამბია"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ზიმბაბვე"])}
  }
}