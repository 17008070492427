export default {
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتسجيل الدخول"])},
  "Data Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطط البيانات"])},
  "Data without border at yourfingertips {quantity} Travel destinations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["بيانات بدون حدود في متناول يدك ", _interpolate(_named("quantity")), " وجهات السفر"])},
  "Search your destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن وجهتك"])},
  "Countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدول"])},
  "Download Getcontact to manage and top up your eSIM anytime, anywhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنزيل Getcontact لإدارة وشحن eSIM في أي وقت وفي أي مكان"])},
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["والتعريفات Premium"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المميزات"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قصص النجاح"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عنا"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهنة"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساعدة"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليمات"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإدارة ملف تعريف الخصوصية الخاص بك"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبلغ عن مشكلة"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قانوني"])},
  "Go to eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتقل إلى eSIM"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الخدمة لدينا"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الشراء لدينا"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
  "footer.copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقوق النشر © 2016-2023 Getverify LDA. كل الحقوق محفوظة. Getcontact ™ علامة تجارية مسجلة."])},
  "Secure Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخروج الآمن"])},
  "Select plan and continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد الخطة واستمر"])},
  "Login or Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتسجيل الدخول أو الاشتراك"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجّل الدخول عبر Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجّل الدخول عبر Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجّل الدخول عبر Apple"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أو"])},
  "Enter Your Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل عنوان بريدك الالكتروني"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمر"])},
  "Card Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة"])},
  "Name On The Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم على البطاقة"])},
  "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
  "YY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YY"])},
  "CVV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
  "Complete Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكمل الدفع"])},
  "{amount} GB": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " غيغابايت"])},
  "{day} Day Valid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " يوم صالح"])},
  "{day} Days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " أيام"])},
  "{day} Day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " يوم"])},
  "Bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حزمة"])},
  "{month} month Getcontact Premium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("month")), " شهر Getcontact Premium"])},
  "Back to Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع إلى الخطط"])},
  "eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شريحة eSIM"])},
  "When you clicked Payment Complete Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند النقر فوق الزر \"إكمال الدفع\""])},
  "When you clicked Update Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند النقر فوق زر التحديث"])},
  "Term of Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الاستخدام"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة خاصة"])},
  "you are deemed to have accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعتبر أنك قد قبلت"])},
  "3rd Party Integration Service Providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدمو خدمات تكامل الطرف الثالث"])},
  "Account Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الحساب"])},
  "Saved Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البطاقة المحفوظة"])},
  "Update card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث البطاقه"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتسجيل الخروج"])},
  "Follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابع"])},
  "My eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM الخاص بي"])},
  "Install an eSIM on your Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتثبيت eSIM على جهازك"])},
  "This plan can only be purchased through the <b>eSIM io</b> mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This plan can only be purchased through the <b>eSIM io</b> mobile application."])},
  "<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application."])},
  "Scan the code to download the eSIM.io application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the code to download the eSIM.io application."])},
  "Go to the application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the application"])},
  "When you clicked Add Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند النقر فوق \"إضافة زر\""])},
  "QR Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الاستجابة السريعة"])},
  "Manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدوي"])},
  "Manual Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التثبيت اليدوي"])},
  "Remaining Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات المتبقية"])},
  "My Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحزم الخاصة بي"])},
  "Available Top-up Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باقات التعبئة المتوفرة"])},
  "Installation Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطوات التثبيت"])},
  "installation.manual-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.انتقل إلى الإعدادات> الخلوي / الجوال> إضافة خطط خلوية / خطط الهاتف"])},
  "installation.manual-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.اضغط على إدخال يدويًا"])},
  "installation.manual-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. أدخل عنوان SM-DP ورمز التفعيل"])},
  "installation.manual-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.اضغط على إضافة خطة خلوية"])},
  "installation.manual-installation-step-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5- قم بتسمية eSIM"])},
  "<b>Important: eSIM</b> can only be installed once.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b> هام: لا يمكن تثبيت eSIM</b> إلا مرة واحدة."])},
  "<b>Do NOT DELETE/REMOVE</b> your eSIM from your device after successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b> لا تحذف / تزيل </ b> eSIM من جهازك بعد التثبيت الناجح"])},
  "QR Code Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تثبيت رمز الاستجابة السريعة"])},
  "Install your eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثبّت eSIM الخاص بك"])},
  "Installation Instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليمات التحميل"])},
  "To manually activate the eSIM on your eSIM capable device you need to print or display this QR code on your device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتنشيط eSIM يدويًا على جهاز قادر على eSIM، تحتاج إلى طباعة أو عرض رمز QR هذا على جهازك"])},
  "Do not remove your eSIM from your device After successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تقم بإزالة eSIM من جهازك بعد التثبيت الناجح"])},
  "To manually activate the eSIM on your eSIM capable device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتنشيط eSIM يدويًا على جهازك الذي يدعم eSIM"])},
  "Please copy this information for manually installing the eSIM your device. é Make sure your device has a stable internet connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى نسخ هذه المعلومات لتثبيت eSIM يدويًا لجهازك. تأكد من أن جهازك به اتصال إنترنت ثابت"])},
  "SM-DP-ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان SM-DP"])},
  "ACTIVATION CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التفعيل"])},
  "Thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا"])},
  "Your plan is ready to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطتك جاهزة للاستخدام."])},
  "Details have been sent to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال التفاصيل إلى:"])},
  "If you wish, you can manage your eSIM installation from the Getcontact Mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت ترغب في ذلك، يمكنك إدارة تثبيت eSIM الخاص بك من تطبيق Getcontact Mobile."])},
  "Download Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنزيل Getcontact"])},
  "First Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأول"])},
  "Last Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكنية"])},
  "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان بريد الكتروني"])},
  "You don’t have any credit card saved yet.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك أي بطاقة ائتمان محفوظة حتى الآن."])},
  "Add Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإضافة بطاقة"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع"])},
  "Expiry Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ انتهاء الصلاحية:"])},
  "Purchase History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الشراء"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجراءات"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشيط"])},
  "Expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتهية الصلاحية"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد الانتظار"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
  "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات"])},
  "Validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحية"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل"])},
  "Show All Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض كل الحزم"])},
  "Would you like to Purchase a new eSIM ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل ترغب في شراء eSIM جديد؟"])},
  "Purchase an eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شراء eSIM"])},
  "Log in / Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الدخول / اشترك"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
  "Back to Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجوع إلى الباقات"])},
  "Are you sure you want to delete your card?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد أنك تريد حذف بطاقتك؟"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
  "An error occurred. Please try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ. حاول مرة اخرى."])},
  "Expires on {date}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["تنتهي الصلاحية في ", _interpolate(_named("date"))])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بالتحديث"])},
  "Copied!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم النسخ!"])},
  "eSIM Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل eSIM"])},
  "my-esim-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد خطة eSIM حتى الآن"])},
  "installation-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك إدارة خططك المشتراة هنا."])},
  "add-saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند إضافة بطاقة جديدة، سترى تفاصيل بطاقتك المحفوظة هنا."])},
  "saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بطاقتك المحفوظة"])},
  "You have successfully logged in. Redirecting...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد قمت بتسجيل الدخول بنجاح. جارٍ إعادة التوجيه ..."])},
  "Please fill in your name and surname to continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ملء اسمك ولقبك للمتابعة"])},
  "Local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محلي"])},
  "Regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إقليمي"])},
  "Global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عالمي"])},
  "This data plan can be used in {count} destinations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["يمكن استخدام خطة البيانات هذه في ", _interpolate(_named("count")), " وجهة"])},
  "login.name-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل أسمك"])},
  "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة إلى الصفحة الرئيسية"])},
  "404.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة التي كنت تبحث عنها لا يمكن العثور عليها."])},
  "404.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - الصفحة غير موجودة"])},
  "installation.global-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة البيانات العالمية"])},
  "installation.regional-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة البيانات الإقليمية"])},
  "installation.local-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة البيانات المحلية"])},
  "packages.supported-countries-modal.search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث في البلدان"])},
  "packages.supported-countries-modal.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في أي البلدان يمكنني استخدام خطتي العالمية؟"])},
  "packages.supported-countries-modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدول المدعومة"])},
  "packages.global-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIMs العالمية"])},
  "packages.regional-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شرائح eSIM الإقليمية"])},
  "packages.local-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIMs المحلية"])},
  "packages.global-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIMs العالمية"])},
  "packages.regional-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شرائح eSIM الإقليمية"])},
  "homepage.banner.image.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بشراء خطة بيانات e-sim الخاصة بك لرحلتك إلى كأس العالم FIFA قطر."])},
  "homepage.banner.button.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشتري الآن"])},
  "400601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأول غير صالح"])},
  "400602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العائلة غير صالح"])},
  "400000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ عام في البيانات"])},
  "403002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الـ Token غير موجود"])},
  "500015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ اثناء التحقق"])},
  "403033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الحساب غير صالحة"])},
  "400010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات غير صالحة"])},
  "400014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البريد الإلكتروني غير صالح"])},
  "404001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عذرا، فشلت المعاملة. الرجاء معاودة المحاولة في وقت لاحق."])},
  "401002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عذرا، فشلت المعاملة. الرجاء معاودة المحاولة في وقت لاحق."])},
  "400002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حزمة غير صالحة. يرجى محاولة شراء حزمة أخرى."])},
  "400003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ في نظام الدفع. حاول مرة اخرى."])},
  "400004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ في نظام الدفع. حاول مرة اخرى."])},
  "400005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل الاجراء."])},
  "400006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بطاقة الائتمان غير صالحة"])},
  "400007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر بدء اشتراك جديد نظرًا لوجود اشتراك نشط بالفعل."])},
  "400008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ. حاول مرة اخرى."])},
  "400009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ. حاول مرة اخرى."])},
  "400011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ. حاول مرة اخرى."])},
  "400012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ في نظام الدفع. حاول مرة اخرى."])},
  "400013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ في نظام الدفع. حاول مرة اخرى."])},
  "400015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ أثناء عملية استرداد الأموال."])},
  "500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ عام"])},
  "500001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ في نظام الدفع. حاول مرة اخرى."])},
  "500002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ أثناء إنشاء الاشتراك. حاول مرة اخرى."])},
  "500003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ أثناء حفظ بطاقة الاشتراك. حاول مرة اخرى."])},
  "C0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ في الدفع. الرجاء معاودة المحاولة في وقت لاحق."])},
  "Z_400220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ. حاول مرة اخرى."])},
  "Z_400221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ. حاول مرة اخرى."])},
  "Z_400222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ. حاول مرة اخرى."])},
  "Z_400223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر العثور على الحزمة المناسبة. حاول مرة اخرى."])},
  "Z_500220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ أثناء الدفع. حاول مرة اخرى."])},
  "Z_500221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ أثناء إنشاء الطلب. حاول مرة اخرى."])},
  "400224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدم ومعرف sim غير متطابقين"])},
  "400340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم بطاقة الائتمان غير صحيح"])},
  "400341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مالك بطاقة الائتمان غير صحيح"])},
  "400342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهر انتهاء صلاحية بطاقة الائتمان غير صحيح"])},
  "400343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنة انتهاء صلاحية بطاقة الائتمان غير صحيحة"])},
  "400344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز CVV لبطاقة الائتمان غير صحيح"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ في الرقم. الرجاء التواصل معنا"])},
  "Z_E0000027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نواجه بعض التغييرات غير المتوقعة على عملياتنا في منطقتك. ومع ذلك، فإننا نعمل حاليًا على حل طلبك في أسرع وقت ممكن."])},
  "Z_E0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ أثناء الدفع. حاول مرة اخرى."])},
  "Z_E0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد قمت بتسجيل الدخول بنجاح. جارٍ إعادة التوجيه ..تم إلغاء المعاملة قبل النقر فوق زر الشراء."])},
  "Z_E0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشلت المعاملة بسبب عدم كفاية حد البطاقة."])},
  "Z_E0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل الإجراء لأنه لم يتم تنفيذ التحقق بشكل صحيح."])},
  "Z_E0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل الاجراء. يرجى الاتصال بالبنك الذي تتعامل معه."])},
  "Z_E0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشلت المعاملة بسبب خطأ فني. الرجاء معاودة المحاولة في وقت لاحق."])},
  "Z_E0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنهى المستخدم إجراء الدفع."])},
  "Z_E0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إلغاء تنشيط بطاقتك للتسوق عبر الإنترنت. يرجى الاتصال بالبنك الذي تتعامل معه."])},
  "Z_E0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشلت المعاملة بسبب سياسات الأمان."])},
  "Z_E0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشلت المعاملة بسبب رقم البطاقة غير صالح."])},
  "Z_E0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشلت المعاملة بسبب تاريخ انتهاء الصلاحية غير صالح."])},
  "Z_E0000011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشلت المعاملة لأن المستخدم لم يقدم تأكيدًا."])},
  "Z_E0000012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشلت المعاملة بسبب تجاوز حد المعاملة."])},
  "Z_E0000013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشلت المعاملة بسبب إدخال مبلغ غير صحيح."])},
  "Z_E0000014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر بدء اشتراكك لأن التحقق من أمان 3DS لم ينجح."])},
  "Z_E0000015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البنك الذي تتعامل معه لم يسمح بالمعاملة."])},
  "Z_E0000016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البنك الذي تتعامل معه لم يسمح بالمعاملة. يرجى الاتصال بالبنك الذي تتعامل معه."])},
  "Z_E0000017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد أدخلت رمز CVV غير صحيح. يرجى التحقق وإعادة المحاولة في المعاملة."])},
  "Z_E0000018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم رفض معلومات بطاقتك الائتمانية. يرجى التحقق وإعادة المحاولة في المعاملة."])},
  "Z_E0000019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتهت صلاحية بطاقتك الائتمانية. الرجاء محاولة بطاقة أخرى."])},
  "Z_E0000020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم رفض المعاملة لأنك أدخلت رمز تحقق خاطئًا عدة مرات."])},
  "Z_E0000021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشلت المعاملة بسبب خطأ فني. حاول مرة اخرى."])},
  "Z_E0000022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بطاقتك الائتمانية غير صالحة في مزود خدمة الدفع الحالي. يرجى تجربة بطاقة ائتمان أخرى."])},
  "Z_E0000023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم رفض بطاقة الائتمان التي تريد استخدامها للمعاملة. يرجى تجربة بطاقة ائتمان أخرى."])},
  "Z_E0000024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد أدخلت سنة انتهاء غير صحيحة لبطاقة الائتمان."])},
  "Z_E0000025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد أدخلت شهر انتهاء غير صحيح لبطاقة الائتمان."])},
  "Z_E0000026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد أدخلت تاريخ انتهاء غير صحيح لبطاقة الائتمان."])},
  "Z_F0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على معالجة معاملتك. يرجى الاتصال بدعم العملاء."])},
  "Z_F0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على معالجة معاملتك. يرجى الاتصال بدعم العملاء."])},
  "Z_F0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على معالجة معاملتك. يرجى الاتصال بدعم العملاء."])},
  "Z_F0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على معالجة معاملتك. يرجى الاتصال بدعم العملاء."])},
  "Z_F0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على معالجة معاملتك. يرجى الاتصال بدعم العملاء."])},
  "Z_F0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على معالجة معاملتك. يرجى الاتصال بدعم العملاء."])},
  "Z_F0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على معالجة معاملتك. يرجى الاتصال بدعم العملاء."])},
  "Z_F0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على معالجة معاملتك. يرجى الاتصال بدعم العملاء."])},
  "Z_F0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على معالجة معاملتك. يرجى الاتصال بدعم العملاء."])},
  "Z_F0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على معالجة معاملتك. يرجى الاتصال بدعم العملاء."])},
  "Z_F0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على معالجة معاملتك. يرجى الاتصال بدعم العملاء."])},
  "Z_S0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملية ناجحة."])},
  "Z_R0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالنسبة للمعاملة ، تحتاج إلى إعادة التوجيه إلى redirectUrl."])},
  "order-status": {
    "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت الإعادة"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتهي الصلاحية"])},
    "ORDER_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ في تحديد الهوية"])},
    "PAYMENT_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملية الدفع فشلت"])},
    "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحذف"])},
    "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد الانتظار"])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جار تجهيز البيانات"])},
    "PENDING_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار الاكتمال"])},
    "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتهي الصلاحية"])}
  },
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفغانستان"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألبانيا"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجزائر"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساموا الأمريكية"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أندورا"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنغولا"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنغويلا"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنتاركتيكا"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنتيغوا وبربودا"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأرجنتين"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرمينيا"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أروبا"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستراليا"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النمسا"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أذربيجان"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البهاما"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحرين"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنغلاديش"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بربادوس"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيلاروس"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلجيكا"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بليز"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنين"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برمودا"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بوتان"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بوليفيا"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البوسنة والهرسك"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بوتسوانا"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرازيل"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإقليم البريطاني في المحيط الهندي"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر فيرجن البريطانية"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بروناي"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلغاريا"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بوركينا فاسو"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بوروندي"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمبوديا"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاميرون"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كندا"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرأس الأخضر"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر كايمان"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمهورية أفريقيا الوسطى"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تشاد"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تشيلي"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصين"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزيرة كريسماس"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر كوكوس (كيلينغ)"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كولومبيا"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر القمر"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر كوك"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كوستاريكا"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كرواتيا"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كوبا"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كوراساو"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبرص"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التشيك"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكونغو - كينشاسا"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدانمرك"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جيبوتي"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دومينيكا"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمهورية الدومينيكان"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تيمور - ليشتي"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإكوادور"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصر"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلفادور"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غينيا الاستوائية"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إريتريا"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستونيا"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إثيوبيا"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر فوكلاند"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر فارو"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيجي"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فنلندا"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرنسا"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غويانا الفرنسية"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بولينيزيا الفرنسية"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغابون"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غامبيا"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جورجيا"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألمانيا"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غانا"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جبل طارق"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليونان"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غرينلاند"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غرينادا"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غوادلوب"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غوام"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غواتيمالا"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غيرنزي"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غينيا"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غينيا بيساو"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غيانا"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هايتي"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هندوراس"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هونغ كونغ الصينية (منطقة إدارية خاصة)"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنغاريا"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آيسلندا"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهند"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إندونيسيا"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيران"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العراق"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أيرلندا"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزيرة مان"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسرائيل"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيطاليا"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساحل العاج"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جامايكا"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليابان"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جيرسي"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأردن"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كازاخستان"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كينيا"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيريباتي"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كوسوفو"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالاو"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيرغيزستان"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لاوس"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لاتفيا"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لبنان"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليسوتو"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليبيريا"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليبيا"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليختنشتاين"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليتوانيا"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوكسمبورغ"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكاو"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدونيا"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدغشقر"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاوي"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماليزيا"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر المالديف"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مالي"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مالطا"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر مارشال"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر المارتينيك"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موريتانيا"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موريشيوس"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مايوت"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكسيك"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميكرونيزيا"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مولدوفا"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موناكو"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منغوليا"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجبل الأسود"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مونتسرات"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المغرب"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موزمبيق"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميانمار (بورما)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناميبيا"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناورو"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نيبال"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هولندا"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كاليدونيا الجديدة"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نيوزيلندا"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نيكاراغوا"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النيجر"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نيجيريا"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نيوي"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزيرة نورفولك"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كوريا الشمالية"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر ماريانا الشمالية"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النرويج"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عُمان"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باكستان"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأراضي الفلسطينية"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنما"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بابوا غينيا الجديدة"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باراغواي"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيرو"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلبين"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر بيتكيرن"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بولندا"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرتغال"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بورتوريكو"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دولة قطر"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكونغو - برازافيل"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روينيون"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رومانيا"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روسيا"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رواندا"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سان بارتليمي"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سانت هيلينا"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سانت كيتس ونيفيس"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سانت لوسيا"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سان مارتن"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سان بيير ومكويلون"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سانت فنسنت وجزر غرينادين"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساموا"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سان مارينو"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساو تومي وبرينسيبي"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المملكة العربية السعودية"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنغال"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صربيا"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيشل"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيراليون"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنغافورة"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سانت مارتن"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلوفاكيا"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلوفينيا"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر سليمان"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصومال"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنوب أفريقيا"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كوريا الجنوبية"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنوب السودان"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسبانيا"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سريلانكا"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السودان"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سورينام"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سفالبارد وجان ماين"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسواتيني"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السويد"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سويسرا"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سوريا"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تايوان"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طاجيكستان"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنزانيا"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تايلاند"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توغو"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توكيلو"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تونغا"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترينيداد وتوباغو"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تونس"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تركيا"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تركمانستان"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر توركس وكايكوس"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توفالو"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر فيرجن التابعة للولايات المتحدة"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أوغندا"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أوكرانيا"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإمارات العربية المتحدة"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المملكة المتحدة"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الولايات المتحدة"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أورغواي"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أوزبكستان"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فانواتو"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفاتيكان"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فنزويلا"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيتنام"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزر والس وفوتونا"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصحراء الغربية"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليمن"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زامبيا"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زيمبابوي"])}
  },
  "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت الموافقة"])},
  "FULFILLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم الإنجاز"])},
  "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منجز"])},
  "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باء بالفشل"])},
  "page-titles": {
    "app-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact eSIM"])},
    "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتسجيل الدخول"])},
    "login-by-verifykit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتسجيل الدخول عن طريق Verifykit"])},
    "country-packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حزم الدول"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع الحزمة"])},
    "payment-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الدفع بنجاح"])},
    "installation-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل التثبيت"])},
    "installation-qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تركيب QR"])},
    "installation-manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التثبيت اليدوي"])},
    "account-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الحساب"])},
    "saved-cards-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البطاقات المحفوظة"])},
    "saved-cards-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف إلى البطاقات المحفوظة"])},
    "saved-cards-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدّل البطاقات المحفوظة"])},
    "my-esim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM الخاص بي"])},
    "404-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة غير موجودة"])}
  },
  "credit-card.card-number-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة غير صالح"])},
  "credit-card.card-owner-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مالك البطاقة غير صالح"])},
  "credit-card.card-expiration-month-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهر انتهاء صلاحية البطاقة غير صالح"])},
  "credit-card.card-expiration-year-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنة انتهاء صلاحية البطاقة غير صالحة"])},
  "credit-card.card-cvv-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز أمان البطاقة غير صالح"])},
  "saved-cards.add-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإضافة بطاقة"])},
  "saved-cards.add-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت إضافة البطاقة بنجاح."])},
  "saved-cards.update-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل البطاقة بنجاح."])},
  "saved-cards.delete-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف البطاقة بنجاح."])},
  "general-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ ما. أعد المحاولة من فضلك."])},
  "search-country.empty-state-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلد غير موجود"])},
  "account-information.update-profile-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تحديث معلومات ملفك الشخصي بنجاح"])},
  "installation.order-does-not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلب الذي تبحث عنه غير موجود."])},
  "payment-success.order-type-topup-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر للحصول على تفاصيل eSIM الخاصة بك"])},
  "installation.qr-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك تبديل جهازك بعد التثبيت الناجح. لذلك لا تقم بإزالة eSIM النشط من جهازك."])},
  "installation.manual-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك تبديل جهازك بعد التثبيت الناجح. لذلك لا تقم بإزالة eSIM النشط من جهازك."])},
  "installation.detail-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك تبديل جهازك بعد التثبيت الناجح. لذلك لا تقم بإزالة eSIM النشط من جهازك."])},
  "installation.qr-installation-step-1:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعدادات> الخلوي / الجوال> إضافة خطة خلوية / جوال."])},
  "installation.qr-installation-step-2:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فم بمسح رمز الاستجابة السريعة."])},
  "installation.qr-installation-step-3:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتأكيد تفاصيل خطة eSIM."])},
  "installation.qr-installation-step-4:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتسمية eSIM."])}
}