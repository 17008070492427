export default {
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "Data Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Plans"])},
  "Data without border at yourfingertips {quantity} Travel destinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The easiest way to stay connected to the internet during your travels"])},
  "Search your destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Data Packs"])},
  "Countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
  "Download Getcontact to manage and top up your eSIM anytime, anywhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Getcontact to manage and top up your eSIM anytime, anywhere"])},
  "Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact"])},
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium and Tariffs "])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Stories"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Your Privacy Profile"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a problem"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy / GDPR"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Terms of Service"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Purchase Policy"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "footer.copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © 2016-2023 Getverify LDA. All rights reserved. Getcontact™ is a registered trademark"])},
  "Secure Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure Checkout"])},
  "Select plan and continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select plan and continue"])},
  "Login or Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login or Register"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Apple"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "Enter Your Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Email Address"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "login.when-clicked-continue-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By clicking 'Continue', you agree to our ", _interpolate(_named("termOfService")), " and ", _interpolate(_named("privacyPolicy"))])},
  "Card Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number"])},
  "Name On The Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name On The Card"])},
  "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
  "YY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YY"])},
  "CVV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
  "Complete Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Payment"])},
  "{amount} GB": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " GB"])},
  "{day} Day Valid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " Days Valid"])},
  "{day} Days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " Days"])},
  "{day} Day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " Day"])},
  "Bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundle"])},
  "{month} month Getcontact Premium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("month")), " month Getcontact Premium"])},
  "Back to Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Plans"])},
  "eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM"])},
  "When you clicked Payment Complete Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you clicked Payment Complete Button"])},
  "When you clicked Update Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you clicked Update Button"])},
  "When you clicked Add Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you clicked Add Button"])},
  "Term of Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term of Use"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "you are deemed to have accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you are deemed to have accepted"])},
  "3rd Party Integration Service Providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd Party Integration Service Providers"])},
  "Account Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Information"])},
  "Saved Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Card"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "Follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
  "My eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My eSIMs"])},
  "Install an eSIM on your Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install an eSIM on your Device"])},
  "This plan can only be purchased through the <b>eSIM.io</b> mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This plan can only be purchased through the <b>eSIM io</b> mobile application."])},
  "<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application."])},
  "Scan the code to download the eSIM.io application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the code to download the eSIM.io application."])},
  "Go to the application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the application"])},
  "QR Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code"])},
  "Manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
  "Manual Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual Installation"])},
  "Remaining Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Data"])},
  "My Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Packages"])},
  "Available Top-up Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Top-up Packages"])},
  "Installation Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation Steps"])},
  "<b>Important: eSIM</b> can only be installed once.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Important: eSIM</b> can only be installed once."])},
  "<b>Do NOT DELETE/REMOVE</b> your eSIM from your device after successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can’t swap your device after successful installation. So don’t remove active eSIM from your device."])},
  "QR Code Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code Installation"])},
  "Install your eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install your eSIM"])},
  "Installation Instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation Instructions"])},
  "To manually activate the eSIM on your eSIM capable device you need to print or display this QR code on your device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code to activate your eSIM"])},
  "Do not remove your eSIM from your device After successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not remove your eSIM from your device After successful installation"])},
  "To manually activate the eSIM on your eSIM capable device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To manually activate the eSIM on your eSIM capable device"])},
  "Please copy this information for manually installing the eSIM your device. é Make sure your device has a stable internet connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please copy this information for manually installing the eSIM your device. Make sure your device has a stable internet connection"])},
  "SM-DP-ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM-DP-ADDRESS"])},
  "ACTIVATION CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTIVATION CODE"])},
  "Thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you"])},
  "Your plan is ready to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your eSIM is ready to use."])},
  "Details have been sent to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details have been sent to your e-mail address:"])},
  "If you wish, you can manage your eSIM installation from the Getcontact Mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete installation to start using your eSIM."])},
  "Download Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Getcontact"])},
  "First Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "Last Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "You don’t have any credit card saved yet.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don’t have any credit card saved yet."])},
  "Add Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Card"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "Expiry Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires Date:"])},
  "Purchase History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase History"])},
  "Package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "Expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "Validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "Show All Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show All Packages"])},
  "Would you like to Purchase a new eSIM ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to Purchase a new eSIM ?"])},
  "Purchase an eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase now"])},
  "Log in / Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in / Sign up"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "Back to Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Packages"])},
  "Are you sure you want to delete your card?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your card?"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "An error occurred. Please try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "Expires on {date}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Expires on ", _interpolate(_named("date"))])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "Copied!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied!"])},
  "eSIM Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install eSIM"])},
  "Go to eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to eSIM"])},
  "my-esim-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No eSIM plan yet"])},
  "installation-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can manage your purchased plans here."])},
  "add-saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you add a new card, you’ll see your saved card details here."])},
  "saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your saved card"])},
  "You have successfully logged in. Redirecting...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully logged in. Redirecting..."])},
  "Please fill in your name and surname to continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in your name and surname to continue"])},
  "This data plan can be used in {count} destinations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This data plan can be used in ", _interpolate(_named("count")), " destinations"])},
  "packages.regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional"])},
  "packages.global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
  "packages.local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
  "packages.regional-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional eSIMs"])},
  "packages.global-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global eSIMs"])},
  "packages.local-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local eSIMs"])},
  "packages.regional-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional eSIMs"])},
  "packages.global-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global eSIMs"])},
  "packages.supported-countries-modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported Countries"])},
  "packages.supported-countries-modal.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In which countries can I use my Global plan?"])},
  "packages.supported-countries-modal.local-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where can I use my Local plan?"])},
  "packages.supported-countries-modal.regional-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In which countries can I use my Regional plan?"])},
  "packages.supported-countries-modal.global-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In which countries can I use my Global plan?"])},
  "packages.supported-countries-modal.search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Countries"])},
  "installation.local-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local Data Plan"])},
  "installation.regional-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional Data Plan"])},
  "installation.global-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Data Plan"])},
  "installation.qr-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't swap your device after successful installation. So don't remove active eSIM from your device."])},
  "installation.manual-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't swap your device after successful installation. So don't remove active eSIM from your device."])},
  "installation.detail-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't swap your device after successful installation. So don't remove active eSIM from your device."])},
  "installation.qr-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.Settings > Cellular/Mobile > Add Cellular/Mobile Plan."])},
  "installation.qr-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.Scan QR code."])},
  "installation.qr-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.Confirm eSIM plan details."])},
  "installation.qr-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.Label the eSIM."])},
  "installation.manual-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.Go to Settings > Cellular/Mobile > Add Cellular/ Mobile Plans"])},
  "installation.manual-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.Tap on Enter Manually"])},
  "installation.manual-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.Enter Your SM-DP-Address and Activation Code"])},
  "installation.manual-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.Tap on Add Cellular Plan"])},
  "installation.manual-installation-step-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5.Label the eSIM"])},
  "cookiepolicy.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use legal cookies to improve your experience."])},
  "cookiepolicy.description2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cookies we use and ", _interpolate(_named("policy")), "."])},
  "cookiepolicy.cookiepolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Policy"])},
  "cookiepolicy.policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Policy"])},
  "404.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Page Not Found"])},
  "404.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you were looking for could not be found."])},
  "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Home"])},
  "login.name-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name and Surname"])},
  "homepage.banner.image.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase your eSIM data plan for your FIFA Qatar World Cup trip."])},
  "homepage.banner.button.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Now"])},
  "403033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid account info"])},
  "500015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification error"])},
  "403002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token not found"])},
  "400000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General parameter error"])},
  "400601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name is required"])},
  "400602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name is required"])},
  "500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again"])},
  "400010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid parameters"])},
  "400014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email address"])},
  "400008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "400009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "400011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "400220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "400221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "400222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "E0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured during payment. Please try again."])},
  "400005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed."])},
  "500221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while creating the order. Please try again."])},
  "404001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, transaction failed. Please try again later"])},
  "401002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, transaction failed. Please try again later"])},
  "400002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid package. Please try purchasing another package."])},
  "400003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment system. Please try again."])},
  "400004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment system. Please try again."])},
  "400006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credit card"])},
  "400007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to start a new subscription as there is already an active subscription"])},
  "400012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment system. Please try again."])},
  "400013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment system. Please try again."])},
  "400015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured during refund process."])},
  "500001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment system. Please try again."])},
  "500002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while creating a subscription. Please try again."])},
  "500003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while saving the subscription card. Please try again."])},
  "C0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment. Please try again later."])},
  "400223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couldn't find a suitable package. Please try again."])},
  "500220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured during payment. Please try again."])},
  "Z_E0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction was cancelled before the Purchase Button was clicked."])},
  "Z_E0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed due to insufficient card limit."])},
  "Z_E0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action failed because verification wasn't carried out properly."])},
  "Z_E0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed. Please contact your bank."])},
  "Z_E0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed due to a technical error. Please try again later."])},
  "Z_E0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment action was ended by the user."])},
  "Z_E0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card is deactivated for online shopping. Please contact your bank."])},
  "Z_E0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed due to security policies."])},
  "Z_E0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed because of invalid card number."])},
  "Z_E0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed because of invalid expiry date."])},
  "Z_E0000011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed because the user didn't give confirmation."])},
  "Z_E0000012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed because transaction limit was exceeded."])},
  "Z_E0000013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed because incorrect amount was entered."])},
  "Z_E0000014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription couldn't be started because 3DS security verification was unsuccessful."])},
  "Z_E0000015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your bank didn't allow the transaction."])},
  "Z_E0000016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your bank didn't allow the transaction. Please contact your bank."])},
  "Z_E0000017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have entered an incorrect CVV. Please check and retry the transaction."])},
  "Z_E0000018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your credit card information has been declined. Please check and retry the transaction."])},
  "Z_E0000019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your credit card has expired. Please try another card."])},
  "Z_E0000020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction declined because you entered a wrong verification code multiple times."])},
  "Z_E0000021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed due to a technical error. Please try again."])},
  "Z_E0000022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your credit card is invalid in the current payment service provider. Please try another credit card."])},
  "Z_E0000023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card you want to use for the transaction was declined. Please try another credit card."])},
  "Z_E0000024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have enter an incorrect expiry year for the credit card."])},
  "Z_E0000025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have enter an incorrect expiry month for the credit card."])},
  "Z_E0000026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have enter an incorrect expiry date for the credit card."])},
  "Z_F0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your transaction. Please contact the customer support."])},
  "Z_F0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your transaction. Please contact the customer support."])},
  "Z_F0000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your transaction. Please contact the customer support."])},
  "Z_F0000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your transaction. Please contact the customer support."])},
  "Z_F0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your transaction. Please contact the customer support."])},
  "Z_F0000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your transaction. Please contact the customer support."])},
  "Z_F0000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your transaction. Please contact the customer support."])},
  "Z_F0000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your transaction. Please contact the customer support."])},
  "Z_F0000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your transaction. Please contact the customer support."])},
  "Z_F0000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your transaction. Please contact the customer support."])},
  "Z_F0000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your transaction. Please contact the customer support."])},
  "Z_S0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction successful."])},
  "Z_R0000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the transaction, you need to redirect to redirectUrl."])},
  "Z_404001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, transaction failed. Please try again later"])},
  "Z_401002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, transaction failed. Please try again later"])},
  "Z_400002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid package. Please try purchasing another package."])},
  "Z_400003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment system. Please try again."])},
  "Z_400004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment system. Please try again."])},
  "Z_400005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed."])},
  "Z_400006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credit card"])},
  "Z_400007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to start a new subscription as there is already an active subscription"])},
  "Z_400008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "Z_400009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "Z_400010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "Z_400011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "Z_400012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment system. Please try again."])},
  "Z_400013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment system. Please try again."])},
  "Z_400014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment system. Please try again"])},
  "Z_400015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured during refund process."])},
  "Z_500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "Z_500001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment system. Please try again."])},
  "Z_500002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while creating a subscription. Please try again."])},
  "Z_500003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while saving the subscription card. Please try again."])},
  "Z_C0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured in the payment. Please try again later."])},
  "Z_400220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "Z_400221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "Z_400222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please try again."])},
  "Z_400223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couldn't find a suitable package. Please try again."])},
  "Z_500220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured during payment. Please try again."])},
  "Z_500221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while creating the order. Please try again."])},
  "400224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User and sim ID do not match"])},
  "400340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect credit card number"])},
  "400341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect credit card owner"])},
  "400342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect credit card expiration month"])},
  "400343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect credit card expiration year"])},
  "400344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect credit card CVV code"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number error. Please contact us"])},
  "Z_E0000000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured during payment. Please try again."])},
  "Z_E0000027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are facing some unforeseen changes to our operations in your region. However we are currently working to resolve your request as soon as possible."])},
  "order-status": {
    "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunded"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
    "ORDER_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification error"])},
    "PAYMENT_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment failed"])},
    "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
    "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing data"])},
    "PENDING_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Complete"])},
    "DEPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Reached"])},
    "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])}
  },
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algeria"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["American Samoa"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antarctica"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua and Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia and Herzegovina"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazil"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Indian Ocean Territory"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Virgin Islands"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodia"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroon"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cape Verde"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cayman Islands"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central African Republic"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Christmas Island"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocos Islands"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoros"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cook Islands"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curacao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech Republic"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Democratic Republic of the Congo"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominican Republic"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["East Timor"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypt"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equatorial Guinea"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopia"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falkland Islands"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faroe Islands"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Guiana"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Polynesia"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenland"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iceland"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isle of Man"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ivory Coast"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyzstan"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebanon"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libya"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedonia"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshall Islands"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinique"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morocco"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
    "AN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands Antilles"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Caledonia"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norfolk Island"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Korea"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Northern Mariana Islands"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norway"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palestine"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua New Guinea"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairn"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Rico"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic of the Congo"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reunion"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russia"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Barthelemy"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Helena"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts and Nevis"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Martin"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Pierre and Miquelon"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent and the Grenadines"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tome and Principe"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudi Arabia"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint Maarten"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solomon Islands"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Africa"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Korea"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Sudan"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard and Jan Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syria"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajikistan"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad and Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkey"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks and Caicos Islands"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U.S. Virgin Islands"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Arab Emirates"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatican"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis and Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Western Sahara"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
  },
  "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCEPTED"])},
  "FULFILLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FULFILLING"])},
  "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETED"])},
  "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAILED"])},
  "page-titles": {
    "app-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact eSIM"])},
    "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "login-by-verifykit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login By Verifykit"])},
    "country-packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Packages"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Payment"])},
    "payment-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Success"])},
    "installation-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation Detail"])},
    "installation-qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation QR"])},
    "installation-manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation Manual"])},
    "account-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Information"])},
    "saved-cards-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Cards"])},
    "saved-cards-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Cards Add"])},
    "saved-cards-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Cards Edit"])},
    "my-esim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My eSIM"])},
    "404-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])}
  },
  "credit-card.card-number-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number is invalid"])},
  "credit-card.card-owner-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card owner is invalid"])},
  "credit-card.card-expiration-month-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card expiration month is invalid"])},
  "credit-card.card-expiration-year-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card expiration year is invalid"])},
  "credit-card.card-cvv-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card security code is invalid"])},
  "saved-cards.update-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Card"])},
  "saved-cards.add-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Card"])},
  "saved-cards.add-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Has Added Successfully."])},
  "saved-cards.update-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Has Edited Successfully."])},
  "saved-cards.delete-card-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Has Deleted Successfully."])},
  "payment-success.order-type-topup-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click for your eSIM details"])},
  "search-country.empty-state-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no data"])},
  "account-information.update-profile-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Profile Information Updated Successfully"])},
  "general-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong please try again."])},
  "installation.order-does-not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order you are looking for does not exist."])},
  "Update card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update card"])}
}