export default {
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
  "Data Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Data"])},
  "Data without border at yourfingertips {quantity} Travel destinations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data tanpa batas di ujung jari Anda ", _interpolate(_named("quantity")), " Destinasi perjalanan"])},
  "Search your destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari Paket Data"])},
  "Countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
  "Download Getcontact to manage and top up your eSIM anytime, anywhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh Getcontact untuk mengelola dan mengisi ulang eSIM anda kapanpun, dimanapun."])},
  "Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact"])},
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium dan Tarif"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitur"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerita Sukses"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang Kami"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karir"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bantuan"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pusat Layanan"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola Profil Privasi Anda"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporkan Masalah"])},
  "Go to eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka eSIM"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi Kami"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perlindungan Data"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebijakan Privasi"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketentuan Layanan Kami"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebijakan Pembelian Kami"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "footer.copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hak Cipta © 2016-2023 Getverify LDA. Hak cipta dilindungi. Getcontact™ adalah merek dagang terdaftar."])},
  "Secure Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Aman"])},
  "Select plan and continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih paket dan lanjutkan"])},
  "Login or Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk atau Daftar"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk dengan Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk dengan Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk dengan Apple"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atau"])},
  "Enter Your Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alamat Email Anda"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan"])},
  "Card Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Kartu"])},
  "Name On The Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Pada Kartu"])},
  "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BB"])},
  "YY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TT"])},
  "CVV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
  "Complete Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selesaikan Pembayaran"])},
  "{amount} GB": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " GB"])},
  "{day} Day Valid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " Hari Berlaku"])},
  "{day} Days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " Hari"])},
  "Bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundle"])},
  "{month} month Getcontact Premium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("month")), " bulan Getcontact Premium"])},
  "Back to Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali ke Paket"])},
  "eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM"])},
  "When you clicked Payment Complete Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketika Anda mengklik Tombol Pembayaran Selesai"])},
  "Term of Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketentuan Penggunaan"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebijakan Privasi"])},
  "you are deemed to have accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anda dianggap telah menerima"])},
  "3rd Party Integration Service Providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penyedia Layanan Integrasi Pihak Ketiga"])},
  "Account Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Akun"])},
  "Saved Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu Tersimpan"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])},
  "Follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikuti"])},
  "My eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM Saya"])},
  "Install an eSIM on your Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasang eSIM diperangkat anda"])},
  "This plan can only be purchased through the <b>eSIM io</b> mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This plan can only be purchased through the <b>eSIM io</b> mobile application."])},
  "<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>eSIM.getcontact.com</b> service is available in the <b>eSIM.io</b> application."])},
  "Scan the code to download the eSIM.io application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the code to download the eSIM.io application."])},
  "Go to the application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the application"])},
  "QR Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode QR"])},
  "Manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
  "Manual Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasang Manual"])},
  "Remaining Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Tersisa"])},
  "My Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Saya"])},
  "Available Top-up Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tersedia Paket Top-up"])},
  "Installation Steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langkah Pemasangan"])},
  "installation.manual-installation-step-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Buka Pengaturan > Seluler/Telepon > Tambah Paket Seluler/Telepon"])},
  "installation.manual-installation-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Pilih Enter Secara Manual"])},
  "installation.manual-installation-step-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Masukkan SM-DP-Address dan Kode Aktivasi Anda"])},
  "installation.manual-installation-step-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Pilih Tambah Paket Seluler"])},
  "installation.manual-installation-step-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Label eSIM"])},
  "<b>Important: eSIM</b> can only be installed once.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Penting: eSIM</b> hanya dapat diinstal satu kali."])},
  "<b>Do NOT DELETE/REMOVE</b> your eSIM from your device after successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>JANGAN HAPUS/HAPUS</b> eSIM Anda dari perangkat Anda setelah instalasi berhasil"])},
  "QR Code Installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemasangan Kode QR"])},
  "Install your eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasang eSIM Anda"])},
  "Installation Instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruksi Pemasangan"])},
  "To manually activate the eSIM on your eSIM capable device you need to print or display this QR code on your device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk mengaktifkan eSIM secara manual di perangkat yang mendukung eSIM, Anda perlu mencetak atau menampilkan kode QR ini di perangkat Anda"])},
  "Do not remove your eSIM from your device After successful installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Jangan lepas eSIM Anda dari perangkat sebelum "])},
  "To manually activate the eSIM on your eSIM capable device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pemasangan berhasil\""])},
  "Please copy this information for manually installing the eSIM your device. é Make sure your device has a stable internet connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk mengaktifkan eSIM secara manual di perangkat eSIM anda yang mendukung"])},
  "SM-DP-ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin informasi ini untuk menginstal eSIM pada perangkat Anda secara manual. Pastikan perangkat Anda memiliki koneksi internet yang stabil"])},
  "ACTIVATION CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM-DP-ALAMAT"])},
  "Thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KODE AKTIFASI"])},
  "Your plan is ready to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terima Kasih"])},
  "Details have been sent to:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket anda siap digunakan."])},
  "If you wish, you can manage your eSIM installation from the Getcontact Mobile application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail telah dikirim ke:"])},
  "Download Getcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika mau, anda dapat mengelola penginstalan eSIM dari aplikasi Getcontact Mobile."])},
  "First Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh Getcontact"])},
  "Last Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Depan"])},
  "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Belakang"])},
  "You don’t have any credit card saved yet.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Email"])},
  "Add Card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda tidak memiliki kartu kredit yang tersimpan"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Kartu"])},
  "Expiry Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga"])},
  "Purchase History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlaku Hingga:"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Pembelian"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "Expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kedaluwarsa"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tertunda"])},
  "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
  "Validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keabsahan"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlaku Hingga:"])},
  "Show All Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
  "Would you like to Purchase a new eSIM ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan Semua Paket"])},
  "Purchase an eSIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah anda ingin membeli eSIM baru?"])},
  "Log in / Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beli eSIM baru"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk / Daftar"])},
  "Back to Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
  "Are you sure you want to delete your card?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali ke Paket"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah anda yakin ingin menghapus kartu anda?"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya"])},
  "An error occurred. Please try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak"])},
  "Expires on {date}": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjadi kesalahan. Silahkan coba lagi."])},
  "Update": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Berlaku hingga ", _interpolate(_named("date"))])},
  "Copied!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbaharui"])},
  "eSIM Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disalin!"])},
  "my-esim-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail eSIM"])},
  "installation-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada paket eSIM"])},
  "When you clicked Add Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketika anda klik Tambah"])},
  "add-saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat mengelola paket yang Anda beli di sini."])},
  "saved-card-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat menambahkan kartu baru, Anda akan melihat detail kartu tersimpan di sini."])},
  "You have successfully logged in. Redirecting...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah berhasil masuk. Mengarahkan..."])},
  "Please fill in your name and surname to continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silakan isi nama lengkap Anda untuk melanjutkan"])},
  "Local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokal"])},
  "Regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daerah"])},
  "Global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
  "This data plan can be used in {count} destinations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Paket data ini dapat digunakan di ", _interpolate(_named("count")), " tujuan"])},
  "login.name-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama Anda"])},
  "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali ke Beranda"])},
  "404.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halaman yang anda cari tidak dapat ditemukan."])},
  "404.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 halaman tidak ditemukan"])},
  "installation.global-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Data Global"])},
  "installation.regional-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Data Regional"])},
  "installation.local-data-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Data Lokal"])},
  "packages.supported-countries-modal.search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari Negara"])},
  "packages.supported-countries-modal.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di negara mana saya dapat menggunakan paket Global?"])},
  "packages.supported-countries-modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara yang Didukung"])},
  "packages.global-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM Global"])},
  "packages.regional-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM Regional"])},
  "packages.local-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM Lokal"])},
  "packages.global-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM Global"])},
  "packages.regional-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM Regional"])},
  "homepage.banner.image.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beli paket data eSIM Anda untuk perjalanan Piala Dunia FIFA Qatar."])},
  "homepage.banner.button.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beli Sekarang"])},
  "400601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Depan Tidak Valid"])},
  "400602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Belakang Tidak Valid"])},
  "400000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesalahan parameter umum"])},
  "500000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesalahan umum"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesalahan pada nomor. Harap hubungi kami"])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afganistan"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aljazair"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa Amerika"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antartika"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua dan Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahama"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgia"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia dan Herzegovina"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brasil"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilayah Inggris di Samudra Hindia"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Virgin Inggris"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamboja"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamerun"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanjung Verde"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Cayman"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republik Afrika Tengah"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cile"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiongkok"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulau Christmas"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Cocos (Keeling)"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolombia"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komoro"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Cook"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosta Rika"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroasia"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaçao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siprus"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceko"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kongo - Kinshasa"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jibuti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominika"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republik Dominika"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor Leste"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekuador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesir"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea Ekuatorial"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiopia"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Malvinas"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Faroe"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlandia"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prancis"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana Prancis"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polinesia Prancis"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jerman"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yunani"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grinlandia"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong SAR Tiongkok"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungaria"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islandia"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irak"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlandia"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulau Man"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italia"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pantai Gading"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaika"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jepang"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yordania"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakstan"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirgistan"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebanon"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libia"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituania"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luksemburg"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makau SAR Tiongkok"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makedonia Utara"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagaskar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maladewa"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Marshall"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinik"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meksiko"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikronesia"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monako"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maroko"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambik"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Burma)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belanda"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaledonia Baru"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selandia Baru"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikaragua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Norfolk"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korea Utara"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Mariana Utara"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegia"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilayah Palestina"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua Nugini"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipina"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Pitcairn"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polandia"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Riko"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kongo - Brazzaville"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumania"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusia"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Barthélemy"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Helena"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts dan Nevis"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Martin"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Pierre dan Miquelon"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent dan Grenadines"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tome dan Principe"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arab Saudi"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapura"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint Maarten"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Solomon"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrika Selatan"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korea Selatan"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan Selatan"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanyol"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Svalbard dan Jan Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSwatini"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedia"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swiss"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriah"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajikistan"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad dan Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turki"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkimenistan"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Turks dan Caicos"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Virgin A.S."])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraina"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uni Emirat Arab"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inggris Raya"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerika Serikat"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatikan"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Wallis dan Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahara Barat"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaman"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
  },
  "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DITERIMA"])},
  "FULFILLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEMENUHI"])},
  "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LENGKAP"])},
  "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GAGAL"])},
  "DEPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mencapai Batas Data"])},
  "page-titles": {
    "app-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM Getcontact "])},
    "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beranda"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
    "login-by-verifykit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk dengan Verifykit"])},
    "country-packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Negara"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Paket"])},
    "payment-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Berhasil"])},
    "installation-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pemasangan"])},
    "installation-qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemasangan QR"])},
    "installation-manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemasangan Manual"])},
    "account-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Akun"])},
    "saved-cards-index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu Tersimpan"])},
    "saved-cards-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan ke Kartu Tersimpan"])},
    "saved-cards-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Kartu Tersimpan"])},
    "my-esim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSIM Saya"])},
    "404-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halaman Tidak Ditemukan"])}
  },
  "credit-card.card-number-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor kartu tidak valid"])},
  "credit-card.card-owner-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemilik kartu tidak valid"])},
  "credit-card.card-expiration-month-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulan berlaku kartu tidak valid"])},
  "credit-card.card-expiration-year-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun berlaku kartu tidak valid"])},
  "credit-card.card-cvv-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode keamanan kartu tidak valid"])},
  "saved-cards.add-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Kartu"])},
  "payment-success.order-type-topup-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik untuk detail eSIM anda"])}
}