export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium ва тарифҳо"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["имконот"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҳикояҳои муваффақият"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дар бораи"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карера"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кумак"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саволҳои зиёд такрормешуда"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идоракунии Махфияти Профили Шумо"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дар бораи мушкилот гузориш диҳед"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бо мо тамос гиред"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ҳифозат аз иттилоот"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нууцлалын бодлого"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шартҳои хизматрасонии мо"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шартҳои харидории мо"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воридшавӣ бо Google"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воридшавӣ бо Facebook"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воридшавӣ бо Apple"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рақам хато дорад. Лутфан ба мо тамос бигиред"])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Афғонистон"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Албания"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алҷазоир"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самоаи Америка"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Андорра"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ангола"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ангилия"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антарктида"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антигуа ва Барбуда"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аргентина"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арманистон"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аруба"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австралия"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Австрия"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Озарбойҷон"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Багам"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баҳрайн"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бангладеш"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барбадос"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белорус"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белгия"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белиз"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бенин"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бермуда"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутон"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боливия"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Босния ва Ҳерсеговина"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботсвана"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бразилия"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қаламрави Британия дар уқёнуси Ҳинд"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Виргини Британия"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бруней"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Булғория"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Буркина-Фасо"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бурунди"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камбоҷа"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камерун"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канада"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабо-Верде"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Кайман"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷумҳурии Африқои Марказӣ"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чад"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чили"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хитой"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираи Крисмас"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Кокос (Килинг)"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колумбия"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комор"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Кук"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коста-Рика"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хорватия"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куба"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кюрасао"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кипр"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷумҳурии Чех"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CD"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дания"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷибути"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доминика"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷумҳурии Доминикан"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тимор-Лесте"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эквадор"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миср"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эл-Салвадор"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвинеяи Экваторӣ"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эритрея"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эстония"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эфиопия"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Фолкленд"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Фарер"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиҷи"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финляндия"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Франсия"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвианаи Фаронса"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полинезияи Фаронса"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Габон"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гамбия"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гурҷистон"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Германия"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гана"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибралтар"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юнон"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гренландия"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гренада"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гваделупа"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гуам"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гватемала"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гернси"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвинея"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гвинея-Бисау"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гайана"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гаити"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гондурас"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҳонконг (МММ)"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маҷористон"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исландия"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҳиндустон"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индонезия"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эрон"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ироқ"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ирландия"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираи Мэн"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исроил"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Италия"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кот-д’Ивуар"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ямайка"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Япония"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷерси"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Урдун"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазоқистон"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кения"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кирибати"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Косово"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Палау"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қирғизистон"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаос"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латвия"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лубнон"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лесото"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Либерия"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Либия"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лихтенштейн"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Литва"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люксембург"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макао (МММ)"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мақдун"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мадагаскар"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малави"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малайзия"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малдив"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мали"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малта"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Маршалл"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мартиника"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мавритания"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маврикий"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майотта"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мексика"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Штатҳои Федеративии Микронезия"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молдова"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монако"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Муғулистон"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черногория"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монтсеррат"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Марокаш"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мозамбик"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мянма"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намибия"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Науру"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непал"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нидерландия"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каледонияи Нав"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зеландияи Нав"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никарагуа"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нигер"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нигерия"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниуэ"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираи Норфолк"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореяи Шимолӣ"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Марианаи Шимолӣ"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Норвегия"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умон"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покистон"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PS"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панама"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папуа Гвинеяи Нав"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парагвай"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перу"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филиппин"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Питкейрн"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаҳистон"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Португалия"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пуэрто-Рико"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қатар"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CG"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реюнион"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Руминия"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русия"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Руанда"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Бартелми"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент Елена"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Китс ва Невис"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Люсия"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираи Сент-Мартин"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Пер ва Микелон"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сент-Винсент ва Гренадина"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самоа"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан-Марино"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан Томе ва Принсипи"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арабистони Саудӣ"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сенегал"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сербия"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейшел"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиерра-Леоне"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сингапур"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синт-Маартен"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словакия"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словения"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Соломон"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сомалӣ"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Африкаи Ҷанубӣ"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KR"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судони Ҷанубӣ"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Испания"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шри-Ланка"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судон"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суринам"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шпитсберген ва Ян Майен"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свазиленд"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шветсия"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швейтсария"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сурия"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тайван"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тоҷикистон"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Танзания"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таиланд"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Того"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токелау"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тонга"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тринидад ва Тобаго"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тунис"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туркия"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туркманистон"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Теркс ва Кайкос"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тувалу"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҷазираҳои Виргини ИМА"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уганда"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Украина"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аморатҳои Муттаҳидаи Араб"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоҳигарии Муттаҳида"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иёлоти Муттаҳида"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уругвай"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ӯзбекистон"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вануату"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаҳри Вотикон"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Венесуэла"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ветнам"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уоллис ва Футуна"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Яман"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замбия"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зимбабве"])}
  }
}