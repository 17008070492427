export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium va tariflar"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xususiyatlari"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muvaffaqiyat hikoyalari"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haqida"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["martaba"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yordam"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSS"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shaxsiy profilingiz maxfiyligini boshqarish"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muammo haqida xabar berish"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz bilan aloqa"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Axborot muhofazasi"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиёсати ҳифзи асрор"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning xizmat ko'rsatish shartlarimiz"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning sotib olish siyosatimiz"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google bilan kirish"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook bilan kirish"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple bilan kirish"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raqam xato. Bizga murojaat qiling"])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgʻoniston"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albaniya"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazoir"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerika Samoasi"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angilya"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antarktida"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua va Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armaniston"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avstraliya"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avstriya"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ozarbayjon"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagama orollari"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrayn"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgiya"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beliz"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda orollari"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butan"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boliviya"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosniya va Gertsegovina"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botsvana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Braziliya"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britaniyaning Hind okeanidagi hududi"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britaniya Virgin orollari"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruney"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolgariya"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina-Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kambodja"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamerun"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabo-Verde"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayman orollari"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markaziy Afrika Respublikasi"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chili"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xitoy"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rojdestvo oroli"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokos (Kiling) orollari"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolumbiya"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komor orollari"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuk orollari"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosta-Rika"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xorvatiya"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyurasao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kipr"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chexiya"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kongo – Kinshasa"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daniya"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jibuti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominika"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominikan Respublikasi"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor-Leste"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekvador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misr"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekvatorial Gvineya"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritreya"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoniya"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efiopiya"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folklend orollari"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farer orollari"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlandiya"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransiya"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransuz Gvianasi"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransuz Polineziyasi"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambiya"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruziya"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germaniya"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gretsiya"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenlandiya"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gvadelupe"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gvatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gernsi"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gvineya"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gvineya-Bisau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gayana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaiti"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gonduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gonkong (Xitoy MMH)"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vengriya"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islandiya"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindiston"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoneziya"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eron"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iroq"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlandiya"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men oroli"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isroil"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiya"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kot-d’Ivuar"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamayka"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaponiya"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersi"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iordaniya"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qozogʻiston"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keniya"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qirgʻiziston"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latviya"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livan"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesoto"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberiya"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liviya"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lixtenshteyn"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litva"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyuksemburg"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makao (Xitoy MMH)"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makedoniya"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagaskar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malavi"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malayziya"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldiv orollari"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshall orollari"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinika"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mavritaniya"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mavrikiy"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotta"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meksika"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikroneziya"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monako"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongoliya"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chernogoriya"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marokash"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambik"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanma (Birma)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibiya"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niderlandiya"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yangi Kaledoniya"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yangi Zelandiya"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikaragua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeriya"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norfolk oroli"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shimoliy Koreya"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shimoliy Mariana orollari"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norvegiya"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ummon"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokiston"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falastin hududlari"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua – Yangi Gvineya"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragvay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filippin"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitkern orollari"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polsha"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugaliya"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto-Riko"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kongo – Brazzavil"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reyunion"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruminiya"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rossiya"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sen-Bartelemi"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muqaddas Yelena oroli"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent-Kits va Nevis"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent-Lyusiya"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent-Martin"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sen-Pyer va Mikelon"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent-Vinsent va Grenadin"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San-Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San-Tome va Prinsipi"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudiya Arabistoni"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbiya"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seyshel orollari"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syerra-Leone"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapur"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint-Marten"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakiya"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sloveniya"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solomon orollari"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somali"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janubiy Afrika Respublikasi"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janubiy Koreya"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janubiy Sudan"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ispaniya"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shri-Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shpitsbergen va Yan-Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svazilend"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shvetsiya"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shveytsariya"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriya"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tayvan"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tojikiston"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzaniya"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailand"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad va Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunis"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkiya"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmaniston"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks va Kaykos orollari"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQSH Virgin orollari"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraina"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birlashgan Arab Amirliklari"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyuk Britaniya"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerika Qo‘shma Shtatlari"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urugvay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oʻzbekiston"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatikan"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venesuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyetnam"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uollis va Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G‘arbiy Sahroi Kabir"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaman"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambiya"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabve"])}
  }
}