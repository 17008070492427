export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium और टैरिफ"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विशेषताएँ"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफलता की कहानियाँ"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारे बारे में"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यवसाय"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य प्रश्न"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी प्रोफाइल गोपनीयता प्रबंधित करें"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समस्या की रिपोर्ट करें"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमसे संपर्क करें"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कानूनी"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informativa sulla privacy / GDPR"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारी सेवा की शर्तें"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारी खरीद नीति"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google द्वारा लॉगिन करें"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook द्वारा लॉगिन करें"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple द्वारा लॉगिन करें"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नम्बर त्रुटि। कृपया हमें सम्पर्क करें"])},
  "countries": [
    
  ]
}