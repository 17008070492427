export default {
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium dan Tarif"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciri-ciri"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kisah Kejayaan"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerjaya"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bantuan"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruskan Profil Privasi Anda"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporkan masalah"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi kami"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perlindungan Data"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebijakan Privasi"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terma Penggunaan Kami"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dasar Pembelian Kami"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Masuk dengan Google"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Masuk dengan Facebook"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Masuk dengan Apple"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombor mempunyai ralat. Sila hubungi kami"])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algeria"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa Amerika"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antartika"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua dan Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia dan Herzegovina"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazil"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilayah Lautan Hindi British"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Virgin British"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemboja"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroon"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cape Verde"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Cayman"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republik Afrika Tengah"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulau Krismas"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Cocos (Keeling)"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoros"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Cook"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curacao"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czechia"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo - Kinshasa"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republik Dominica"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor-Leste"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesir"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea Khatulistiwa"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopia"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Falkland"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Faroe"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perancis"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiana Perancis"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polinesia Perancis"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jerman"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenland"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong SAR China"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iceland"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isle of Man"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itali"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cote d’Ivoire"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jepun"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyzstan"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lubnan"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libya"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau SAR China"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedonia Utara"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagaskar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Marshall"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinique"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maghribi"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Burma)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belanda"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Caledonia"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulau Norfolk"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korea Utara"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Mariana Utara"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norway"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilayah Palestin"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua New Guinea"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipina"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Pitcairn"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Rico"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo - Brazzaville"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reunion"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusia"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Barthelemy"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Helena"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts dan Nevis"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Martin"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Pierre dan Miquelon"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent dan Grenadines"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tome dan Principe"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arab Saudi"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapura"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint Maarten"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Solomon"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrika Selatan"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korea Selatan"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan Selatan"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepanyol"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard dan Jan Mayen"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syria"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajikistan"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad dan Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turki"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Turks dan Caicos"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kepulauan Virgin A.S."])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emiriah Arab Bersatu"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerika Syarikat"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota Vatican"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis dan Futuna"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahara Barat"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaman"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
  }
}