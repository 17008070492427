export default {
  "Getcontact Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getcontact Web"])},
  "Premium ve Tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium և սակագներ"])},
  "Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հատկություններ"])},
  "Success Story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ձեռքբերումներ"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մասին"])},
  "Career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կարիերա"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Օգնություն"])},
  "Support Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ՀՏՀ"])},
  "Manage Profile Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կառավարեք Ձեր գաղտնիության պրոֆիլը"])},
  "Report a Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հաղորդել խնդրի մասին"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կապ մեզ հետ"])},
  "Legal Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Օրինական"])},
  "Privacy Policy / GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məxfilik Siyasəti"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մեր Ծառայության Պայմանները"])},
  "Purchasing Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մեր Գնման Քաղաքականությունը"])},
  "Google login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մուտք գործել Google-ի Միջոցով"])},
  "Facebook login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մուտք գործել Facebook-ի Միջոցով"])},
  "Apple login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մուտք գործել Apple-ի Միջոցով"])},
  "403012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Համարում սխալ կա: Խնդրում ենք կապհաստատել մեզ հետ:"])},
  "countries": {
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Աֆղանստան"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ալբանիա"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ալժիր"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ամերիկյան Սամոա"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Անդորրա"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Անգոլա"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Անգուիլա"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Անտարկտիդա"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Անտիգուա և Բարբուդա"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Արգենտինա"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հայաստան"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Արուբա"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ավստրալիա"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ավստրիա"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ադրբեջան"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բահամյան կղզիներ"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բահրեյն"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բանգլադեշ"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բարբադոս"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բելառուս"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բելգիա"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բելիզ"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բենին"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բերմուդներ"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բութան"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բոլիվիա"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բոսնիա և Հերցեգովինա"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բոթսվանա"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բրազիլիա"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բրիտանական Տարածք Հնդկական Օվկիանոսում"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բրիտանական Վիրջինյան կղզիներ"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բրունեյ"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բուլղարիա"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բուրկինա Ֆասո"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բուրունդի"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կամբոջա"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կամերուն"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կանադա"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կաբո Վերդե"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կայմանյան կղզիներ"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կենտրոնական Աֆրիկյան Հանրապետություն"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Չադ"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Չիլի"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Չինաստան"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սուրբ Ծննդյան կղզի"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կոկոսյան (Քիլինգ) կղզիներ"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կոլումբիա"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կոմորյան կղզիներ"])},
    "CK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կուկի կղզիներ"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կոստա Ռիկա"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Խորվաթիա"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կուբա"])},
    "CW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կյուրասաո"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կիպրոս"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Չեխիա"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կոնգո - Կինշասա"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Դանիա"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ջիբութի"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Դոմինիկա"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Դոմինիկյան Հանրապետություն"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Թիմոր Լեշտի"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Էկվադոր"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Եգիպտոս"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սալվադոր"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հասարակածային Գվինեա"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Էրիթրեա"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Էստոնիա"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Եթովպիա"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ֆոլքլենդյան կղզիներ"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ֆարերյան կղզիներ"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ֆիջի"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ֆինլանդիա"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ֆրանսիա"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ֆրանսիական Գվիանա"])},
    "PF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ֆրանսիական Պոլինեզիա"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գաբոն"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գամբիա"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վրաստան"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գերմանիա"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գանա"])},
    "GI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ջիբրալթար"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հունաստան"])},
    "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գրենլանդիա"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գրենադա"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գվադելուպա"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գուամ"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գվատեմալա"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գերնսի"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գվինեա"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գվինեա-Բիսաու"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գայանա"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հայիթի"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հոնդուրաս"])},
    "HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հոնկոնգի ՀՎՇ"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հունգարիա"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Իսլանդիա"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հնդկաստան"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ինդոնեզիա"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Իրան"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Իրաք"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Իռլանդիա"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մեն կղզի"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Իսրայել"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Իտալիա"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կոտ դ’Իվուար"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ճամայկա"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ճապոնիա"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ջերսի"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հորդանան"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ղազախստան"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Քենիա"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կիրիբատի"])},
    "XK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կոսովո"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Պալաու"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ղրղզստան"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լաոս"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լատվիա"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լիբանան"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լեսոտո"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լիբերիա"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լիբիա"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լիխտենշտեյն"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լիտվա"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լյուքսեմբուրգ"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Չինաստանի Մակաո ՀՎՇ"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մակեդոնիա"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մադագասկար"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մալավի"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մալայզիա"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մալդիվներ"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մալի"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մալթա"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մարշալյան կղզիներ"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մարտինիկա"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մավրիտանիա"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մավրիկիոս"])},
    "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մայոտ"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մեքսիկա"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Միկրոնեզիա"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մոլդովա"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մոնակո"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մոնղոլիա"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Չեռնոգորիա"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մոնսեռատ"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մարոկկո"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մոզամբիկ"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մյանմա (Բիրմա)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նամիբիա"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նաուրու"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նեպալ"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նիդեռլանդներ"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նոր Կալեդոնիա"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նոր Զելանդիա"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նիկարագուա"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նիգեր"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նիգերիա"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նիուե"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նորֆոլկ կղզի"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հյուսիսային Կորեա"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հյուսիսային Մարիանյան կղզիներ"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նորվեգիա"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Օման"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Պակիստան"])},
    "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Պաղեստինյան տարածքներ"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Պանամա"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Պապուա Նոր Գվինեա"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Պարագվայ"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Պերու"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ֆիլիպիններ"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Պիտկեռն կղզիներ"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լեհաստան"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Պորտուգալիա"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Պուերտո Ռիկո"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կատար"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կոնգո - Բրազավիլ"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ռեյունիոն"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ռումինիա"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ռուսաստան"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ռուանդա"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սուրբ Բարդուղիմեոս"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սուրբ Հեղինեի կղզի"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սենթ Քիտս և Նևիս"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սենթ Լյուսիա"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սեն Մարտեն"])},
    "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սեն Պիեռ և Միքելոն"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սենթ Վինսենթ և Գրենադիններ"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սամոա"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սան Մարինո"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սան Տոմե և Փրինսիպի"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սաուդյան Արաբիա"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սենեգալ"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սերբիա"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սեյշելներ"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սիեռա Լեոնե"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սինգապուր"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սինտ Մարտեն"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սլովակիա"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սլովենիա"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սողոմոնյան կղզիներ"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սոմալի"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հարավաֆրիկյան Հանրապետություն"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հարավային Կորեա"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հարավային Սուդան"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Իսպանիա"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շրի Լանկա"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սուդան"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սուրինամ"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սվալբարդ և Յան Մայեն"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Էսվատինի"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շվեդիա"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շվեյցարիա"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սիրիա"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Թայվան"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Տաջիկստան"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Տանզանիա"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Թայլանդ"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Տոգո"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Տոկելաու"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Տոնգա"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Տրինիդադ և Տոբագո"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Թունիս"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Թուրքիա"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Թուրքմենստան"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Թըրքս և Կայկոս կղզիներ"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Տուվալու"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ԱՄՆ Վիրջինյան կղզիներ"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ուգանդա"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ուկրաինա"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Արաբական Միացյալ Էմիրություններ"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Միացյալ Թագավորություն"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Միացյալ Նահանգներ"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ուրուգվայ"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ուզբեկստան"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վանուատու"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վատիկան"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վենեսուելա"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վիետնամ"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ուոլիս և Ֆուտունա"])},
    "EH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Արևմտյան Սահարա"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Եմեն"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Զամբիա"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Զիմբաբվե"])}
  }
}